/**
 * OLC System
 * Ügyfél és számlakezelő rendszer
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { LabelValueResponse } from '../model/labelValueResponse';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { environment } from '../../../environments/environment';


@Injectable()
export class EnumControllerService {

    protected basePath = environment.apiBaseUrl + '/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * listConfigName
     * 
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listConfigNameUsingGET(authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<LabelValueResponse>;
    public listConfigNameUsingGET(authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LabelValueResponse>>;
    public listConfigNameUsingGET(authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LabelValueResponse>>;
    public listConfigNameUsingGET(authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<LabelValueResponse>(`${this.basePath}/enum/configName`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * listContractTypes
     * 
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listContractTypesUsingGET(authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<LabelValueResponse>;
    public listContractTypesUsingGET(authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LabelValueResponse>>;
    public listContractTypesUsingGET(authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LabelValueResponse>>;
    public listContractTypesUsingGET(authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<LabelValueResponse>(`${this.basePath}/enum/contractType`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * listCurrencies
     * 
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listCurrenciesUsingGET(authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<LabelValueResponse>;
    public listCurrenciesUsingGET(authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LabelValueResponse>>;
    public listCurrenciesUsingGET(authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LabelValueResponse>>;
    public listCurrenciesUsingGET(authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<LabelValueResponse>(`${this.basePath}/enum/currency`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * listInvoiceStatus
     * 
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listInvoiceStatusUsingGET(authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<LabelValueResponse>;
    public listInvoiceStatusUsingGET(authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LabelValueResponse>>;
    public listInvoiceStatusUsingGET(authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LabelValueResponse>>;
    public listInvoiceStatusUsingGET(authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<LabelValueResponse>(`${this.basePath}/enum/invoiceStatus`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * listInvoiceSubTypes
     * 
     * @param authorization JWT Authorization token
     * @param type type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listInvoiceSubTypesUsingGET(authorization?: string, type?: string, observe?: 'body', reportProgress?: boolean): Observable<LabelValueResponse>;
    public listInvoiceSubTypesUsingGET(authorization?: string, type?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LabelValueResponse>>;
    public listInvoiceSubTypesUsingGET(authorization?: string, type?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LabelValueResponse>>;
    public listInvoiceSubTypesUsingGET(authorization?: string, type?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }

        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<LabelValueResponse>(`${this.basePath}/enum/invoiceSubType`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * listInvoiceTypes
     * 
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listInvoiceTypesUsingGET(authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<LabelValueResponse>;
    public listInvoiceTypesUsingGET(authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LabelValueResponse>>;
    public listInvoiceTypesUsingGET(authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LabelValueResponse>>;
    public listInvoiceTypesUsingGET(authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<LabelValueResponse>(`${this.basePath}/enum/invoiceType`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * listLogType
     * 
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listLogTypeUsingGET(authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<LabelValueResponse>;
    public listLogTypeUsingGET(authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LabelValueResponse>>;
    public listLogTypeUsingGET(authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LabelValueResponse>>;
    public listLogTypeUsingGET(authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<LabelValueResponse>(`${this.basePath}/enum/logType`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * listMeterType
     * 
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listMeterTypeUsingGET(authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<LabelValueResponse>;
    public listMeterTypeUsingGET(authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LabelValueResponse>>;
    public listMeterTypeUsingGET(authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LabelValueResponse>>;
    public listMeterTypeUsingGET(authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<LabelValueResponse>(`${this.basePath}/enum/meterType`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * listMeterValueType
     * 
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listMeterValueTypeUsingGET(authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<LabelValueResponse>;
    public listMeterValueTypeUsingGET(authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LabelValueResponse>>;
    public listMeterValueTypeUsingGET(authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LabelValueResponse>>;
    public listMeterValueTypeUsingGET(authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<LabelValueResponse>(`${this.basePath}/enum/meterValueType`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * listMonthlyReportStatus
     * 
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listMonthlyReportStatusUsingGET(authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<LabelValueResponse>;
    public listMonthlyReportStatusUsingGET(authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LabelValueResponse>>;
    public listMonthlyReportStatusUsingGET(authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LabelValueResponse>>;
    public listMonthlyReportStatusUsingGET(authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<LabelValueResponse>(`${this.basePath}/enum/monthlyReportStatus`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * listNotificationTypes
     * 
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listNotificationTypesUsingGET(authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<LabelValueResponse>;
    public listNotificationTypesUsingGET(authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LabelValueResponse>>;
    public listNotificationTypesUsingGET(authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LabelValueResponse>>;
    public listNotificationTypesUsingGET(authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<LabelValueResponse>(`${this.basePath}/enum/notificationType`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * listPeriodTypes
     * 
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listPeriodTypesUsingGET(authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<LabelValueResponse>;
    public listPeriodTypesUsingGET(authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LabelValueResponse>>;
    public listPeriodTypesUsingGET(authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LabelValueResponse>>;
    public listPeriodTypesUsingGET(authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<LabelValueResponse>(`${this.basePath}/enum/periodType`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * listPodControls
     * 
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listPodControlsUsingGET(authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<LabelValueResponse>;
    public listPodControlsUsingGET(authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LabelValueResponse>>;
    public listPodControlsUsingGET(authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LabelValueResponse>>;
    public listPodControlsUsingGET(authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<LabelValueResponse>(`${this.basePath}/enum/podControl`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * listPodTypes
     * 
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listPodTypesUsingGET(authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<LabelValueResponse>;
    public listPodTypesUsingGET(authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LabelValueResponse>>;
    public listPodTypesUsingGET(authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LabelValueResponse>>;
    public listPodTypesUsingGET(authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<LabelValueResponse>(`${this.basePath}/enum/podType`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * listProcessStatus
     * 
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listProcessStatusUsingGET(authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<LabelValueResponse>;
    public listProcessStatusUsingGET(authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LabelValueResponse>>;
    public listProcessStatusUsingGET(authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LabelValueResponse>>;
    public listProcessStatusUsingGET(authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<LabelValueResponse>(`${this.basePath}/enum/invoiceProcessStatus`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * listProviderIntegrationMessageStatus
     * 
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listProviderIntegrationMessageStatusUsingGET(authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<LabelValueResponse>;
    public listProviderIntegrationMessageStatusUsingGET(authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LabelValueResponse>>;
    public listProviderIntegrationMessageStatusUsingGET(authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LabelValueResponse>>;
    public listProviderIntegrationMessageStatusUsingGET(authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<LabelValueResponse>(`${this.basePath}/enum/providerIntegrationMessageStatus`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * listProviders
     * 
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listProvidersUsingGET(authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<LabelValueResponse>;
    public listProvidersUsingGET(authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LabelValueResponse>>;
    public listProvidersUsingGET(authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LabelValueResponse>>;
    public listProvidersUsingGET(authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<LabelValueResponse>(`${this.basePath}/enum/provider`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * listRhdTariffs
     * 
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listRhdTariffsUsingGET(authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<LabelValueResponse>;
    public listRhdTariffsUsingGET(authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LabelValueResponse>>;
    public listRhdTariffsUsingGET(authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LabelValueResponse>>;
    public listRhdTariffsUsingGET(authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<LabelValueResponse>(`${this.basePath}/enum/rhdTariff`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * listRoles
     * 
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listRolesUsingGET(authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<LabelValueResponse>;
    public listRolesUsingGET(authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LabelValueResponse>>;
    public listRolesUsingGET(authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LabelValueResponse>>;
    public listRolesUsingGET(authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<LabelValueResponse>(`${this.basePath}/enum/roles`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * listUserStatus
     * 
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listUserStatusUsingGET(authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<LabelValueResponse>;
    public listUserStatusUsingGET(authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LabelValueResponse>>;
    public listUserStatusUsingGET(authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LabelValueResponse>>;
    public listUserStatusUsingGET(authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<LabelValueResponse>(`${this.basePath}/enum/userStatus`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
