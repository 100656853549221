import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'pod-type',
  templateUrl: './pod-type.component.html',
  styleUrls: ['./pod-type.component.css']
})
export class PodTypeComponent implements OnInit {
  
  @Input()
  template: string = ''
  
  @Input()
  label: string = ''
  
  @Input()  
  status: string =''
  
  constructor() { }

  ngOnInit() {
  }
  
}
