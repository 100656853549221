import { Component, OnInit, ViewChild, OnDestroy, HostListener } from '@angular/core';
import { CommonService } from '../../common/common.service';
import { NotificationControllerService } from '../../../model/api/notificationController.service';
import { EnumControllerService } from '../../../model/api/enumController.service';
import { ListNotificationRequest } from '../../../model/model/listNotificationRequest';
import { NotificationDto } from '../../../model/model/notificationDto';
import { NotificationFilter } from './notification-filter';
import { Paginator } from 'primeng/paginator';
import { TABLE_SELECTOR } from '../../common/enums/table-selector';
import { LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
import { DeleteNotificationRequest } from 'src/app/model/model/deleteNotificationRequest';
//import { Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'notifications-table',
  templateUrl: './notifications-table.component.html',
  styleUrls: ['./notifications-table.component.css'],
})
export class NotificationsTableComponent implements OnInit, OnDestroy {
  @ViewChild('p', { static: true }) paginator: Paginator;
  @ViewChild('dt', { static: false }) table: Table;

  constructor(
    private notificationService: NotificationControllerService,
    private commonService: CommonService,
    private enumService: EnumControllerService,
    private confirmationService: ConfirmationService,
    private sanitizer: DomSanitizer //private router: Router
  ) {}

  public cols: any;
  public currentInc: number = 0;
  //public pageNum: number =1;

  public notifications: NotificationDto[];

  public filterValues: NotificationFilter;
  public sortField: string;
  public sortOrder: number = 0;
  public notificationTotalCount: number = 0;

  public selectedNotifications?: NotificationDto[];
  private subscriptions: Subscription[] = [];

  ngOnInit() {
    this.cols = [
      { field: 'flag', header: 'Megjelölt', size: '50px', align: 'center' },
      //{ field: 'unread', header: 'Olvasott', size:"100px", align:"center" },
      { field: 'type', header: 'Típus', size: '200px', align: 'left' },
      { field: 'created', header: 'Létrehozás dátuma', size: '100px', align: 'center' },
      //{ field: 'message', header: 'Üzenet', size:"300px", align:"left" },
      //{ field: "operations", header: "Műveletek", size:"100px", align:"center" }
    ];

    this.filterValues = <NotificationFilter>{};
    this.filterValues.unread = true;
    this.subscriptions.push(
      this.commonService.connector.subscribe(() => {
        this.filter();
      })
    );

    this.initDropdowns();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  public paginate(): void {
    this.currentInc = this.paginator.getPage() * this.commonService.tablePageSize;
    this.listNotifications();
  }

  initDropdowns() {
    this.subscriptions.push(
      this.enumService.listNotificationTypesUsingGET(this.commonService.getCurrentUser().token).subscribe((data) => {
        this.commonService.notificationTypeOptions = data.labelValues;
      })
    );
  }

  public filter() {
    this.currentInc = this.paginator.getPage() * this.commonService.tablePageSize;
    this.listNotifications();
  }

  buildListNotificationRequest(): ListNotificationRequest {
    let request: ListNotificationRequest = {
      direction: this.sortOrder,
      sortField: this.sortField,
      pageNumber: this.paginator.getPage(),
      pageSize: this.commonService.tablePageSize,
      flag: this.filterValues != null ? this.filterValues.flag : null,
      type: this.filterValues != null ? this.filterValues.type : null,
      unread: this.filterValues != null && this.filterValues.unread ? this.filterValues.unread : null,
      //createdFrom: this.filterValues != null && this.filterValues.created != null ? this.filterValues.created[0] : null,
      //createdTo: this.filterValues != null && this.filterValues.created != null ? this.filterValues.created[1] : null,
    };
    return request;
  }

  public sort(event: LazyLoadEvent) {
    this.sortField = event.sortField;
    this.sortOrder = event.sortOrder;
    this.listNotifications();
  }
  RowSelect(event) {
    if (this.selectedNotifications && this.selectedNotifications.length == 1) {
      if (event.data.unread) {
        this.subscriptions.push(
          this.notificationService.readNotificationUsingGET(event.data.id, this.commonService.getCurrentUser().token).subscribe(() => {
            if (this.filterValues.unread) {
              this.selectedNotifications[0].unread = false;
              this.getUnreadNotifications();
            } else {
              this.filter();
            }
          })
        );
      }
    }
  }

  listNotifications() {
    if (TABLE_SELECTOR.NOTIFICATIONS != this.commonService.getselectTable()) {
      return;
    }

    this.subscriptions.push(
      this.notificationService
        .listNotificationsUsingPOST(this.buildListNotificationRequest(), this.commonService.getCurrentUser().token)
        .subscribe(
          (data) => {
            this.notifications = data.notificationPage.content;
            this.notificationTotalCount = data.notificationPage.totalElements;
          },
          //@ts-ignore
          (error) => {},
          () => {
            this.setSelectedNotification();
            this.getUnreadNotifications();
            this.commonService.setDisableTree(true);
          }
        )
    );
  }

  markedUnread(notificationID: number) {
    this.subscriptions.push(
      this.notificationService.unreadNotificationUsingGET(notificationID, this.commonService.getCurrentUser().token).subscribe(() => {
        this.selectedNotifications = null;
        this.filter();
      })
    );
  }

  markedFlag(notificationID: number, value: boolean) {
    if (value) {
      this.subscriptions.push(
        this.notificationService.addFlagUsingGET(notificationID, this.commonService.getCurrentUser().token).subscribe(() => {
          this.filter();
        })
      );
    } else {
      this.subscriptions.push(
        this.notificationService.removeFlagUsingGET(notificationID, this.commonService.getCurrentUser().token).subscribe(() => {
          this.filter();
        })
      );
    }
  }

  getUnreadNotifications() {
    this.subscriptions.push(
      this.notificationService
        .listNotificationsUsingPOST({ direction: 1, pageNumber: 0, pageSize: 10, unread: true }, this.commonService.getCurrentUser().token)
        .subscribe((data) => {
          this.commonService.unreadNotifications = data.notificationPage.content;
        })
    );
  }

  deleteNotification(notificationID: number) {
    this.confirmationService.confirm({
      message: 'Szeretné a törölni ezt a értesítést?',
      accept: () => {
        this.subscriptions.push(
          this.notificationService.deleteUsingGET3(notificationID, this.commonService.getCurrentUser().token).subscribe(() => {
            this.filter();
            this.selectedNotifications = null;
          })
        );
      },
    });
  }

  setSelectedNotification() {
    if (this.selectedNotifications && this.selectedNotifications.length == 1) {
      //if (this.selectedNotification != null ) {
      this.selectedNotifications[0] = this.notifications.find((item) => item.id === this.selectedNotifications[0].id);
      this.table.selection = this.selectedNotifications[0];
      //}
    }
  }

  public findLabel(value: string): string {
    return this.commonService.findLabel(this.commonService.notificationTypeOptions, value);
  }

  getNotificationTypeOptions(): any[] {
    return this.commonService.notificationTypeOptions;
  }

  getTableHeightInpx(): string {
    return this.commonService.getTableHeightInpx();
  }

  getTableWidthInpx(): string {
    return this.commonService.getTableWidthInpx();
  }

  getContentWidth(): number {
    return this.commonService.getContentWidth();
  }

  tablePageSize(): number {
    return this.commonService.tablePageSize;
  }

  getTableHeight(): number {
    return this.commonService.getTableHeight();
    //return this.commonService.getTableHeight() +36;
  }

  public getCurrentPageReportTemplate() {
    let from = this.paginator.getPage() * this.commonService.tablePageSize + 1;
    let to = (this.paginator.getPage() + 1) * this.commonService.tablePageSize;
    const total = this.notificationTotalCount | 0;
    to = Math.min(to, total);
    return `{currentPage}. oldal [ ${from} - ${to}  a(z) ${total} -ből]`;
  }

  deleteSelectedNotifications() {
    this.confirmationService.confirm({
      message: `Szeretné a törölni a kijelölt <b>${this.selectedNotifications.length} db</b> értesítést?`,
      accept: () => {
        let ids: DeleteNotificationRequest = {} as DeleteNotificationRequest;
        ids.notificationIds = this.selectedNotifications.map((item) => item.id);
        this.subscriptions.push(
          this.notificationService.deleteUsingPOST(ids, this.commonService.getCurrentUser().token).subscribe(() => {
            this.selectedNotifications = null;
            this.filter();
          })
        );
      },
    });
  }

  isDisableButton() {
    return !(this.selectedNotifications && this.selectedNotifications.length > 0);
  }

  getContent(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(`${this.selectedNotifications[0].message}`);
    //<a href="#" routerlink="POD" customerid="42377" id="39N050605877000G">PODAZONISÍTÓ</a>
    //<a href="#" routerlink="INVOICE" customerid="42377" id="ewwerewr">SZÁMLASZÁM</a>
    
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    if (event.target instanceof HTMLAnchorElement === false) {
      return;
    }
    const routerlink: string = event.target['attributes']['routerlink'].value;
    const customerid: string = event.target['attributes']['customerid'].value;
    const id: string = event.target['attributes']['id'].value;

    event.preventDefault();

    this.commonService.showInvoiceList(customerid, routerlink, id);
  }
}
