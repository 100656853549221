import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Paginator } from 'primeng/components/paginator/paginator';
import { Subscription } from 'rxjs/internal/Subscription';
import { CommonService } from '../../common/common.service';
import { ProviderIntegrationControllerService } from 'src/app/model/api/providerIntegrationController.service';
import { CustomerTreeControllerService } from 'src/app/model/api/customerTreeController.service';
import { ConfirmationService } from 'primeng/components/common/confirmationservice';
import { TABLE_SELECTOR } from '../../common/enums/table-selector';
import { take } from 'rxjs/internal/operators/take';
import { ListProviderIntegrationRequest } from 'src/app/model/model/listProviderIntegrationRequest';
import { ListProviderIntegrationResponse } from 'src/app/model/model/listProviderIntegrationResponse';
import { ProviderIntegrationDto } from 'src/app/model/model/providerIntegrationDto';
import { LazyLoadEvent } from 'primeng/components/common/lazyloadevent';
import { ProviderIntegrationFilter } from './providerIntegrationList-filter';
import { ProviderIntegrationManagerComponent } from '../provider-integration-manager/provider-integration-manager.component';
import { LabelValue } from 'src/app/model';
import { map } from 'rxjs/internal/operators/map';
import { ToastMessage } from '../shared/toaster/toast-message';
import { ToasterService } from '../shared/toaster/toaster.service';
import { LabelValueExtra } from '../../../model/model/labelValueExtra';
import { ProviderIntegrationRunRequest } from '../../../model/model/providerIntegrationRunRequest';
import { ProviderIntegrationRobotStatusComponent } from '../shared/provider-integration-robot-status/provider-integration-robot-status.component';

@Component({
  selector: 'provider-integration-list',
  templateUrl: './provider-integration-list.component.html',
  styleUrls: ['./provider-integration-list.component.css'],
})
export class ProviderIntegrationListComponent implements OnInit, OnDestroy {
  @ViewChild('p', { static: true }) paginator: Paginator;
  @ViewChild('robotstatus', { static:false }) robotstatus: ProviderIntegrationRobotStatusComponent;

  @ViewChild('providerIntegrationManager', { static: false })
  providerIntegrationManager: ProviderIntegrationManagerComponent;

  constructor(
    private commonService: CommonService,
    private confirmationService: ConfirmationService,
    private customerTreeControllerService: CustomerTreeControllerService,
    private providerIntegrationControllerService: ProviderIntegrationControllerService,    
    private toasterService: ToasterService
  ) {}

  private subscriptions: Subscription[] = [];
  public cols: any;
  public sortOrder: number = 0;
  public currentInc: number = 0;
  public providerIntgrationsTotalCount: number = 0;
  public sortField: string;
  public providerInterations: ProviderIntegrationDto[];
  public selectedProviderIntegration: ProviderIntegrationDto;
  public filterValues: ProviderIntegrationFilter = new ProviderIntegrationFilter();
  public displayProviderIntegrationEditor: boolean = false;
  public displayRunProviderIntegrationEditor: number = undefined;
  public providerOptions: LabelValueExtra[] = [];
  public customerOptions: LabelValue[] = [];
  public downloadStart: string;
  public downloadEnd: string;
  public hu: any;
  isActiveTab: boolean = false;
  

  ngOnInit() {
    this.setTableCols();
    //this.filter();
    this.currentInc = (this.paginator.getPage() || 0) * this.commonService.tablePageSize;
    this.commonService.setDisableTree(true);
    this.initDropdowns();
    this.hu = this.commonService.datePickerLanguage;
  }

  private setTableCols() {
    this.cols = [
      { field: 'customerName', header: 'Ügyfél', size: '250px', align: 'left' },
      {
        field: 'provider',
        header: 'Szolgáltató',
        size: '250px',
        align: 'left',
      },
      {
        field: 'username',
        header: 'Felhasználónév',
        size: '250px',
        align: 'left',
      },
      {
        field: 'period',
        header: 'Szerződéses időszak',
        size: '250px',
        align: 'center',
      },
      {
        field: 'customerCodes',
        header: 'Vevőkódok',
        size: '250px',
        align: 'left',
      },

      {
        field: 'operations',
        header: 'Műveletek',
        size: '100px',
        align: 'center',
      },
    ];
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  public filter() {
    this.commonService.setDisableTree(true);
    this.listProviderIntegrations();
    this.currentInc = (this.paginator.getPage() || 0) * this.commonService.tablePageSize;
    //this.selectedProviderIntegration = null;
  }

  buildListRequest(): ListProviderIntegrationRequest {
    let req: ListProviderIntegrationRequest = {
      //pageNumber: 0,
      pageNumber: this.paginator.getPage() || 0,
      pageSize: this.commonService.tablePageSize,
      direction: this.sortOrder,
      sortField: this.sortField,

      //customerId?: number;
      provider: this.filterValues.provider ? [this.filterValues.provider] : null,
    };
    return req;
  }

  public listProviderIntegrations() {
    if (TABLE_SELECTOR.PROVIDER_INTEGRATION != this.commonService.getselectTable()) return;
    this.subscriptions.push(
      this.providerIntegrationControllerService
        .listProviderIntegrationsUsingPOST(this.buildListRequest(), this.commonService.getCurrentUser().token)
        .pipe(take(1))
        .subscribe((data: ListProviderIntegrationResponse) => {
          this.providerInterations = data.providerIntegrationPage.content;
          this.providerIntgrationsTotalCount = data.providerIntegrationPage.totalElements;
        })
    );
  }

  initDropdowns() {
    this.subscriptions.push(
      this.providerIntegrationControllerService
        .getProvidersUsingGET(this.commonService.getCurrentUser().token)
        .pipe(take(1))
        .subscribe((resp) => {
          this.providerOptions = resp;
        })
    );

    this.subscriptions.push(
      this.customerTreeControllerService
        .getTreeOnlyCustomersUsingGET(this.commonService.getCurrentUser().token)
        .pipe(
          take(1),
          map((resp) => {
            return resp.items.map((item) => {
              return { label: item.label, value: item };
            });
          })
        )
        .subscribe((resp) => {
          this.customerOptions = resp;
        })
    );
  }

  public sort(event: LazyLoadEvent) {
    this.sortField = event.sortField;
    this.sortOrder = event.sortOrder;
    this.listProviderIntegrations();
  }

  resetFilter() {
    this.filterValues = new ProviderIntegrationFilter();
    this.filter();
  }
  addProvideerIntegration() {
    this.displayProviderIntegrationEditor = true;
    
    //this.providerIntegrationManager.formValue = {};
    //this.providerIntegrationManager.formValue = null;
    //this.providerIntegrationManager.filteredContracts = [];
    //this.providerIntegrationManager.selectedContracts = [];
    //this.providerIntegrationManager.newItem = true;
    //this.providerIntegrationManager.updateItem = false;
  }

  public getCurrentPageReportTemplate() {
    let from = this.paginator.getPage() * this.commonService.tablePageSize + 1;
    let to = (this.paginator.getPage() + 1) * this.commonService.tablePageSize;
    to = Math.min(to, this.providerIntgrationsTotalCount);
    return '{currentPage}. oldal [' + from + ' - ' + to + ' a(z) ' + this.providerIntgrationsTotalCount + '-ből]';
  }

  isAdminUser(): boolean {
    return this.commonService.isAdminUser();
  }

  public paginate(): void {
    this.currentInc = (this.paginator.getPage() || 0) * this.commonService.tablePageSize;

    this.listProviderIntegrations();
  }

  getTableHeightInpx(): string {
    return this.commonService.getTableHeightInpx();
  }
  getTableWidthInpx(): string {
    return this.commonService.getTableWidthInpx();
  }
  tablePageSize(): number {
    return this.commonService.tablePageSize;
  }

  getTableHeight(): number {
    return this.commonService.getTableHeight() + 36;
  }

  isDisabled() {
    return !this.providerIntegrationManager ? true : this.providerIntegrationManager.isDisableSaveButton();
  }
  /*

  public validate(): boolean {
    if (this.meterFormValues.name === null) {
      this.toasterService.showToaster({ severity: 'error', summary: 'A Saját elnevezés kitöltése kötelező', detail: '', life: 30000 });
      return false;
    }

    if (this.meterFormValues.serialNumber === null) {
      this.toasterService.showToaster({ severity: 'error', summary: 'A Gyári szám kitöltése kötelező', detail: '', life: 30000 });
      return false;
    }
    return true;
  }
*/
  public validate(): boolean {
    if (this.providerIntegrationManager.formValue.downloadStart != null && this.providerIntegrationManager.formValue.downloadEnd != null) {
      if (this.providerIntegrationManager.formValue.downloadStart > this.providerIntegrationManager.formValue.downloadEnd) {
        this.toasterService.showToaster({
          severity: 'error',
          summary: 'Hibás dátum',
          detail: 'A letöltés kezdete későbbi mint a vége',
          life: 30000,
        });
        return false;
      }
    }

    if (this.providerIntegrationManager.formValue.downloadStart === null) {
      this.toasterService.showToaster({
        severity: 'error',
        summary: 'A letöltés kezdete mező nincs kitöltve',
        detail: '',
        life: 30000,
      });
      return false;
    }

    if (this.providerIntegrationManager.formValue.downloadEnd === null) {
      this.toasterService.showToaster({
        severity: 'error',
        summary: 'A letöltés vége mező nincs kitöltve',
        detail: '',
        life: 30000,
      });
      return false;
    }

    return true;
  }

  saveProviderIntegration() {
    if (!this.validate) return;

    let respBody: ProviderIntegrationDto = {
      id: this.providerIntegrationManager.formValue.id || null,
      username: this.providerIntegrationManager.formValue.username,
      provider: this.providerIntegrationManager.formValue.provider,
      password: this.providerIntegrationManager.formValue.password,
      customerId: this.providerIntegrationManager.formValue.customerId.key,
      customerName: this.providerIntegrationManager.formValue.customerId.label,
      downloadStart: this.providerIntegrationManager.formValue.downloadStart,
      downloadEnd: this.providerIntegrationManager.formValue.downloadEnd,
      customerCodes: this.providerIntegrationManager.formValue.customerCodes,
    };
    this.subscriptions.push(
      this.providerIntegrationControllerService
        .saveProviderIntegrationUsingPOST(respBody, this.commonService.getCurrentUser().token)
        .subscribe(() => {
          this.filter();
          this.displayProviderIntegrationEditor = false;
        })
    );
  }

  cancelEdit() {
    this.displayProviderIntegrationEditor = false;
  }

  findLabel(value: string): string {
    return this.commonService.findLabel(this.providerOptions, value);
  }

  deleteProviderIntegration(row: any) {
    const provider: string = this.findLabel(row.provider);

    this.confirmationService.confirm({
      message: `Szeretné törölni a(z) <b>${row.customerName}</b> ügyfélhez és <b>${provider}</b> szolgáltatóhoz tartozó integrációt?`,
      accept: () => {
        this.providerIntegrationControllerService
          .deleteProviderIntegrationUsingGET(row.id, this.commonService.getCurrentUser().token)
          .pipe(take(1))
          .subscribe(() => {
            this.filter();
          });
      },
    });
  }

  editProviderIntegration(row: any) {
    this.providerIntegrationControllerService
      .getProviderIntegrationUsingGET(row.id, this.commonService.getCurrentUser().token)
      .pipe(take(1))
      .subscribe((item) => {
        //this.providerIntegrationManager.newItem = false;
        //this.providerIntegrationManager.updateItem = true;
        this.providerIntegrationManager.formValue = {};

        this.providerIntegrationManager.formValue.id = item.id;
        this.providerIntegrationManager.formValue.provider = item.provider;
        this.providerIntegrationManager.formValue.password = item.password;
        this.providerIntegrationManager.formValue.username = item.username;
        this.providerIntegrationManager.formValue.downloadStart = item.downloadStart;
        this.providerIntegrationManager.formValue.downloadEnd = item.downloadEnd;
        this.providerIntegrationManager.formValue.customerCodes = item.customerCodes;

        this.providerIntegrationManager.formValue.customerId = this.customerOptions.filter(
          (cust) => cust.value.key == item.customerId
        )[0].value;
        this.providerIntegrationManager.formValue.customerName = item.customerName;

        this.providerIntegrationManager.selectContract();
      });

    this.displayProviderIntegrationEditor = true;
  }

  getDateStr(date: Date): string {
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date.getFullYear();

    return `${yyyy}-${mm}-${dd}`;
  }

  setDateField() {
    let endDate = new Date();
    let startDate = new Date();
    startDate.setDate(startDate.getDate() - 7);
    this.downloadEnd = this.getDateStr(endDate);
    this.downloadStart = this.getDateStr(startDate);
  }

  runProviderIntegration(row: any) {
    this.displayRunProviderIntegrationEditor = row.id;
    this.setDateField();
  }

  cancelRunProviderIntegration() {
    this.displayRunProviderIntegrationEditor = undefined;
  }

  isDisabledRunProvider() {
    return !this.downloadStart || !this.downloadEnd;
  }

  public validateRunProviderIntegration(): boolean {
    if (this.downloadStart != null && this.downloadEnd != null) {
      if (this.downloadStart > this.downloadEnd) {
        this.toasterService.showToaster({
          severity: 'error',
          summary: 'Hibás dátum',
          detail: 'A letöltés időszak kezdete későbbi mint a vége',
          life: 30000,
        });
        return false;
      }
    }

    if (this.downloadStart === null) {
      this.toasterService.showToaster({
        severity: 'error',
        summary: 'A letöltés időszak kezdete mező nincs kitöltve',
        detail: '',
        life: 30000,
      });
      return false;
    }

    if (this.downloadEnd === null) {
      this.toasterService.showToaster({
        severity: 'error',
        summary: 'A letöltés időszak vége mező nincs kitöltve',
        detail: '',
        life: 30000,
      });
      return false;
    }
    return true;
  }

  startRunProviderIntegration() {
    if (!this.validateRunProviderIntegration()) return;
    let request: ProviderIntegrationRunRequest = {};
    request.id = this.displayRunProviderIntegrationEditor;
    request.fromDate = this.downloadStart;
    request.toDate = this.downloadEnd;

    this.providerIntegrationControllerService
      .runSelectedProviderIntegrationUsingPOST(request, this.commonService.getCurrentUser().token)
      .pipe(take(1))
      .subscribe(() => {
        let toastMessage: ToastMessage;
        toastMessage = {
          severity: 'info',
          summary: 'Számla letöltés',
          detail: 'A kiválasztott szolgáltató / ügyfél számlák letöltése elindult!',
          life: 30000,
        };
        this.toasterService.showToaster(toastMessage);
        this.robotstatus.getList();
      });
    this.displayRunProviderIntegrationEditor = undefined;
  }
  
  handleChange(event: any){
    this.isActiveTab = event.index == 1
  }
}
