import { Component, OnInit, OnDestroy, Input, ViewEncapsulation, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { Editor } from 'primeng/editor';
import { take } from 'rxjs/operators';
import { SendEmailsRequest, UserControllerService, UserDto } from 'src/app/model';
import { CommonService } from 'src/app/modules/common/common.service';

interface EmailFormValuesDTO {
  body?: string;
  subject?: string;
}

@Component({
  selector: 'recipient',
  template: ` <span class="recipient-chip">{{ recipientText }}</span> `,
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./email-editor.component.css'],
})
export class RecipientComponent implements OnInit, OnDestroy {
  @Input()
  recipientText: string;

  constructor() {}

  ngOnDestroy(): void {}

  ngOnInit() {}
}

@Component({
  selector: 'email-editor',
  templateUrl: './email-editor.component.html',
  styleUrls: ['./email-editor.component.css'],
})
export class EmailEditorComponent implements OnInit, OnDestroy {

  @ViewChild('editor', { static: false }) editor: Editor;
  formValues: EmailFormValuesDTO = {} as EmailFormValuesDTO;

  @Input()
  public recipients: UserDto[];

  constructor(private userService: UserControllerService, private commonService: CommonService) {}

  ngOnDestroy(): void {}

  ngOnInit() {}

  private buildRequest(): SendEmailsRequest {
    let request: SendEmailsRequest = {} as SendEmailsRequest;
    request.subject = this.formValues.subject;
    request.body = this.formValues.body;
    request.userIds = this.recipients.map((item: UserDto) => item.id);

    return request;
  }

  public isDisable() {
    return this.formValues.body && this.formValues.subject ? false : true;
  }

  public sendMessage() {
    this.userService
      .sendEmailsUsingPOST(this.buildRequest(), this.commonService.getCurrentUser().token)
      .pipe(take(1))
      //@ts-ignore
      .subscribe((item) => {});
  }
}
