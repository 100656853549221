/**
 * OLC System
 * Ügyfél és számlakezelő rendszer
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpEvent }  from '@angular/common/http';
import { Observable }                                        from 'rxjs';

import { InvoiceProcessDto } from '../model/invoiceProcessDto';
import { ProcessDetailsResponse } from '../model/processDetailsResponse';
import { ScanInvoiceResponse } from '../model/scanInvoiceResponse';
import { StartInvoiceProcessRequest } from '../model/startInvoiceProcessRequest';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { environment } from '../../../environments/environment';


@Injectable()
export class DispatcherControllerService {

    protected basePath = environment.apiBaseUrl + '/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * getDetails
     * 
     * @param invoiceProcessId invoiceProcessId
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDetailsUsingGET(invoiceProcessId: number, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<ProcessDetailsResponse>;
    public getDetailsUsingGET(invoiceProcessId: number, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProcessDetailsResponse>>;
    public getDetailsUsingGET(invoiceProcessId: number, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProcessDetailsResponse>>;
    public getDetailsUsingGET(invoiceProcessId: number, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invoiceProcessId === null || invoiceProcessId === undefined) {
            throw new Error('Required parameter invoiceProcessId was null or undefined when calling getDetailsUsingGET.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        return this.httpClient.get<ProcessDetailsResponse>(`${this.basePath}/dispatcher/details/${encodeURIComponent(String(invoiceProcessId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getStatus
     * 
     * @param invoiceProcessId invoiceProcessId
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStatusUsingGET(invoiceProcessId: number, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<InvoiceProcessDto>;
    public getStatusUsingGET(invoiceProcessId: number, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InvoiceProcessDto>>;
    public getStatusUsingGET(invoiceProcessId: number, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InvoiceProcessDto>>;
    public getStatusUsingGET(invoiceProcessId: number, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invoiceProcessId === null || invoiceProcessId === undefined) {
            throw new Error('Required parameter invoiceProcessId was null or undefined when calling getStatusUsingGET.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<InvoiceProcessDto>(`${this.basePath}/dispatcher/status/${encodeURIComponent(String(invoiceProcessId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * scanInvoiceFolders
     * 
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public scanInvoiceFoldersUsingGET(authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<ScanInvoiceResponse>;
    public scanInvoiceFoldersUsingGET(authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ScanInvoiceResponse>>;
    public scanInvoiceFoldersUsingGET(authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ScanInvoiceResponse>>;
    public scanInvoiceFoldersUsingGET(authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<ScanInvoiceResponse>(`${this.basePath}/dispatcher/scan`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * startInvoiceProcessing
     * 
     * @param body request
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public startInvoiceProcessingUsingPOST(body: StartInvoiceProcessRequest, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public startInvoiceProcessingUsingPOST(body: StartInvoiceProcessRequest, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public startInvoiceProcessingUsingPOST(body: StartInvoiceProcessRequest, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public startInvoiceProcessingUsingPOST(body: StartInvoiceProcessRequest, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling startInvoiceProcessingUsingPOST.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<number>(`${this.basePath}/dispatcher/process`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
