import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../../common/common.service';
import { TABLE_SELECTOR } from '../../common/enums/table-selector';
import { LazyLoadEvent, ConfirmationService, MessageService } from 'primeng/api';
import { UserFilter } from './user-filter';
import { UserDto } from '../../../model/model/userDto';
import { ListUserRequest } from '../../../model/model/listUserRequest';
import { UserControllerService } from '../../../model/api/userController.service';
import { EnumControllerService } from '../../../model/api/enumController.service';
import { UserEditorComponent } from '../user-editor/user-editor.component';
import { UserStatusEnum } from '../../common/enums/userStatus';
import { UserDetailsDto, LabelValue, TreeItem } from '../../../model/model/models';
import { ToasterService } from '../shared/toaster/toaster.service';
import { Subscription } from 'rxjs';
import { EmailEditorComponent } from '../shared/email-editor/email-editor.component';
import { UserRoleEnum } from '../../common/enums/userRole';

@Component({
  selector: 'user-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.css'],
  providers: [UserEditorComponent, MessageService],
})
export class UserTableComponent implements OnInit {
  @ViewChild('emailEditor', { static: false }) emailEditor: EmailEditorComponent;

  public sortField: string;
  public selectedUsers: UserDto[] = [];
  private subscriptions: Subscription[] = [];
  public sortOrder: number = 0;

  public cols: any;
  public currentInc: number = 0;

  public users: UserDto[];

  public filterValues: UserFilter;

  public userTotalCount: number = 0;

  public userRoleOptions: any[];

  public userStatusOptions: any[];

  public showUserEditor: boolean = false;

  public showMessageEditor: boolean = false;

  selectedTreeItem: TreeItem;
  userFormValues: UserDetailsDto = {
    status: UserStatusEnum[UserStatusEnum.ACTIVE],
  };

  constructor(
    private userService: UserControllerService,
    private commonService: CommonService,
    private enumService: EnumControllerService,
    private confirmationService: ConfirmationService,
    private toasterService: ToasterService,
    private messageService: MessageService
  ) {}

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  ngOnInit() {
    this.cols = [
      { field: 'email', header: 'E-mail', size: '300px', align: 'left' },
      { field: 'name', header: 'Név', size: '150px', align: 'left' },
      { field: 'role', header: 'Szerepkör', size: '200px', align: 'center' },
      { field: 'status', header: 'Állapot', size: '100px', align: 'center' },
      { field: 'otp', header: 'Demo', size: '100px', align: 'center' },
      { field: 'operations', header: 'Műveletek', size: '100px', align: 'center' },
    ];

    this.filterValues = <UserFilter>{};
    this.subscriptions.push(
      this.commonService.connector.subscribe((data) => {
        this.selectedTreeItem = data.selectedTreeItem;
        this.filter();
      })
    );
    this.initDropdowns();
  }

  initDropdowns() {
    this.subscriptions.push(
      this.enumService.listRolesUsingGET(this.commonService.getCurrentUser().token).subscribe((data) => {
        this.userRoleOptions = data.labelValues;
      })
    );
    this.subscriptions.push(
      this.enumService.listUserStatusUsingGET(this.commonService.getCurrentUser().token).subscribe((data) => {
        this.userStatusOptions = data.labelValues;
      })
    );
  }

  buildListUsersRequest(): ListUserRequest {
    let request: ListUserRequest = {
      sortField: this.sortField,
      direction: this.sortOrder,
      email: this.filterValues != null ? this.filterValues.email : null,
      name: this.filterValues != null ? this.filterValues.name : null,
      role: this.filterValues != null ? this.filterValues.role : null,
      status: this.filterValues != null ? this.filterValues.status : null,
      treeId: !this.selectedTreeItem ? null : parseInt(this.selectedTreeItem.key),
    };
    return request;
  }

  listUsers() {
    if (TABLE_SELECTOR.USERS != this.commonService.getselectTable()) {
      return;
    }
    this.subscriptions.push(
      this.userService.listUsersUsingPOST(this.buildListUsersRequest(), this.commonService.getCurrentUser().token).subscribe(
        (data) => {
          this.users = data.usersPage;
        },
        // @ts-ignore
        (error) => {},
        () => {
          this.commonService.setDisableTree(false);
        }
      )
    );
  }

  sort(event: LazyLoadEvent) {
    this.sortField = event.sortField;
    this.sortOrder = event.sortOrder;
    this.listUsers();
  }

  filter() {
    this.listUsers();
  }

  paginate(): void {
    this.listUsers();
  }

  showDetails(row: number): void {
    this.loadUser(this.users[row].id);
  }

  openUserEditor(): void {
    this.userFormValues = {
      status: UserStatusEnum[UserStatusEnum.ACTIVE],
    };
    this.showUserEditor = true;
  }

  saveUser(): void {
    if (!this.validate()) {
      return;
    }
    if (this.userFormValues.id == null) {
      this.newUser();
    } else {
      this.modifyUser();
    }
  }
  validate(): boolean {
    if (this.userFormValues.name == null) {
      this.toasterService.showToaster({ severity: 'error', summary: 'A név kitöltése kötelező', detail: '', life: 30000 });
      return false;
    }
    if (this.userFormValues.email == null) {
      this.toasterService.showToaster({ severity: 'error', summary: 'Az email cím kitöltése kötelező', detail: '', life: 30000 });
      return false;
    }
    let emailValidator = new RegExp('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,}$');
    if (!emailValidator.test(this.userFormValues.email)) {
      this.toasterService.showToaster({ severity: 'error', summary: 'Az email cím érvénytelen', detail: '', life: 30000 });
      return false;
    }
    if (this.userFormValues.role == null) {
      this.toasterService.showToaster({ severity: 'error', summary: 'A szerepkör kitöltése kötelező', detail: '', life: 30000 });
      return false;
    }
    return true;
  }
  loadUser(userId: number) {
    this.subscriptions.push(
      this.userService.getUserDetailsUsingGET(userId, this.commonService.getCurrentUser().token).subscribe((data) => {
        this.userFormValues = data;
        this.showUserEditor = true;
      })
    );
  }

  newUser() {
    this.subscriptions.push(
      this.userService.createUserUsingPOST(this.userFormValues, this.commonService.getCurrentUser().token).subscribe(() => {
        this.listUsers();
        this.showUserEditor = false;
      })
    );
  }

  modifyUser() {
    this.subscriptions.push(
      this.userService.updateUserUsingPOST(this.userFormValues, this.commonService.getCurrentUser().token).subscribe(() => {
        this.listUsers();
        this.showUserEditor = false;
      })
    );
  }

  generatePassword() {
    this.confirmationService.confirm({
      message: 'Szeretne új jelszót generálni a(z) <b>' + this.userFormValues.name + '</b> nevű felhasználónak?',
      accept: () => {
        this.subscriptions.push(
          this.userService.generatePasswordUsingGET(this.userFormValues.id, this.commonService.getCurrentUser().token).subscribe(() => {
            this.messageService.add({ severity: 'success', summary: 'Sikeres jelszó változtatás', detail: '' });
          })
        );
      },
    });
  }

  activateUser(): void {
    this.confirmationService.confirm({
      message: 'Szeretné <b>aktiválni</b> ' + this.userFormValues.name + ' nevű felhasználót?',
      accept: () => {
        this.subscriptions.push(
          this.userService.activateUsingGET(this.userFormValues.id, this.commonService.getCurrentUser().token).subscribe(() => {
            this.loadUser(this.userFormValues.id);
            this.messageService.add({
              severity: 'success',
              summary: 'Sikeres aktiválás',
              detail: 'A felhasználó aktiválása sikeresen megtörtént.',
            });
          })
        );
      },
    });
  }

  inactivateUser(): void {
    this.confirmationService.confirm({
      message: 'Szeretné <b>inaktiválni</b> ' + this.userFormValues.name + ' nevű felhasználót?',
      accept: () => {
        this.subscriptions.push(
          this.userService.inactivateUsingGET(this.userFormValues.id, this.commonService.getCurrentUser().token).subscribe(() => {
            this.loadUser(this.userFormValues.id);
            this.messageService.add({
              severity: 'success',
              summary: 'Sikeres inaktiválás',
              detail: 'A felhasználó inaktiválása sikeresen megtörtént.',
            });
          })
        );
      },
    });
  }

  isActive() {
    return this.userFormValues != null && this.userFormValues.status == UserStatusEnum[UserStatusEnum.ACTIVE];
  }

  isInactive() {
    return this.userFormValues != null && this.userFormValues.status == UserStatusEnum[UserStatusEnum.INACTIVE];
  }

  isDisable(): boolean {
    if (!this.userFormValues) return true;
    if ([UserRoleEnum[UserRoleEnum.CLIENT], UserRoleEnum[UserRoleEnum.POWER_CLIENT]].indexOf(this.userFormValues.role) > 0) {
      if (this.userFormValues.customers && this.userFormValues.customers.length > 0) {
        return false;
      } else {
        return true;
      }
    } else return false;
  }

  activateRow(row: number): void {
    this.confirmationService.confirm({
      message: 'Szeretné <b>aktiválni</b> ' + this.users[row].name + ' nevű felhasználót?',
      accept: () => {
        this.subscriptions.push(
          this.userService.activateUsingGET(this.users[row].id, this.commonService.getCurrentUser().token).subscribe(() => {
            this.listUsers();
          })
        );
      },
    });
  }

  inactivateRow(row: number): void {
    this.confirmationService.confirm({
      message: 'Szeretné <b>inaktiválni</b> ' + this.users[row].name + ' nevű felhasználót?',
      accept: () => {
        this.subscriptions.push(
          this.userService.inactivateUsingGET(this.users[row].id, this.commonService.getCurrentUser().token).subscribe(() => {
            this.listUsers();
          })
        );
      },
    });
  }

  findLabel(options: LabelValue[], value: string): string {
    return this.commonService.findLabel(options, value);
  }
  getTableWidthInpx(): string {
    return this.commonService.getTableWidthInpx();
  }

  getTableHeightInpx(): string {
    return this.commonService.getTableHeightInpx();
  }
  getTablePageSize(): number {
    return this.commonService.tablePageSize;
  }

  openMessageEditor() {
    this.showMessageEditor = true;
  }

  sendMessage() {
    this.emailEditor.sendMessage();
    this.showMessageEditor = false;
  }
}
