/**
 * OLC System
 * Ügyfél és számlakezelő rendszer
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface InvoiceProcessDetails { 
    count?: number;
    provider?: InvoiceProcessDetails.ProviderEnum;
    quality?: number;
}
export namespace InvoiceProcessDetails {
    export type ProviderEnum = 'ELMU' | 'ELMU_EGYETEMES' | 'ELMU_VP' | 'ELMU_VP_ENSZOLG' | 'EMASZ' | 'EON' | 'EON_ESZAK_D' | 'EON_DEL_D' | 'EON_TISZANTUL' | 'CYEB' | 'E2' | 'ALTEO' | 'NKM_ARAM' | 'NKM_GAZ' | 'NKM_GAZ_VP' | 'EMOGA' | 'HUNGARO_ENERGY' | 'MVM_PARTNER' | 'EON_SZOLG' | 'FOGAZ' | 'MASZ' | 'DEMASZ';
    export const ProviderEnum = {
        ELMU: 'ELMU' as ProviderEnum,
        ELMUEGYETEMES: 'ELMU_EGYETEMES' as ProviderEnum,
        ELMUVP: 'ELMU_VP' as ProviderEnum,
        ELMUVPENSZOLG: 'ELMU_VP_ENSZOLG' as ProviderEnum,
        EMASZ: 'EMASZ' as ProviderEnum,
        EON: 'EON' as ProviderEnum,
        EONESZAKD: 'EON_ESZAK_D' as ProviderEnum,
        EONDELD: 'EON_DEL_D' as ProviderEnum,
        EONTISZANTUL: 'EON_TISZANTUL' as ProviderEnum,
        CYEB: 'CYEB' as ProviderEnum,
        E2: 'E2' as ProviderEnum,
        ALTEO: 'ALTEO' as ProviderEnum,
        NKMARAM: 'NKM_ARAM' as ProviderEnum,
        NKMGAZ: 'NKM_GAZ' as ProviderEnum,
        NKMGAZVP: 'NKM_GAZ_VP' as ProviderEnum,
        EMOGA: 'EMOGA' as ProviderEnum,
        HUNGAROENERGY: 'HUNGARO_ENERGY' as ProviderEnum,
        MVMPARTNER: 'MVM_PARTNER' as ProviderEnum,
        EONSZOLG: 'EON_SZOLG' as ProviderEnum,
        FOGAZ: 'FOGAZ' as ProviderEnum,
        MASZ: 'MASZ' as ProviderEnum,
        DEMASZ: 'DEMASZ' as ProviderEnum
    };
}