/**
 * OLC System
 * Ügyfél és számlakezelő rendszer
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface InvoiceProcessDto { 
    comment?: string;
    denied_cnt?: number;
    id?: number;
    processEnd?: Date;
    processStart?: Date;
    processed_cnt?: number;
    status?: InvoiceProcessDto.StatusEnum;
    volumen_cnt?: number;
}
export namespace InvoiceProcessDto {
    export type StatusEnum = 'RUNNING' | 'FINISHED' | 'ERROR';
    export const StatusEnum = {
        RUNNING: 'RUNNING' as StatusEnum,
        FINISHED: 'FINISHED' as StatusEnum,
        ERROR: 'ERROR' as StatusEnum
    };
}