import { Component, OnInit, ViewChild } from '@angular/core';
import { MeterControllerService, TreeItem, EnumControllerService, LabelValue, MeterValueDto, MeterDto } from 'src/app/model';
import { ComboItem } from '../contract-manager/combo-item.inteface';
import { CommonService } from '../../common/common.service';
import { ToasterService } from '../shared/toaster/toaster.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { MeterListComponent } from '../meter-list/meter-list.component';
import { ConfirmationService } from 'primeng/api';
import { MeterValueRequest } from '../../../model/model/models';
import { take } from 'rxjs/operators';
import { ConsuptionUnitEnum } from '../../common/enums/consuptionUnit';

interface MeterPostionDTO {
  consuption: number;
  production: number;
  customerId: number;
  customerName: string;
  customerAddress: string;
  meterId: number;
  podId: number;
  podType: string;
  podName: string;
  readingDate: string;
  unit: string;
  uploadFileName: string;
  uploadFileName2: string;
}

@Component({
  selector: 'meter-position',
  templateUrl: './meter-position.component.html',
  styleUrls: ['./meter-position.component.css'],
})
export class MeterPositionComponent implements OnInit {
  @ViewChild('meterList', { static: true }) meterList: MeterListComponent;

  podID: string;
  treeItem: TreeItem;
  visi: boolean = false;

  public podTypeOptions: ComboItem[] = [];
  public rhdTariffOptions: ComboItem[] = [];
  public podControlOptions: ComboItem[] = [];
  public currentMeterId: number;
  public currentMeter: MeterDto;
  private subscriptions: Subscription[] = [];
  public show: boolean = false;

  public uploadDocumentHeader: string;
  public showUploadDocument: boolean = false;
  public uploadedFileType: string;
  public selectedMeterValueID: number;
  public unitOptions: LabelValue[] = [];
  public meterValues: MeterValueDto[] = [];
  public meterPositionFormValues: MeterPostionDTO = {} as MeterPostionDTO;
  uploadedFile: File;
  uploadedFile2: File;

  uploadedFile3: File;
  uploadFileName3: string;
  public cols: any;

  save() {
    if (!this.validate()) {
      return;
    }

    let request: MeterValueRequest = {
      consuption: this.meterPositionFormValues.consuption,
      meterId: this.currentMeterId,
      production: this.meterPositionFormValues.production,
      readingDate: this.meterPositionFormValues.readingDate,
      unit: this.meterPositionFormValues.unit,
    };

    this.meterControllerService;
    // .addMeterValueUsingPOST(request, this.commonService.getCurrentUser().token)

    this.subscriptions.push(
      this.meterControllerService
        .addMeterValueUsingPOST(request, this.commonService.getCurrentUser().token)
        .pipe(take(1))


        .subscribe(async (data) => {
          await this.uploadDocument('CONSUPTION', data.id);
          await this.uploadDocument('PRODUCTION', data.id);
          this.finishSave();
        })
    );
  }

  async uploadDocument(type, meterId) {
    let uploadedDoc: File;
    if (type === 'CONSUPTION') {
      uploadedDoc = this.uploadedFile;
      this.meterPositionFormValues.uploadFileName = null;
      this.uploadedFile = null;
    }
    if (type === 'PRODUCTION') {
      uploadedDoc = this.uploadedFile2;
      this.meterPositionFormValues.uploadFileName2 = null;
      this.uploadedFile2 = null;
    }
    if (uploadedDoc) {
      await this.meterControllerService
      .addMeterValuePhotoUsingPOST(uploadedDoc, this.commonService.getCurrentUser().token, meterId, type).toPromise()

      /*
      this.subscriptions.push(
        this.meterControllerService
          .addMeterValuePhotoUsingPOST(uploadedDoc, this.commonService.getCurrentUser().token, meterId, type)
          .subscribe(() => {
            this.finishSave();
          })
      ); */
    }
  }

  finishSave() {
    this.meterPositionFormValues.consuption = null;
    this.meterPositionFormValues.production = null;
    this.meterPositionFormValues.readingDate = null;
    this.meterPositionFormValues.unit = null;
    this.getList(this.currentMeterId);
  }

  isInactiveMeter(): boolean {
    return false;
    if (this.currentMeter) {
      return this.currentMeter.status == 'ACTIVE' ? false : true;
    } else return true;
  }
  isDisabledSaveMeterButton() {
    return false;
    return (
      this.meterPositionFormValues.consuption == undefined ||
      this.meterPositionFormValues.unit == undefined ||
      this.meterPositionFormValues.readingDate == undefined ||
      this.currentMeterId == undefined
    );
  }

  constructor(
    private commonService: CommonService,
    private meterControllerService: MeterControllerService,
    private enumService: EnumControllerService,
    private toasterService: ToasterService,
    private confirmationService: ConfirmationService
  ) {}
  setData(meterId: number) {
    this.meterValues = [];
    this.meterPositionFormValues = {} as MeterPostionDTO;
    if (!meterId) {
      if (this.meterList) {
        this.meterList.table.selection = null;
      }
    }
    if (this.treeItem == undefined) {
      return;
    }
    if (this.podTypeOptions.map((item) => item.value).filter((pod) => pod == this.treeItem.type).length == 1) {
      const customerItem = this.commonService.getCustomerByTreeItem(this.treeItem);
      this.setUnitOptions(this.treeItem.type);
      this.meterPositionFormValues.podId = this.treeItem.podId;
      this.meterPositionFormValues.podName = this.treeItem.label;
      this.meterPositionFormValues.podType = this.treeItem.type;
      this.meterPositionFormValues.customerAddress = this.treeItem.parent.label;
      this.meterPositionFormValues.customerId = parseInt(customerItem.key);
      this.meterPositionFormValues.customerName = customerItem.label;
      this.meterPositionFormValues.readingDate = this.getTodayString();

      this.getList(meterId);
    }
  }

  setUnitOptions(podType: string) {
    this.unitOptions = this.commonService.getUnitOptionsByPodType(podType);
  }

  initEnums() {
    this.subscriptions.push(
      this.enumService
        .listPodTypesUsingGET(this.commonService.getCurrentUser().token)
        .pipe(take(1))
        .subscribe((resp) => {
          resp.labelValues.forEach((labelValue) => {
            this.podTypeOptions.push({ label: labelValue.label, value: labelValue.value });
          });
          this.setData(null);
        })
    );
    /*
    this.subscriptions.push(
      this.enumService
        .listRhdTariffsUsingGET(this.commonService.getCurrentUser().token)
        .pipe(take(1))
        .subscribe((resp) => {
          resp.labelValues.forEach((labelValue) => {
            this.rhdTariffOptions.push({ label: labelValue.label, value: labelValue.value });
          });
        })
    );
    this.subscriptions.push(
      this.enumService
        .listPodControlsUsingGET(this.commonService.getCurrentUser().token)
        .pipe(take(1))
        .subscribe((resp) => {
          resp.labelValues.forEach((labelValue) => {
            this.podControlOptions.push({ label: labelValue.label, value: labelValue.value });
          });
        })
    );
    */
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  ngOnInit() {
    this.initEnums();
    this.cols = [
      { field: 'readingDate', header: 'Leolvasás ideje', size: '80px', align: 'center' },
      { field: 'consuption', header: 'Mérőóra állás (fogyasztás)', size: '100px', align: 'right' },
      { field: 'difference', header: 'Különbözet (fogyasztás)', size: '100px', align: 'right' },

      { field: 'production', header: 'Mérőóra állás (termelés)', size: '100px', align: 'right' },
      { field: 'productionDifference', header: 'Különbözet (termelés)', size: '100px', align: 'right' },

      { field: 'userName', header: 'Bejelentő', size: '80px', align: 'left' },
      //{ field: 'productionConsuptiondifference', header: 'Különbözet', size: '100px', align: 'right' },

      { field: 'operations', header: 'Műveletek', size: '140px', align: 'center' },
    ];

    this.subscriptions.push(
      this.commonService.connector.pipe().subscribe((data) => {
        this.treeItem = data.selectedTreeItem;
        this.currentMeterId = undefined;
        this.commonService.setDisableTree(false);
        this.setData(null);
      })
    );
  }

  getMaxUploadFileSize(): number {
    return this.commonService.maxUploadFileSize;
  }
  uploadFileChange(event): void {
    let target = event.target || event.srcElement;
    this.uploadedFile = target.files[0];
    this.meterPositionFormValues.uploadFileName = this.uploadedFile.name;
    target.value = '';
  }
  uploadFileChange2(event): void {
    let target = event.target || event.srcElement;
    this.uploadedFile2 = target.files[0];
    this.meterPositionFormValues.uploadFileName2 = this.uploadedFile2.name;
    target.value = '';
  }
  uploadFileChange3(event): void {
    let target = event.target || event.srcElement;
    this.uploadedFile3 = target.files[0];
    this.uploadFileName3 = this.uploadedFile3.name;
    target.value = '';
  }
  getList(meterId: number) {
    if (!meterId) return;
    this.subscriptions.push(
      this.meterControllerService
        .listMeterValuesUsingGET(meterId, this.commonService.getCurrentUser().token)
        .pipe(take(1))
        .subscribe((data) => {
          this.meterValues = [...data.meterValues];

          if (this.meterPositionFormValues.podType == 'ELECTRIC') this.meterPositionFormValues.unit = ConsuptionUnitEnum[ConsuptionUnitEnum.kWh];

          if (this.meterPositionFormValues.podType == 'WATER') this.meterPositionFormValues.unit = ConsuptionUnitEnum[ConsuptionUnitEnum.M3];

          if (this.meterPositionFormValues.podType == 'GAS') {
            if (this.meterValues.length > 0) this.meterPositionFormValues.unit = this.meterValues[0].consuptionUnit;
          }
        })
    );
  }

  validate(): boolean {
    if (this.meterPositionFormValues.readingDate == undefined) {
      this.toasterService.showToaster({
        severity: 'error',
        summary: 'Hibás dátum',
        detail: 'A leolvasás ideje nem lehet üres',
        life: 30000,
      });
      return false;
    }

    if (!this.meterPositionFormValues.unit) {
      this.toasterService.showToaster({
        severity: 'error',
        summary: 'Hibás mértékegység',
        detail: 'A mértékegység nem lehet üres',
        life: 30000,
      });
      return false;
    }
    if (this.meterPositionFormValues.consuption == undefined) {
      this.toasterService.showToaster({
        severity: 'error',
        summary: 'Hibás mennyiség',
        detail: 'A leolvasott mennyiség nem lehet üres',
        life: 30000,
      });
      return false;
    }

    if (this.meterList.isTwoWayMeter()) {
      if (this.meterPositionFormValues.production == undefined) {
        this.toasterService.showToaster({
          severity: 'error',
          summary: 'Hibás mennyiség',
          detail: 'A leolvasott mennyiség nem lehet üres',
          life: 30000,
        });
        return false;
      }
    }

    //if (this.meterValues.length > 0) {
    //let formDate = new Date(this.meterPositionFormValues.readingDate);
    /*
      let lastDate = new Date(this.meterValues[0].readingDate);
      if (lastDate >= formDate) {
        this.toasterService.showToaster({
          severity: 'error',
          summary: 'Hibás dátum',
          detail: 'A leolvasás ideje nem lehet korábbi mint az előző leolvasás ideje',
          life: 30000,
        });
        return false;
      }
      */

    //  if (this.meterValues[0].consuption > this.meterPositionFormValues.consuption) {
    //    this.toasterService.showToaster({
    //      severity: 'error',
    //      summary: 'Hibás mennyiség',
    //      detail: 'A leolvasott mennyiség nem lehet kevesebb mint az előző leolvasott mennyiség',
    //      life: 30000,
    //    });
    //    return false;
    //  }
    //}
    return true;
  }

  deleteMeterPosition(rowData: MeterValueDto) {
    this.confirmationService.confirm({
      message: `Biztos hogy törölni szeretné ezt a mérőóraállást?`,
      accept: () => {
        this.meterControllerService
          .deleteMeterValueUsingGET(rowData.id, this.commonService.getCurrentUser().token)
          .pipe(take(1))
          .subscribe(() => {
            this.setData(this.currentMeterId);
          });
      },
    });
  }

  downloadDocument(uuid: string) {
    this.commonService.downloadDocument(uuid, uuid);
  }

  getTableHeightInpx(): number {
    return this.commonService.getTableHeight();
  }
  getTablePageSize(): number {
    return this.commonService.tablePageSize;
  }

  getTableWidthInpx(): number {
    return this.commonService.getTableWidth();
  }

  selectMeter(event: any) {
    if (event) {
      this.currentMeterId = event.data.id;
      this.currentMeter = event.data;
      this.meterPositionFormValues.meterId = event.data.id;
      this.setData(this.currentMeterId);
    } else {
      this.currentMeterId = null;
    }
  }
  getTodayString(): string {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();

    return `${yyyy}-${mm}-${dd}`;
  }

  exportExcelConfirmation() {
    this.confirmationService.confirm({
      message: `Szeretné a mérőóra állásokat exportálni?`,
      accept: () => {
        this.exportMeterPositionsToExcel();
      },
    });
  }

  exportMeterPositionsToExcel() {
    this.subscriptions.push(
      this.meterControllerService
        .exportMeterValuesUsingGET(this.currentMeterId, this.commonService.getCurrentUser().token, 'response')
        .subscribe((data) => {
          import('file-saver').then((FileSaver) => {
            FileSaver.saveAs(
              data.body,
              this.commonService.getFilenameFromHeader(data, 'meterValue_export_' + new Date().getTime() + '.xlsx')
            );
          });
        })
    );
  }

  isDisableExport(): boolean {
    return this.meterValues.length === 0;
  }

  isHidden(field) {
    return !this.meterList.isTwoWayMeter() && ['production', 'productionDifference', 'productionConsuptiondifference'].includes(field);
  }

  getProductionConsuptiondifference(rowData) {
    return rowData.difference - rowData.productionDifference;
  }

  openUploadDocument(type, id) {
    this.showUploadDocument = true;
    this.selectedMeterValueID = id;
    this.uploadedFileType = type;
    type == 'PRODUCTION'
      ? (this.uploadDocumentHeader = 'Termeléshez tartozó dokumentum feltöltése')
      : (this.uploadDocumentHeader = 'Fogyasztáshoz tartozó dokumentum feltöltése');
  }

  uploadFile() {
    if (this.uploadedFile3) {
      this.subscriptions.push(
        this.meterControllerService
          .addMeterValuePhotoUsingPOST(
            this.uploadedFile3,
            this.commonService.getCurrentUser().token,
            this.selectedMeterValueID,
            this.uploadedFileType
          )
          .subscribe(() => {
            this.cancelUpload();
            this.getList(this.currentMeterId);
          })
      );
    }
  }

  cancelUpload() {
    this.showUploadDocument = false;
    this.uploadedFileType = null;
    this.selectedMeterValueID = null;
    this.uploadDocumentHeader = null;
    this.uploadFileName3 = null;
    this.uploadedFile3 = null;
  }
  copyData(rowData: MeterPostionDTO) {
    this.meterPositionFormValues.readingDate = rowData.readingDate;
    this.meterPositionFormValues.consuption = rowData.consuption;
    if (this.meterList.isTwoWayMeter()) this.meterPositionFormValues.production = rowData.production;
  }
}
