import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { CommonService } from '../../common/common.service';
import {
  EnumControllerService,
  PodControllerService,
  TreeItem,
  PodDto,
  ListPodItem,
  ListPodRequest,
  CustomerTreeControllerService,
  SavePodRequest,
  BatchPodUpdateRequest,
} from 'src/app/model';
import { ConfirmationService, LazyLoadEvent } from 'primeng/api';
import { Subscription } from 'rxjs';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { TABLE_SELECTOR } from '../../common/enums/table-selector';
import { PODFilter } from './podlist-filter';
import { ComboItem } from '../contract-manager/combo-item.inteface';
import { MeterPositionComponent } from '../meter-position/meter-position.component';
import { LabelValue } from '../../../model/model/models';

@Component({
  selector: 'pod-list',
  templateUrl: './pod-list.component.html',
  styleUrls: ['./pod-list.component.css'],
})
export class PodListComponent implements OnInit, OnDestroy {
  @ViewChild('p', { static: true }) paginator: Paginator;
  @ViewChild('dt', { static: false }) table: Table;
  @ViewChild('meterPosition', { static: false }) meterPosition: MeterPositionComponent;
  constructor(
    private commonService: CommonService,
    private enumService: EnumControllerService,
    private confirmationService: ConfirmationService,
    private podControllerService: PodControllerService,
    private customerTreeService: CustomerTreeControllerService
  ) {}

  public cols: any;
  selectedTreeItem: TreeItem;
  private subscriptions: Subscription[] = [];
  public displayPodEditor: boolean;
  public currentInc: number = 0;
  public pageNum: number = 0;
  public sortField: string;
  public sortOrder: number = 0;
  public justDeletedPOD: boolean;
  public pods: ListPodItem[];
  isGroupingEditing: boolean = false;
  showMeterPosition = false;
  //public selectedPod: ListPodItem;
  public selectedPods: ListPodItem[];

  public filterValues: PODFilter = new PODFilter();
  public PODsTotalCount: number = 0;

  podFormValues: PodDto = {};
  public podTypeOptions: ComboItem[] = [];
  public rhdTariffOptions: ComboItem[] = [];
  public podStatusOptions: LabelValue[] = [];
  public podControlOptions: ComboItem[] = [];
  properties = ['control', 'rhdTariff', 'availableEnergy', 'reservedEnergy', 'overConsuptionPercent', 'belowConsuptionPercent', 'mef', 'capacity'];
  ngOnInit() {
    this.setTableCols();
    this.subscriptions.push(
      this.commonService.connector.subscribe((data) => {
        this.selectedTreeItem = data.selectedTreeItem;
        this.filter();
      })
    );
    this.initDropdowns();
  }

  public filter() {
    this.commonService.setDisableTree(false);
    this.listPODs();
    this.currentInc = (this.paginator.getPage() || 0) * this.commonService.tablePageSize;
    this.selectedPods = [];
  }

  buildListPODSRequest(): ListPodRequest {
    if (this.filterValues != null) status = this.filterValues.status;
    if (this.justDeletedPOD) status = 'DELETED';

    let request: ListPodRequest = {
      direction: this.sortOrder,
      sortField: this.sortField,
      pageNumber: this.paginator.getPage() || 0,
      //pageNumber: 0,
      pageSize: this.commonService.tablePageSize,

      type: this.filterValues != null ? this.filterValues.type : null,
      podName: this.filterValues != null ? this.filterValues.podName : null,
      status: this.filterValues != null ? this.filterValues.status : null,
      treeId: this.selectedTreeItem == null ? null : parseInt(this.selectedTreeItem.key),
    };
    return request;
  }

  listPODs() {
    if (TABLE_SELECTOR.PODS != this.commonService.getselectTable()) {
      return;
    }
    this.subscriptions.push(
      this.podControllerService
        .listPodsUsingPOST(this.buildListPODSRequest(), this.commonService.getCurrentUser().token)
        .subscribe((data) => {
          this.PODsTotalCount = data.podPage.totalElements;
          this.pods = data.podPage.content;
        })
    );
  }

  private setTableCols() {
    this.cols = [
      { field: 'customer', header: 'Ügyfél', size: '250px', align: 'left' },
      { field: 'name', header: 'POD', size: '250px', align: 'left' },
      { field: 'address', header: 'Cím', size: '250px', align: 'left' },
      { field: 'status', header: 'Státusz', size: '100px', align: 'center' },

      { field: 'availableEnergy', header: 'Rendelkezésre álló teljesítmény', size: '100px', align: 'center' },
      { field: 'control', header: 'Vezérlés', size: '100px', align: 'center' },
      { field: 'reservedEnergy', header: 'Lekötött mennyiség', size: '100px', align: 'center' },
      { field: 'rhdTariff', header: 'RHD tarifa', size: '100px', align: 'center' },

      { field: 'operations', header: 'Műveletek', size: '100px', align: 'center' },
    ];
  }

  public sort(event: LazyLoadEvent) {
    this.sortField = event.sortField;
    this.sortOrder = event.sortOrder;
    this.listPODs();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  initDropdowns() {
    this.podStatusOptions.push({ label: 'Aktív', value: 'ACTIVE' });
    this.podStatusOptions.push({ label: 'Inaktív', value: 'INACTIVE' });
    this.subscriptions.push(
      this.enumService.listPodTypesUsingGET(this.commonService.getCurrentUser().token).subscribe((resp) => {
        resp.labelValues.forEach((labelValue) => {
          this.podTypeOptions.push({ label: labelValue.label, value: labelValue.value });
        });
      })
    );
    this.subscriptions.push(
      this.enumService.listRhdTariffsUsingGET(this.commonService.getCurrentUser().token).subscribe((resp) => {
        resp.labelValues.forEach((labelValue) => {
          this.rhdTariffOptions.push({ label: labelValue.label, value: labelValue.value });
        });
      })
    );
    this.subscriptions.push(
      this.enumService.listPodControlsUsingGET(this.commonService.getCurrentUser().token).subscribe((resp) => {
        resp.labelValues.forEach((labelValue) => {
          this.podControlOptions.push({ label: labelValue.label, value: labelValue.value });
        });
      })
    );
  }

  public paginate(): void {
    this.currentInc = (this.paginator.getPage() || 0) * this.commonService.tablePageSize;
    this.listPODs();
  }

  getTableHeightInpx(): string {
    return this.commonService.getTableHeightInpx();
  }
  getTableWidthInpx(): string {
    return this.commonService.getTableWidthInpx();
  }
  tablePageSize(): number {
    return this.commonService.tablePageSize;
  }

  getTableHeight(): number {
    return this.commonService.getTableHeight() + 36;
  }

  public deletePOD(item: ListPodItem) {
    this.confirmationService.confirm({
      message: 'Szeretné törölni a(z) <b>' + item.name + '</b> nevű elemet?',
      accept: () => {
        this.customerTreeService.deleteUsingGET(item.treeId, this.commonService.getCurrentUser().token).subscribe(() => {
          this.filter();
        });
      },
    });
  }

  public editPOD(item: ListPodItem) {
    this.podControllerService.getPodUsingGET(item.id, this.commonService.getCurrentUser().token).subscribe((resp) => {
      this.podFormValues = resp.pod;
      this.displayPodEditor = true;
      this.isGroupingEditing = false;
    });
  }

  activatePod(item: TreeItem) {
    this.confirmationService.confirm({
      message: 'Szeretné <b>aktiválni</b> a(z) ' + item.label + ' nevű POD-ot?',
      accept: () => {
        this.subscriptions.push(
          this.customerTreeService.activatePodUsingGET(item.treeId, this.commonService.getCurrentUser().token).subscribe(() => {
            this.filter();
            this.selectedPods = [];
          })
        );
      },
    });
  }

  inactivatePod(item: ListPodItem) {
    this.confirmationService.confirm({
      message: 'Szeretné <b>inaktiválni</b> a(z) ' + item.name + ' nevű POD-ot?',
      accept: () => {
        this.subscriptions.push(
          this.customerTreeService.inactivatePodUsingGET(item.treeId, this.commonService.getCurrentUser().token).subscribe(() => {
            this.filter();
            this.selectedPods = [];
          })
        );
      },
    });
  }

  checkIsGrouping() {
    return !(this.selectedPods.length >= 2 && [...new Set(this.selectedPods.map((pod) => pod.type))].length == 1);
  }

  isEnableMeterPositionButton() {
    return this.selectedPods.length == 0;
  }

  test() {
    this.showMeterPosition = true;
  }

  groupEditing() {
    this.isGroupingEditing = true;
    this.resetFormValues();
    this.podFormValues.type = this.selectedPods[0].type;
    this.displayPodEditor = true;
  }

  cancelEdit() {
    this.displayPodEditor = false;
    this.isGroupingEditing = false;
  }

  savePod() {
    if (this.isGroupingEditing) {
      let req: BatchPodUpdateRequest = {} as BatchPodUpdateRequest;
      req.podIds = this.selectedPods.map((pod) => pod.id);
      req.values = {};
      this.properties.forEach((property) => {
        if (this.podFormValues[property] != null) {
          req.values[property] = this.podFormValues[property].toString();
        }
      });
      this.subscriptions.push(
        this.podControllerService.batchUpdateUsingPOST(req, this.commonService.getCurrentUser().token).subscribe(() => {
          this.displayPodEditor = false;
          this.filter();
          this.selectedPods = [];
        })
      );
    } else {
      if (this.podFormValues.type != 'ELECTRIC') {
        this.podFormValues.rhdTariff = null;
        this.podFormValues.control = null;
      }
      let req: SavePodRequest = {
        parentPath: undefined,
        pod: this.podFormValues,
      };
      this.subscriptions.push(
        this.podControllerService.saveUsingPOST(req, this.commonService.getCurrentUser().token).subscribe(() => {
          this.displayPodEditor = false;
          this.filter();
          this.selectedPods = [];
        })
      );
    }
  }

  isDisabled() {
    let result: boolean = true;
    this.properties.forEach((property) => {
      if (this.podFormValues[property] != null) {
        result = false;
      }
    });
    if (this.isGroupingEditing) {
      return result;
    }

    if (this.podFormValues === null) {
      return true;
    }
  
    if (this.podFormValues.type === null) {
      return true;
    }
  
    if (this.podFormValues.type == 'ELECTRIC' && (this.podFormValues.name + '').length == 33) {
      return false;
    }
  
    if (this.podFormValues.type =='GAS' && (this.podFormValues.name + '').length == 16) {
      return false;
    }
  
    if (this.podFormValues.type == 'WATER' && (this.podFormValues.name + '').length > 0) {
      return false;
    }
  
    if (this.podFormValues.type == 'HEAT' && (this.podFormValues.name + '').length > 0) {
      return false;
    } 
  
    return true;


  }

  resetFormValues() {
    this.podFormValues.availableEnergy = null;
    this.podFormValues.reservedEnergy = null;
    this.podFormValues.rhdTariff = null;
    this.podFormValues.control = null;
    this.podFormValues.mef = null;
    this.podFormValues.capacity = null;
    this.podFormValues.belowConsuptionPercent = null;
    this.podFormValues.overConsuptionPercent = null;
  }

  public resetFilter() {
    this.filterValues = new PODFilter();
    this.filter();
  }

  findLabel(options: LabelValue[], value: string): string {
    return this.commonService.findLabel(options, value);
  }

  public getCurrentPageReportTemplate() {
    let from = this.paginator.getPage() * this.commonService.tablePageSize + 1;
    let to = (this.paginator.getPage() + 1) * this.commonService.tablePageSize;
    const total = this.PODsTotalCount | 0;
    to = Math.min(to, total);
    return `{currentPage}. oldal [ ${from} - ${to}  a(z) ${total} -ből]`;
  }

  isAdminUser(): boolean {
    return this.commonService.isAdminUser();
  }

  isDisableExport(): boolean {
    return this.pods == null || this.pods.length == 0;
  }

  exportExcelConfirmation() {
    this.confirmationService.confirm({
      message: `Szeretné a(z) <strong>${this.PODsTotalCount} darab</strong> POD-t exportálni?`,
      accept: () => {
        this.exportPODsToExcel();
      },
    });
  }

  exportContractExcelConfirmation() {
    this.confirmationService.confirm({
      message: `Szeretné a(z) <strong>${this.PODsTotalCount} darab</strong> POD-t exportálni szerződésekkel együtt?`,
      accept: () => {
        this.exportPODsWithContractToExcel();
      },
    });
  }

  exportPODsToExcel() {
    const req: ListPodRequest = this.buildListPODSRequest();
    this.subscriptions.push(
      this.podControllerService.exportListPodsUsingPOST(req, this.commonService.getCurrentUser().token, 'response').subscribe((data) => {
        import('file-saver').then((FileSaver) => {
          FileSaver.saveAs(data.body, this.commonService.getFilenameFromHeader(data, 'pod_export_' + new Date().getTime() + '.xlsx'));
        });
      })
    );
  }

  exportPODsWithContractToExcel() {
    const req: ListPodRequest = this.buildListPODSRequest();
    this.subscriptions.push(
      this.podControllerService.exportWithContractUsingPOST(req, this.commonService.getCurrentUser().token, 'response').subscribe((data) => {
        import('file-saver').then((FileSaver) => {
          FileSaver.saveAs(data.body, this.commonService.getFilenameFromHeader(data, 'pod_export_' + new Date().getTime() + '.xlsx'));
        });
      })
    );

  }

}
