/**
 * OLC System
 * Ügyfél és számlakezelő rendszer
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';

import { HttpClient, HttpHeaders,
         HttpResponse, HttpEvent }                           from '@angular/common/http';


import { Observable }                                        from 'rxjs';

import { ExecuteEventRequest } from '../model/executeEventRequest';
import { LabelValue } from '../model/labelValue';
import { StateInvoiceLogDto } from '../model/stateInvoiceLogDto';
import { StatePodConfigDto } from '../model/statePodConfigDto';
import { WorkflowDto } from '../model/workflowDto';
import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { environment } from '../../../environments/environment';



@Injectable()
export class WorkflowControllerService {

    protected basePath = environment.apiBaseUrl + '/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

     /**
     * executeEvent
     * 
     * @param body request
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public executeEventUsingPOST(body: ExecuteEventRequest, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public executeEventUsingPOST(body: ExecuteEventRequest, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public executeEventUsingPOST(body: ExecuteEventRequest, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public executeEventUsingPOST(body: ExecuteEventRequest, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling executeEventUsingPOST.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/workflow/execute`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

     /**
     * getAvailableWorkflow
     * 
     * @param customerId customerId
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAvailableWorkflowUsingPOST(customerId: number, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<WorkflowDto>;
    public getAvailableWorkflowUsingPOST(customerId: number, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WorkflowDto>>;
    public getAvailableWorkflowUsingPOST(customerId: number, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WorkflowDto>>;
    public getAvailableWorkflowUsingPOST(customerId: number, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling getAvailableWorkflowUsingPOST.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.post<WorkflowDto>(`${this.basePath}/workflow/availableWorkflow/${encodeURIComponent(String(customerId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }




    /**
     * getEvents
     * 
     * @param invoiceId invoiceId
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEventsUsingGET(invoiceId: number, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<LabelValue>>;
    public getEventsUsingGET(invoiceId: number, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LabelValue>>>;
    public getEventsUsingGET(invoiceId: number, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LabelValue>>>;
    public getEventsUsingGET(invoiceId: number, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invoiceId === null || invoiceId === undefined) {
            throw new Error('Required parameter invoiceId was null or undefined when calling getEventsUsingGET.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<Array<LabelValue>>(`${this.basePath}/workflow/events/${encodeURIComponent(String(invoiceId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * listStateInvoiceLog
     * 
     * @param invoiceId invoiceId
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listStateInvoiceLogUsingGET(invoiceId: number, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<StateInvoiceLogDto>>;
    public listStateInvoiceLogUsingGET(invoiceId: number, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StateInvoiceLogDto>>>;
    public listStateInvoiceLogUsingGET(invoiceId: number, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StateInvoiceLogDto>>>;
    public listStateInvoiceLogUsingGET(invoiceId: number, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invoiceId === null || invoiceId === undefined) {
            throw new Error('Required parameter invoiceId was null or undefined when calling listStateInvoiceLogUsingGET.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<Array<StateInvoiceLogDto>>(`${this.basePath}/workflow/log/${encodeURIComponent(String(invoiceId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * loadConfig
     * 
     * @param treeId treeId
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loadConfigUsingGET(treeId: number, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<StatePodConfigDto>;
    public loadConfigUsingGET(treeId: number, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StatePodConfigDto>>;
    public loadConfigUsingGET(treeId: number, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StatePodConfigDto>>;
    public loadConfigUsingGET(treeId: number, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (treeId === null || treeId === undefined) {
            throw new Error('Required parameter treeId was null or undefined when calling loadConfigUsingGET.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<StatePodConfigDto>(`${this.basePath}/workflow/config/${encodeURIComponent(String(treeId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * saveConfig
     * 
     * @param body config
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveConfigUsingPOST(body: StatePodConfigDto, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public saveConfigUsingPOST(body: StatePodConfigDto, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public saveConfigUsingPOST(body: StatePodConfigDto, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public saveConfigUsingPOST(body: StatePodConfigDto, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling saveConfigUsingPOST.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/workflow/config`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
