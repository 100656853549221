import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../../common/common.service';
import { TABLE_SELECTOR } from '../../common/enums/table-selector';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { UserControllerService } from '../../../model/api/userController.service';
import { ChangePasswordRequest } from '../../../model/model/changePasswordRequest';
import { EnumControllerService } from '../../../model/api/enumController.service';
import { NotificationControllerService } from '../../../model/api/notificationController.service';
import { TreeUtils } from '../../common/tree.utils';
import { LoginControllerService } from '../../user-surface/login/service/loginController.service';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { version } from '../../../../../package.json';
import { ChangePinRequest } from 'src/app/model';
import { NgForm } from '@angular/forms';
import { UserDetailsDto } from '../../../model/model/userDetailsDto';

declare var jQuery: any;

interface PinDto { 
  pin: number;
  pin1: number;
  pin2: number;
}

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})


export class NavbarComponent implements OnInit {
  constructor(
    private treeUtils: TreeUtils,
    private commonService: CommonService,
    private loginService: LoginControllerService,
    private notificationService: NotificationControllerService,
    private router: Router,
    private userService: UserControllerService,
    private enumService: EnumControllerService,
    private confirmationService: ConfirmationService
  ) {}
  @ViewChild('formChangePin', { static: true }) formChangePin: NgForm;

  profileMenu: MenuItem[];
  items: MenuItem[];
  home: MenuItem;
  displayChangePasswordEditor: boolean;
  displayChangePinEditor: boolean;
  password: string;
  public pinChangeFormValues: PinDto = {} as PinDto;// = { pin: null, pin1: null, pin2: null };

  //pin: number;
  //pin1: number;
  //pin2: number;

  password2: string;
  timer: any;
  displaySettings: boolean;

  originalPassword: string;
  public buttonPanelHeight: number;
  public curentUserName: string;
  public currentUserDetails : UserDetailsDto;
  public currentUserAuthority: string;
  public roleOptions: any[] = [];
  public menuItems: MenuItem[] = [];
  public settingKeys: any[];
  public version: string = version;

  ngOnInit() {
    this.buttonPanelHeight = this.commonService.headerPanelHeight / 2;
    this.commonService.loginSubject.subscribe(() => {
      this.curentUserName = this.commonService.getCurrentUser().username;
      this.currentUserDetails = <UserDetailsDto>{};
      this.currentUserAuthority = this.commonService.getCurrentUser().authority;
      this.initMenu();
      this.initNotificationTypes();
      this.initUserInfo();
      this.commonService.selectTable(TABLE_SELECTOR.INVOICES);
      this.addHighlightMenu();
    });
    this.startUnreadNotificationsPolling();

    this.commonService.connector.subscribe((data) => {
      if (data != null && data.selectedTreeItem != null) {
        this.items = this.treeUtils.getTreeChain(data.selectedTreeItem);
        this.items.forEach((item) => {
          item.command = (event) => {
            this.breadcrumbItemClick(event);
          };
        });
      }
    });
    this.roleEnums();
    this.home = {
      icon: 'pi pi-home',
      command: (event) => {
        this.homeClick(event);
      },
    };
    this.profileMenu = [
      {
        items: [
          {
            label: 'Jelszó módosítás',
            icon: 'pi pi-key',
            command: () => {
              this.openChangePassword();
            },
          },
          {
            label: 'PIN módosítás',
            icon: 'pi pi-key',
            command: () => {
              this.openChangePin();
            },
          },
          {
            label: 'Beállítások kezelése',
            icon: 'pi pi-cog',
            command: () => {
              this.openSettings();
            },
          },
        ],
      },
      {
        items: [
          {
            label: 'Hibabejelentő',
            id: 'menu_bugReport',
            icon: 'pi pi-bell',
            command: () => {
              (window as any).showCollectorDialog();
            },
          },
        ],
      },
    ];
  }

  initNotificationTypes() {
    if (this.commonService.notificationTypeOptions == null)
      this.enumService.listNotificationTypesUsingGET(this.commonService.getCurrentUser().token).subscribe((data) => {
        this.commonService.notificationTypeOptions = data.labelValues;
      });
  }

  initUserInfo() {
    this.userService.getCurrentUserDetailsUsingGET(this.commonService.getCurrentUser().token).subscribe((data) => {
      this.currentUserDetails = data;
    });
  }

  private addHighlightMenu() {
    setTimeout(() => {
      jQuery('.ui-menuitem').click(function () {
        jQuery('.customactive').removeClass('customactive');
        //@ts-ignore
        jQuery(this).addClass('customactive');
      });
      jQuery('#menu_szamlak').parent().addClass("customactive");
    }, 500);
  }

  private initMenu() {
    this.menuItems = [
      {
        label: 'Számlák',
        id: 'menu_szamlak',
        command: () => {
          this.commonService.selectTable(TABLE_SELECTOR.INVOICES);
        },
      },
      {
        label: 'Dokumentumok',
        id: 'menu_dokumentumok',
        command: () => {
          this.commonService.selectTable(TABLE_SELECTOR.DOCUMENTS);
        },
      },
      {
        label: 'Szerződések',
        id: 'menu_szerzodesek',
        command: () => {
          this.commonService.selectTable(TABLE_SELECTOR.CONTRACTS);
        },
      },
      {
        label: 'POD-ok',
        id: 'menu_podok',
        command: () => {
          this.commonService.selectTable(TABLE_SELECTOR.PODS);
        },
      },
      {
        label: 'Mérőóra állások',
        id: 'menu_merooraallasok',
        command: () => {
          this.commonService.selectTable(TABLE_SELECTOR.METERVALUE);
        },
      },
      /*{ label: 'Mérőóra állások', id: "menu_merooraallasok", command: event => { this.commonService.selectTable(TABLE_SELECTOR.METERVALUE); } },*/
    ];
    if (this.commonService.isAdminUser()) {
      let adminMenu: MenuItem = {
        label: 'Adminisztráció',
        icon: 'pi pi-fw pi-cog',
        items: [
          { label: 'Napló', id: "menu_naplo", icon: "fa fa-table", command: () => { this.commonService.selectTable(TABLE_SELECTOR.LOG); } },
          { label: 'Havi jelentés', id: "menu_havijelentesek", icon: "pi pi-chart-line", command: () => { this.commonService.selectTable(TABLE_SELECTOR.MONTHLY_REPORT); } },
          { label: 'Felhasználók', id: "menu_felhasznalok", icon: "pi pi-users", command: () => { this.commonService.selectTable(TABLE_SELECTOR.USERS); } },
          { label: 'Értesítések', id: "menu_ertesitesek", icon: "pi pi-tags", command: () => { this.openNotificationTable(); } },
          { label: 'Szolgáltatói integráció', id: "menu_szolgaltatoi_integracio", icon: "pi pi-sliders-v", command: () => { this.commonService.selectTable(TABLE_SELECTOR.PROVIDER_INTEGRATION); } },
          { label: 'Számlafeldolgozás', id: "menu_szamlafeldolgozo", icon: "pi pi-chart-bar", command: () => { this.openInvoiceProcessing(); } },
          { label: 'Riportok', id: "menu_report", icon: "pi pi-list", command: () => { this.openControlPanel(); } },
          { label: 'Beállítások', id: "menu_konfiguracio", icon: "pi pi-cog", command: () => { this.openConfigTable(); } },
        ],
      };
      this.menuItems.push({ separator: true });
      this.menuItems.push(adminMenu);
    }
  }

  private roleEnums() {
    this.enumService.listRolesUsingGET(this.commonService.getCurrentUser().token).subscribe((data) => {
      this.roleOptions = data.labelValues;
    });
  }

  public logout() {
    this.loginService.logout(this.commonService.getCurrentUser().token).subscribe(() => {
      localStorage.removeItem('currentUser');
      this.router.navigateByUrl('/login');
    });
  }

  getSettingDisplayName(key: string) {
    return this.commonService.getUserSettingBykey(key).displayname;
  }

  removeSavedUserSetting(key: string) {
    this.confirmationService.confirm({
      message: `Szeretné törölni "${this.getSettingDisplayName(key)}" beállítást?`,
      accept: () => {
        this.commonService.removeSetting(key);
        this.settingKeys = this.commonService.getUserSettingKeys();
      },
    });
  }

  public openSettings() {
    this.settingKeys = this.commonService.getUserSettingKeys();
    this.displaySettings = true;
  }

  public closeSettings() {
    this.displaySettings = false;
  }

  public openChangePassword() {
    this.originalPassword = '';
    this.password = '';
    this.password2 = '';
    this.displayChangePasswordEditor = true;
  }

  public openChangePin() {
    this.formChangePin.resetForm();
    this.displayChangePinEditor = true;
  }

  public changePin() {
    let req: ChangePinRequest = {
      pin: '' + this.pinChangeFormValues.pin1,
      originalPin: '' + this.pinChangeFormValues.pin,
    };
    this.userService.changePinUsingPOST(req, this.commonService.getCurrentUser().token).pipe(take(1)).subscribe(() => {
      this.displayChangePinEditor = false;
    });
  }

  public isDisabled() {
    if (!this.pinChangeFormValues.pin2 || !this.pinChangeFormValues.pin1) return true;

    return this.pinChangeFormValues.pin1 != this.pinChangeFormValues.pin2;
  }

  public changePassword() {
    let req: ChangePasswordRequest = {
      password: this.password,
      originalPassword: this.originalPassword,
    };
    //TODO: form validacio. password es password2 egyezik-e?
    this.userService.changePasswordUsingPOST(req, this.commonService.getCurrentUser().token).subscribe(() => {
      this.displayChangePasswordEditor = false;
    });
  }

  public breadcrumbItemClick(event: any) {
    this.commonService.selectTreeItem(event.item);
    this.commonService.selectedItem = event.item;
  }

  //@ts-ignore
  public homeClick(event: any) {
    this.items = [];
    this.commonService.selectTreeItem(null);
    this.commonService.selectedItem = null;
  }

  getUnreadNotifications() {
    if (!this.commonService.unreadNotifications || this.commonService.unreadNotifications.length < 10) {
      this.notificationService
        .listNotificationsUsingPOST({ direction: 1, pageNumber: 0, pageSize: 10, unread: true }, this.commonService.getCurrentUser().token)
        .pipe(take(1))
        .subscribe(
          (data) => {
            this.commonService.unreadNotifications = data.notificationPage.content;
          },
          //@ts-ignore
          (error) => {},
          () => {}
        );
    }
  }

  startUnreadNotificationsPolling() {
    if (this.isAdminUser()) {
      this.getUnreadNotifications();
      this.timer = setInterval(() => {
        if (localStorage.getItem('currentUser') != null) {
          if (TABLE_SELECTOR.NOTIFICATIONS != this.commonService.getselectTable()) {
            this.getUnreadNotifications();
          }
        } else {
          clearInterval(this.timer);
        }
      }, this.commonService.unreadNOtificationsRefreshIntervall);
    }
  }

  openNotificationTable() {
    this.commonService.selectTable(TABLE_SELECTOR.NOTIFICATIONS);
  }

  openInvoiceProcessing() {
    this.commonService.selectTable(TABLE_SELECTOR.INVOICE_PROCESSING);
  }

  openControlPanel() {
    this.commonService.selectTable(TABLE_SELECTOR.CONTROL_PANEL);
  }

  openConfigTable() {
    this.commonService.selectTable(TABLE_SELECTOR.CONFIG);
  }

  findLabel(value: string): string {
    return this.commonService.findLabel(this.commonService.notificationTypeOptions, value);
  }

  getNotificationTypeOptions(): any[] {
    return this.commonService.notificationTypeOptions;
  }
  unreadNotifications(): any[] {
    return this.commonService.unreadNotifications;
  }
  isAdminUser(): boolean {
    return this.commonService.isAdminUser();
  }

  clearSavedUserSettings() {
    this.confirmationService.confirm({
      message: `Szeretné törölni az összes elmentett beállítást?`,
      accept: () => {
        this.commonService.deleteUSerSetting();
        this.settingKeys = this.commonService.getUserSettingKeys();
      },
    });
  }
}
