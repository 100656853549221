import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from '../../common/common.service';
//import { AccountContinuityReportTriggerComponent } from '../../user-surface/shared/account-continuity-report-trigger/account-continuity-report-trigger.component'


@Component({
  selector: 'control-panel',
  templateUrl: './control-panel.component.html',
  styleUrls: ['./control-panel.component.css']
})
export class ControlPanelComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  constructor( private commonService: CommonService) {
    this.commonService.setDisableTree(true);
   }

  ngOnInit() {
    
  }

  ngOnDestroy(){
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }


  
  getContentWidth() : number {
    return this.commonService.getContentWidth();
  }
  
  getContenteHeight(): number {
    return this.commonService.getTableHeight();
    //return this.commonService.getTableHeight() +36;
  }

}
