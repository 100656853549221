import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MainComponent } from './main-component/main.component';
import { CalendarModule } from 'primeng/calendar';
import { ProgressBarModule } from 'primeng/progressbar';
//import { TreeModule } from 'primeng/tree';

import { BreadcrumbModule } from 'primeng/breadcrumb';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
//import { TableModule } from '../../olcTable/table';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { PaginatorModule } from 'primeng/paginator';
import { SliderModule } from 'primeng/slider';
import { MultiSelectModule } from 'primeng/multiselect';
import { TabViewModule } from 'primeng/tabview';
import { CommonService } from '../common/common.service';
import { InvoiceTableComponent } from './invoice-table-component/invoice-table.component';
import { InvoiceProcessingComponent } from './invoice-processing/invoice-processing.component';
import { NavbarComponent } from '../border/navbar/navbar.component';
import { FooterComponent } from '../border/footer/footer.component';
import { DocumentTableComponent } from './document-table/document-table.component';
import { ContractTableComponent } from './contract-table/contract-table.component';

import { ToasterComponent } from './shared/toaster/toaster.component';

import {
  InvoiceControllerService,
  DocumentControllerService,
  DispatcherControllerService,
  ContractControllerService,
  CustomerTreeControllerService,
} from 'src/app/model';
import { InvoiceManagerComponent } from './invoice-manager/invoice-manager.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ContractManagerComponent } from './contract-manager/contract-manager.component';
import { AngularDualListBoxModule } from 'angular-dual-listbox';
import { ContextMenuModule } from 'primeng/contextmenu';
import { CommentControllerService } from '../../model/api/commentController.service';
import { EnumControllerService } from '../../model/api/enumController.service';
import { ProviderIntegrationControllerService } from '../../model/api/providerIntegrationController.service';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PodControllerService } from '../../model/api/podController.service';
import { ConfigControllerService } from '../../model/api/configController.service';

import { BusinessLogComponent } from './business-log-table/business-log.component';
import { BusinessLogControllerService } from '../../model/api/businessLogController.service';
import { MonthlyReportComponent } from './monthly-report-component/monthly-report.component';
import { MonthlyReportControllerService } from '../../model/api/monthlyReportController.service';

import { MeterControllerService } from '../../model/api/meterController.service';

import { NotificationControllerService } from '../../model/api/notificationController.service';

import { UserTableComponent } from './user-table-component/user-table.component';
import { UserControllerService } from '../../model/api/userController.service';
import { UserEditorComponent } from './user-editor/user-editor.component';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { CheckboxModule } from 'primeng/checkbox';
import { MenubarModule } from 'primeng/menubar';
import { RangeCalendar } from './shared/range-calendar/range-calendar.component';
import { TreeChooserComponent } from './shared/tree-chooser/tree-chooser.component';
import { DictionaryControllerService } from '../../model/api/dictionaryController.service';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { NoCacheHeadersInterceptor } from './interceptors/noCacheHeaders.interceptor';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastModule } from 'primeng/toast';
import { SplitButtonModule } from 'primeng/splitbutton';
import { InputTextModule } from 'primeng/inputtext';
import { InputMaskModule } from 'primeng/inputmask';


import { registerLocaleData } from '@angular/common';
import localeHuHU from '@angular/common/locales/hu';
import { SpinnerModule } from 'primeng/spinner';
import { NotificationsTableComponent } from './notifications-table/notifications-table.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import {EditorModule} from 'primeng/editor';
import { MenuModule } from 'primeng/menu';
import { InvoiceProcessingDetailComponent } from './invoice-processing-detail/invoice-processing-detail.component';
import { ChartModule } from 'primeng/chart';
import { ChipsModule } from 'primeng/chips';

import { PodTypeComponent } from './shared/pod-type/pod-type.component';
import { ProviderIntegrationMessagesComponent } from './shared/provider-integration-message/provider-integration-message.component';
import { ProviderIntegrationRobotStatusComponent } from './shared/provider-integration-robot-status/provider-integration-robot-status.component';



import { PeriodInputComponent } from './shared/period-input/period-input.component';

import { PodListComponent } from './pod-list/pod-list.component';
import { MeterPositionComponent } from './meter-position/meter-position.component';

//import { ResizableDirective } from '../../resizable.directive'
import { ResizableModule } from 'angular-resizable-element';
import { ConfigTableComponent } from './config-table/config-table.component';
import { MeterListComponent } from './meter-list/meter-list.component';
import { ProviderIntegrationListComponent } from './provider-integration-list/provider-integration-list.component';
import { ProviderIntegrationManagerComponent } from './provider-integration-manager/provider-integration-manager.component';
import { ControlPanelComponent } from './control-panel/control-panel.component';
import { TreeComponent } from './shared/tree-component/tree.component';
import { TreeModule } from 'src/app/olcTree/tree';
import { AccountContinuityReportTriggerComponent } from './shared/account-continuity-report-trigger/account-continuity-report-trigger.component';
import { CurrentMonthlyReportTriggerComponent } from './shared/current-monthly-report-trigger/current-monthly-report-trigger.component';


import { EmailEditorComponent, RecipientComponent } from './shared/email-editor/email-editor.component';
import { WorkflowLogComponent } from './invoice-manager/workflow-log/workflow-log.component';
import { WorkflowControllerService } from '../../model/api/workflowController.service';
import { WorkflowEditorComponent } from './shared/workflow-editor/workflow-editor.component';
import { InvoiceshareEditorComponent } from './shared/invoiceshare-editor/invoiceshare-editor.component';
import { InvoiceShareControllerService } from '../../model/api/invoiceShareController.service';
import { InvoiceShareComponent } from './invoice-manager/invoice-share/invoice-share.component';



registerLocaleData(localeHuHU);
@NgModule({
  declarations: [
    MainComponent,
    TreeComponent,
    InvoiceTableComponent,
    NavbarComponent,
    FooterComponent,
    DocumentTableComponent,
    ContractTableComponent,
    ToasterComponent,
    InvoiceManagerComponent,
    InvoiceProcessingComponent,
    UserEditorComponent,
    ContractManagerComponent,
    BusinessLogComponent,
    UserTableComponent,
    MonthlyReportComponent,
    TreeChooserComponent,
    RangeCalendar,
    NotificationsTableComponent,
    InvoiceProcessingDetailComponent,
    PodTypeComponent,
    EmailEditorComponent,RecipientComponent,
    ProviderIntegrationMessagesComponent,
    ProviderIntegrationRobotStatusComponent,
    PeriodInputComponent,
    AccountContinuityReportTriggerComponent,
    CurrentMonthlyReportTriggerComponent,
    PodListComponent,
    MeterPositionComponent,
    ConfigTableComponent,
    MeterListComponent,
    ProviderIntegrationListComponent,
    ProviderIntegrationManagerComponent,
    ControlPanelComponent,
    WorkflowLogComponent,
    InvoiceShareComponent,
    WorkflowEditorComponent,
    InvoiceshareEditorComponent,
  ],
  imports: [
    ContextMenuModule,
    PdfViewerModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TreeModule,
    PaginatorModule,
    TableModule,
    SliderModule,
    MultiSelectModule,
    CalendarModule,
    ProgressBarModule,
    TabViewModule,
    DialogModule,
    BreadcrumbModule,
    AngularDualListBoxModule,
    ConfirmDialogModule,
    MessageModule,
    MessagesModule,
    CheckboxModule,
    MenubarModule,
    ToastModule,
    ProgressSpinnerModule,
    SpinnerModule,
    SplitButtonModule,
    InputMaskModule,
    InputTextModule,
    PanelModule,
    OverlayPanelModule,
    ChartModule,
    ChipsModule,
    MenuModule,
    EditorModule,
    ResizableModule,
  ],
  providers: [
    InvoiceControllerService,
    DocumentControllerService,
    PodControllerService,
    ConfigControllerService,
    DispatcherControllerService,
    CommonService,
    BusinessLogControllerService,
    CustomerTreeControllerService,
    ContractControllerService,
    EnumControllerService,
    WorkflowControllerService,
    InvoiceShareControllerService,
    ProviderIntegrationControllerService,
    CommentControllerService,
    MonthlyReportControllerService,
    MeterControllerService,
    UserControllerService,
    ConfirmationService,
    DictionaryControllerService,
    NotificationControllerService,
    MeterListComponent,
    ProviderIntegrationListComponent,
    ProviderIntegrationManagerComponent,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoCacheHeadersInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'hu-HU' },
  ],
})
export class UserSurfaceModule {}
