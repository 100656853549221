/**
 * OLC System
 * Ügyfél és számlakezelő rendszer
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { GetInvoiceDetailsResponse } from '../model/getInvoiceDetailsResponse';
import { InvoiceProcessDto } from '../model/invoiceProcessDto';
import { ListInvoiceFieldDefinitionRequest } from '../model/listInvoiceFieldDefinitionRequest';
import { ListInvoiceFieldDefinitionResponse } from '../model/listInvoiceFieldDefinitionResponse';
import { ListInvoiceRequest } from '../model/listInvoiceRequest';
import { ListInvoiceResponse } from '../model/listInvoiceResponse';

import { UpdateInvoiceRequest } from '../model/updateInvoiceRequest';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { environment } from '../../../environments/environment';
import { CreateInvoiceRequest } from '../model/models';


@Injectable()
export class InvoiceControllerService {

    protected basePath = environment.apiBaseUrl + '/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * checked
     * 
     * @param invoiceId invoiceId
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkedUsingGET(invoiceId: number, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public checkedUsingGET(invoiceId: number, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public checkedUsingGET(invoiceId: number, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public checkedUsingGET(invoiceId: number, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invoiceId === null || invoiceId === undefined) {
            throw new Error('Required parameter invoiceId was null or undefined when calling checkedUsingGET.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<any>(`${this.basePath}/invoice/checked/${encodeURIComponent(String(invoiceId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createInvoice
     * 
     * @param body request
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createInvoiceUsingPOST(body: CreateInvoiceRequest, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public createInvoiceUsingPOST(body: CreateInvoiceRequest, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public createInvoiceUsingPOST(body: CreateInvoiceRequest, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public createInvoiceUsingPOST(body: CreateInvoiceRequest, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createInvoiceUsingPOST.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/invoice/create`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * error
     * 
     * @param invoiceId invoiceId
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public errorUsingGET1(invoiceId: number, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public errorUsingGET1(invoiceId: number, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public errorUsingGET1(invoiceId: number, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public errorUsingGET1(invoiceId: number, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invoiceId === null || invoiceId === undefined) {
            throw new Error('Required parameter invoiceId was null or undefined when calling errorUsingGET1.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<any>(`${this.basePath}/invoice/error/${encodeURIComponent(String(invoiceId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * exportInvoiceDetailsXls
     * 
     * @param invoiceId invoiceId
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportInvoiceDetailsXlsUsingGET(invoiceId: number, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public exportInvoiceDetailsXlsUsingGET(invoiceId: number, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public exportInvoiceDetailsXlsUsingGET(invoiceId: number, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public exportInvoiceDetailsXlsUsingGET(invoiceId: number, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invoiceId === null || invoiceId === undefined) {
            throw new Error('Required parameter invoiceId was null or undefined when calling exportInvoiceDetailsXlsUsingGET.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<string>(`${this.basePath}/invoice/exportInvoiceDetails/${encodeURIComponent(String(invoiceId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * exportInvoiceDocuments
     * 
     * @param body request
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportInvoiceDocumentsUsingPOST(body: ListInvoiceRequest, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public exportInvoiceDocumentsUsingPOST(body: ListInvoiceRequest, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public exportInvoiceDocumentsUsingPOST(body: ListInvoiceRequest, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public exportInvoiceDocumentsUsingPOST(body: ListInvoiceRequest, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling exportInvoiceDocumentsUsingPOST.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/zip'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/invoice/exportDocuments`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * exportInvoiceXlsAllFields
     * 
     * @param body request
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportInvoiceXlsAllFieldsUsingPOST(body: ListInvoiceRequest, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public exportInvoiceXlsAllFieldsUsingPOST(body: ListInvoiceRequest, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public exportInvoiceXlsAllFieldsUsingPOST(body: ListInvoiceRequest, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public exportInvoiceXlsAllFieldsUsingPOST(body: ListInvoiceRequest, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling exportInvoiceXlsAllFieldsUsingPOST.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<string>(`${this.basePath}/invoice/exportXlsAllFields`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * exportInvoiceXls
     * 
     * @param body request
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportInvoiceXlsUsingPOST(body: ListInvoiceRequest, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public exportInvoiceXlsUsingPOST(body: ListInvoiceRequest, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public exportInvoiceXlsUsingPOST(body: ListInvoiceRequest, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public exportInvoiceXlsUsingPOST(body: ListInvoiceRequest, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling exportInvoiceXlsUsingPOST.');

        }

        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

         return this.httpClient.post<string>(`${this.basePath}/invoice/exportXls`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * exportSelectedInvoiceXlsAllFields
     * 
     * @param body invoiceIds
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportSelectedInvoiceXlsAllFieldsUsingPOST(body: Array<number>, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public exportSelectedInvoiceXlsAllFieldsUsingPOST(body: Array<number>, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public exportSelectedInvoiceXlsAllFieldsUsingPOST(body: Array<number>, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public exportSelectedInvoiceXlsAllFieldsUsingPOST(body: Array<number>, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling exportSelectedInvoiceXlsAllFieldsUsingPOST.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<string>(`${this.basePath}/invoice/exportSelectedXlsAllFields`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


    /**
     * exportSelectedInvoiceXls
     * 
     * @param body invoiceIds
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportSelectedInvoiceXlsUsingPOST(body: Array<number>, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public exportSelectedInvoiceXlsUsingPOST(body: Array<number>, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public exportSelectedInvoiceXlsUsingPOST(body: Array<number>, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public exportSelectedInvoiceXlsUsingPOST(body: Array<number>, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling exportSelectedInvoiceXlsUsingPOST.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<string>(`${this.basePath}/invoice/exportSelectedXls`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getContinuousInvoiceReport
     * 
     * @param fromDate fromDate
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getContinuousInvoiceReportUsingGET(fromDate: string, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getContinuousInvoiceReportUsingGET(fromDate: string, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getContinuousInvoiceReportUsingGET(fromDate: string, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getContinuousInvoiceReportUsingGET(fromDate: string, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (fromDate === null || fromDate === undefined) {
            throw new Error('Required parameter fromDate was null or undefined when calling getContinuousInvoiceReportUsingGET.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<any>(`${this.basePath}/invoice/continuousInvoice/${encodeURIComponent(String(fromDate))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getInvoiceDetails
     * 
     * @param invoiceId invoiceId
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInvoiceDetailsUsingGET(invoiceId: number, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<GetInvoiceDetailsResponse>;
    public getInvoiceDetailsUsingGET(invoiceId: number, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetInvoiceDetailsResponse>>;
    public getInvoiceDetailsUsingGET(invoiceId: number, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetInvoiceDetailsResponse>>;
    public getInvoiceDetailsUsingGET(invoiceId: number, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invoiceId === null || invoiceId === undefined) {
            throw new Error('Required parameter invoiceId was null or undefined when calling getInvoiceDetailsUsingGET.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<GetInvoiceDetailsResponse>(`${this.basePath}/invoice/${encodeURIComponent(String(invoiceId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * listColumnFields
     * 
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listColumnFieldsUsingGET(authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<ListInvoiceFieldDefinitionResponse>;
    public listColumnFieldsUsingGET(authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListInvoiceFieldDefinitionResponse>>;
    public listColumnFieldsUsingGET(authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListInvoiceFieldDefinitionResponse>>;
    public listColumnFieldsUsingGET(authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<ListInvoiceFieldDefinitionResponse>(`${this.basePath}/invoice/columnFieldList`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * listFields
     * 
     * @param body request
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listFieldsUsingPOST(body: ListInvoiceFieldDefinitionRequest, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<ListInvoiceFieldDefinitionResponse>;
    public listFieldsUsingPOST(body: ListInvoiceFieldDefinitionRequest, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListInvoiceFieldDefinitionResponse>>;
    public listFieldsUsingPOST(body: ListInvoiceFieldDefinitionRequest, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListInvoiceFieldDefinitionResponse>>;
    public listFieldsUsingPOST(body: ListInvoiceFieldDefinitionRequest, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling listFieldsUsingPOST.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ListInvoiceFieldDefinitionResponse>(`${this.basePath}/invoice/fieldList`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * listInvoiceProcesses
     * 
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listInvoiceProcessesUsingGET(authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<InvoiceProcessDto>>;
    public listInvoiceProcessesUsingGET(authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<InvoiceProcessDto>>>;
    public listInvoiceProcessesUsingGET(authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<InvoiceProcessDto>>>;
    public listInvoiceProcessesUsingGET(authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<Array<InvoiceProcessDto>>(`${this.basePath}/invoice/processes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * listInvoices
     * 
     * @param body request
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listInvoicesUsingPOST(body: ListInvoiceRequest, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<ListInvoiceResponse>;
    public listInvoicesUsingPOST(body: ListInvoiceRequest, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListInvoiceResponse>>;
    public listInvoicesUsingPOST(body: ListInvoiceRequest, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListInvoiceResponse>>;
    public listInvoicesUsingPOST(body: ListInvoiceRequest, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling listInvoicesUsingPOST.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ListInvoiceResponse>(`${this.basePath}/invoice/list`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * mergeInvoiceDocuments
     * 
     * @param body request
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mergeInvoiceDocumentsUsingPOST(body: ListInvoiceRequest, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public mergeInvoiceDocumentsUsingPOST(body: ListInvoiceRequest, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public mergeInvoiceDocumentsUsingPOST(body: ListInvoiceRequest, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public mergeInvoiceDocumentsUsingPOST(body: ListInvoiceRequest, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling mergeInvoiceDocumentsUsingPOST.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/pdf'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/invoice/mergeDocuments`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * mergeSelectedInvoiceDocuments
     * 
     * @param body invoiceIds
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mergeSelectedInvoiceDocumentsUsingPOST(body: Array<number>, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public mergeSelectedInvoiceDocumentsUsingPOST(body: Array<number>, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public mergeSelectedInvoiceDocumentsUsingPOST(body: Array<number>, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public mergeSelectedInvoiceDocumentsUsingPOST(body: Array<number>, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling mergeSelectedInvoiceDocumentsUsingPOST.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/pdf'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<string>(`${this.basePath}/invoice/mergeSelectedDocuments`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**

     * modifyInvoice
     * 
     * @param body request
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public modifyInvoiceUsingPOST(body: UpdateInvoiceRequest, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public modifyInvoiceUsingPOST(body: UpdateInvoiceRequest, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public modifyInvoiceUsingPOST(body: UpdateInvoiceRequest, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public modifyInvoiceUsingPOST(body: UpdateInvoiceRequest, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling modifyInvoiceUsingPOST.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/invoice/update`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * reloadFilteredInvoices
     * 
     * @param body request
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reloadFilteredInvoicesUsingPOST(body: ListInvoiceRequest, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public reloadFilteredInvoicesUsingPOST(body: ListInvoiceRequest, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public reloadFilteredInvoicesUsingPOST(body: ListInvoiceRequest, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public reloadFilteredInvoicesUsingPOST(body: ListInvoiceRequest, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling reloadFilteredInvoicesUsingPOST.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<string>(`${this.basePath}/invoice/reloadFiltered`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * reloadInvoices
     * 
     * @param body invoiceIds
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reloadInvoicesUsingPOST(body: Array<number>, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public reloadInvoicesUsingPOST(body: Array<number>, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public reloadInvoicesUsingPOST(body: Array<number>, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public reloadInvoicesUsingPOST(body: Array<number>, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling reloadInvoicesUsingPOST.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/invoice/reload`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * uploadInvoiceDocument
     * 
     * @param file 
     * @param invoiceId invoiceId
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadInvoiceDocumentUsingPOST(file: Blob, invoiceId: number, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public uploadInvoiceDocumentUsingPOST(file: Blob, invoiceId: number, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public uploadInvoiceDocumentUsingPOST(file: Blob, invoiceId: number, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public uploadInvoiceDocumentUsingPOST(file: Blob, invoiceId: number, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling uploadInvoiceDocumentUsingPOST.');
        }

        if (invoiceId === null || invoiceId === undefined) {
            throw new Error('Required parameter invoiceId was null or undefined when calling uploadInvoiceDocumentUsingPOST.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
			formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.post<any>(`${this.basePath}/invoice/upload/${encodeURIComponent(String(invoiceId))}`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}