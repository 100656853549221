import { InvoiceTypeEnum } from "../../common/enums/invoiceType";
import { ProviderEnum } from "../../common/enums/provider";
import { RhdTariffEnum } from "../../common/enums/rhdTariff";
import { PodControlEnum } from "../../common/enums/podControl";

export class InvoiceFilter {
    podName: string;
    podType: string;
    provider: ProviderEnum[] = [];
    type: InvoiceTypeEnum[] = [];
    invoiceNumber: string;
    issueDate: string[] = [];
    settlementPeriod: string[] = [];
    paying: string;
    consuption: string;
    payingDeadline: string[] = [];
    orphanInvoice:boolean = false;
    rhdTariff:RhdTariffEnum;
    podControl: PodControlEnum;
    invoiceProcessId: number;
}