/**
 * OLC System
 * Ügyfél és számlakezelő rendszer
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateCustomerChildRequest } from '../model/createCustomerChildRequest';
import { CreateCustomerRequest } from '../model/createCustomerRequest';
import { CreateCustomerTreeResponse } from '../model/createCustomerTreeResponse';
import { GetTreeResponse } from '../model/getTreeResponse';
import { UpdateCustomerTreeRequest } from '../model/updateCustomerTreeRequest';
import { UpdateCustomerTreeResponse } from '../model/updateCustomerTreeResponse';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { environment } from '../../../environments/environment';
import { AddressResponse } from '../model/models';


@Injectable()
export class CustomerTreeControllerService {

    protected basePath = environment.apiBaseUrl + '/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * activatePod
     * 
     * @param treeId treeId
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public activatePodUsingGET(treeId: number, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public activatePodUsingGET(treeId: number, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public activatePodUsingGET(treeId: number, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public activatePodUsingGET(treeId: number, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (treeId === null || treeId === undefined) {
            throw new Error('Required parameter treeId was null or undefined when calling activatePodUsingGET.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<any>(`${this.basePath}/tree/activatePod/${encodeURIComponent(String(treeId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * copy
     * 
     * @param authorization JWT Authorization token
     * @param destinationId 
     * @param sourceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public copyUsingPOST(authorization?: string, destinationId?: number, sourceId?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public copyUsingPOST(authorization?: string, destinationId?: number, sourceId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public copyUsingPOST(authorization?: string, destinationId?: number, sourceId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public copyUsingPOST(authorization?: string, destinationId?: number, sourceId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (destinationId !== undefined && destinationId !== null) {
            queryParameters = queryParameters.set('destinationId', <any>destinationId);
        }
        if (sourceId !== undefined && sourceId !== null) {
            queryParameters = queryParameters.set('sourceId', <any>sourceId);
        }

        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/tree/copy`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createCustomerChild
     * 
     * @param body request
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCustomerChildUsingPOST(body: CreateCustomerChildRequest, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<CreateCustomerTreeResponse>;
    public createCustomerChildUsingPOST(body: CreateCustomerChildRequest, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreateCustomerTreeResponse>>;
    public createCustomerChildUsingPOST(body: CreateCustomerChildRequest, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreateCustomerTreeResponse>>;
    public createCustomerChildUsingPOST(body: CreateCustomerChildRequest, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createCustomerChildUsingPOST.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CreateCustomerTreeResponse>(`${this.basePath}/tree/createCustomerChild`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createCustomer
     * 
     * @param body request
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCustomerUsingPOST(body: CreateCustomerRequest, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<CreateCustomerTreeResponse>;
    public createCustomerUsingPOST(body: CreateCustomerRequest, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreateCustomerTreeResponse>>;
    public createCustomerUsingPOST(body: CreateCustomerRequest, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreateCustomerTreeResponse>>;
    public createCustomerUsingPOST(body: CreateCustomerRequest, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createCustomerUsingPOST.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CreateCustomerTreeResponse>(`${this.basePath}/tree/createCustomer`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createDocument
     * 
     * @param file 
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importExcelUsingPOST(file: Blob, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public importExcelUsingPOST(file: Blob, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public importExcelUsingPOST(file: Blob, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public importExcelUsingPOST(file: Blob, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling createDocumentUsingPOST.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.post<any>(`${this.basePath}/tree/import`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * delete
     * 
     * @param treeId treeId
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUsingGET(treeId: number, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteUsingGET(treeId: number, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteUsingGET(treeId: number, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteUsingGET(treeId: number, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (treeId === null || treeId === undefined) {
            throw new Error('Required parameter treeId was null or undefined when calling deleteUsingGET.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<any>(`${this.basePath}/tree/delete/${encodeURIComponent(String(treeId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getPODAddress
     * 
     * @param customerId customerId
     * @param podId podId
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPODAddressUsingGET(customerId: number, podId: number, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<AddressResponse>;
    public getPODAddressUsingGET(customerId: number, podId: number, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AddressResponse>>;
    public getPODAddressUsingGET(customerId: number, podId: number, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AddressResponse>>;
    public getPODAddressUsingGET(customerId: number, podId: number, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling getPODAddressUsingGET.');
        }

        if (podId === null || podId === undefined) {
            throw new Error('Required parameter podId was null or undefined when calling getPODAddressUsingGET.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<AddressResponse>(`${this.basePath}/tree/address/${encodeURIComponent(String(customerId))}/${encodeURIComponent(String(podId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getTreeOnlyCustomers
     * 
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTreeOnlyCustomersUsingGET(authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<GetTreeResponse>;
    public getTreeOnlyCustomersUsingGET(authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetTreeResponse>>;
    public getTreeOnlyCustomersUsingGET(authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetTreeResponse>>;
    public getTreeOnlyCustomersUsingGET(authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<GetTreeResponse>(`${this.basePath}/tree/onlyCustomer`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getTree
     * 
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTreeUsingGET(authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<GetTreeResponse>;
    public getTreeUsingGET(authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetTreeResponse>>;
    public getTreeUsingGET(authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetTreeResponse>>;
    public getTreeUsingGET(authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        return this.httpClient.get<GetTreeResponse>(`${this.basePath}/tree`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getTreeWithPods
     * 
     * @param podType podType
     * @param treeId treeId
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTreeWithPodsUsingGET(podType: string, treeId: number, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<GetTreeResponse>;
    public getTreeWithPodsUsingGET(podType: string, treeId: number, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetTreeResponse>>;
    public getTreeWithPodsUsingGET(podType: string, treeId: number, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetTreeResponse>>;
    public getTreeWithPodsUsingGET(podType: string, treeId: number, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (podType === null || podType === undefined) {
            throw new Error('Required parameter podType was null or undefined when calling getTreeWithPodsUsingGET.');
        }

        if (treeId === null || treeId === undefined) {
            throw new Error('Required parameter treeId was null or undefined when calling getTreeWithPodsUsingGET.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<GetTreeResponse>(`${this.basePath}/tree/withPods/${encodeURIComponent(String(treeId))}/${encodeURIComponent(String(podType))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    /**

     * getTreeWithoutPods
     * 
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTreeWithoutPodsUsingGET(authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<GetTreeResponse>;
    public getTreeWithoutPodsUsingGET(authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetTreeResponse>>;
    public getTreeWithoutPodsUsingGET(authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetTreeResponse>>;
    public getTreeWithoutPodsUsingGET(authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        return this.httpClient.get<GetTreeResponse>(`${this.basePath}/tree/withoutPods`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * inactivatePod
     * 
     * @param treeId treeId
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inactivatePodUsingGET(treeId: number, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public inactivatePodUsingGET(treeId: number, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public inactivatePodUsingGET(treeId: number, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public inactivatePodUsingGET(treeId: number, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (treeId === null || treeId === undefined) {
            throw new Error('Required parameter treeId was null or undefined when calling inactivatePodUsingGET.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<any>(`${this.basePath}/tree/inactivatePod/${encodeURIComponent(String(treeId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * move
     * 
     * @param authorization JWT Authorization token
     * @param destinationId 
     * @param sourceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public moveUsingPOST(authorization?: string, destinationId?: number, sourceId?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public moveUsingPOST(authorization?: string, destinationId?: number, sourceId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public moveUsingPOST(authorization?: string, destinationId?: number, sourceId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public moveUsingPOST(authorization?: string, destinationId?: number, sourceId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (destinationId !== undefined && destinationId !== null) {
            queryParameters = queryParameters.set('destinationId', <any>destinationId);
        }
        if (sourceId !== undefined && sourceId !== null) {
            queryParameters = queryParameters.set('sourceId', <any>sourceId);
        }

        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/tree/move`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**

     * updateCustomerTree
     * 
     * @param body request
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCustomerTreeUsingPOST(body: UpdateCustomerTreeRequest, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<UpdateCustomerTreeResponse>;
    public updateCustomerTreeUsingPOST(body: UpdateCustomerTreeRequest, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UpdateCustomerTreeResponse>>;
    public updateCustomerTreeUsingPOST(body: UpdateCustomerTreeRequest, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UpdateCustomerTreeResponse>>;
    public updateCustomerTreeUsingPOST(body: UpdateCustomerTreeRequest, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateCustomerTreeUsingPOST.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UpdateCustomerTreeResponse>(`${this.basePath}/tree/updateCustomerTree`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
