import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../../common/common.service';
import { Subscription } from 'rxjs';
import { TABLE_SELECTOR } from '../../common/enums/table-selector';
import { Table } from 'primeng/table';
import { EnumControllerService, StartInvoiceProcessRequest } from 'src/app/model';
import { DispatcherControllerService } from '../../../model/api/dispatcherController.service';
import { InvoiceProcessingProviderData, InvoiceProcessingDetailData } from './invoice-processing-provicerData';
import { ProviderEnum } from '../../common/enums/provider';

@Component({
  selector: 'invoice-processing',
  templateUrl: './invoice-processing.component.html',
  styleUrls: ['./invoice-processing.component.css']
})
export class InvoiceProcessingComponent implements OnInit {
  @ViewChild('dt', { static: false }) table: Table;

  constructor(private commonService: CommonService,
    private dispatcherService: DispatcherControllerService,
    private enumService: EnumControllerService,) { }

  public cols: any;
  private subscriptions: Subscription[] = [];
  public providers: InvoiceProcessingProviderData[] = [];
  public selectedProviders: InvoiceProcessingProviderData[] = [];
  public currentInc: number = 0;

  private providerOptions: any[] = [];
  //private processStatusOptions: any[] = [];
  public showInvoiceProcessDetail: boolean = false;
  public actProcessID: number;
  public statusAndDetail: InvoiceProcessingDetailData = <InvoiceProcessingDetailData>{};
  ngOnInit() {
    this.cols = [
      { field: 'provider', header: 'Szolgáltató', size: "200px", align: "left" },
      { field: 'cnt', header: 'Darab', size: "50px", align: "right" }
    ]

    this.enumService.listProvidersUsingGET(this.commonService.getCurrentUser().token).subscribe(resp => {
      this.providerOptions = resp.labelValues;
    });
    //this.enumService.listProcessStatusUsingGET(this.commonService.getCurrentUser().token).subscribe(resp => {
    //  this.processStatusOptions = resp.labelValues;
    //})


    this.subscriptions.push(
      this.commonService.connector.subscribe(() => {
        this.listInvoiceProviders();
      })
    );
  }

  listInvoiceProviders() {
    if (TABLE_SELECTOR.INVOICE_PROCESSING != this.commonService.getselectTable()) {
      return;
    }
    
    this.providers.length = 0;
    this.providers = [];
    this.subscriptions.push(
      this.dispatcherService.scanInvoiceFoldersUsingGET(this.commonService.getCurrentUser().token).subscribe(
        data => {
          let xScanResult = data.scanResult;
          for (let p of Object.keys(xScanResult)) {
            let cnt = xScanResult[p];
            let xData = { 'provider': p, 'cnt': cnt } as InvoiceProcessingProviderData;
            xData.provider = p as ProviderEnum;
            this.providers.push(xData);
          }
        },
        //@ts-ignore
        error => { },
        () => { this.commonService.setDisableTree(true); }
      ),
    );
  }

  getTableHeightInpx(): string {
    return this.commonService.getTableHeightInpx()
  }

  getTableWidthInpx(): string {
    return this.commonService.getTableWidthInpx();
  }

  public findLabel(value: string): string {
    return this.commonService.findLabel(this.providerOptions, value);
  }


  serachInvoice() {
    this.listInvoiceProviders();
  }


  invoiceProcessing() {
    let request: StartInvoiceProcessRequest = <StartInvoiceProcessRequest>{};
    let processID: number;
    request.providers = this.selectedProviders.map(item => {
      return item.provider
    })
    this.dispatcherService.startInvoiceProcessingUsingPOST(request, this.commonService.getCurrentUser().token).subscribe(
      data => {
        processID = data
      },
      //@ts-ignore
      error => { },
      () => {
        this.actProcessID = processID;
        this.getInvoiceProcessingDetailData(processID)
      }
    )
  }

  getInvoiceProcessingDetailData(processID: number){    
    this.dispatcherService.getStatusUsingGET(processID, this.commonService.getCurrentUser().token).subscribe(
      status => {
        this.statusAndDetail.status = status;
      }, 
      //@ts-ignore
      error => { },
      () => {
        this.dispatcherService.getDetailsUsingGET(processID, this.commonService.getCurrentUser().token).subscribe(
          detail => {
            this.statusAndDetail.detail = detail;            
          }, 
          //@ts-ignore
          error =>  {},
          ()=>{
            this.showInvoiceProcessDetail = true;      
          }
        )
      }
    )
  
  }


}
