import { Component, OnInit } from '@angular/core';
import { CommonService } from './modules/common/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'Online OLC System';
  public componentHeight: number;

  constructor(private commonService: CommonService) {
  }

  ngOnInit() {
    this.componentHeight = window.document.documentElement.clientHeight - this.commonService.componentHeightSubValue;
  }

}
