import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from '../../common/common.service';
import { TABLE_SELECTOR } from '../../common/enums/table-selector';
//import { ConfirmationService } from 'primeng/api';
import { MonthlyReportControllerService } from '../../../model/api/monthlyReportController.service';
import { MonthlyReportDto } from '../../../model/model/monthlyReportDto';
import { LabelValue } from '../../../model/model/labelValue';
import { EnumControllerService } from '../../../model/api/enumController.service';
import { MonthlyReportGroupDto, AddMontlyReportCommentRequest } from '../../../model/model/models';
import { isUndefined } from 'util';
import { SendReportRequest } from '../../../model/model/sendReportRequest';
import { Subscription } from 'rxjs';

@Component({
  selector: 'monthly-report-table',
  templateUrl: './monthly-report.component.html',
  styleUrls: ['./monthly-report.component.css']
})
export class MonthlyReportComponent implements OnInit, OnDestroy {

  public issueDatesOptions: LabelValue[] = [];

  public selectedPeriod: string;

  public reports: MonthlyReportDto[];

  public reportGroups: MonthlyReportGroupDto[];
  public selectedReportGroup : MonthlyReportGroupDto;

  public monthlyReportStatusOptions: any[];

  public displayCustomerComment = false;
  public commentText: string = '';
  public commentCustomerId: number;
  public displayInvoiceComment: boolean = false;
  public displaySendMail: boolean =  false;
  public sendMailMessage: string = '';
  public otherEmailAddress: string = '';
  public commentReport: MonthlyReportDto;

  public customerCommentMap?: { [key: string]: string; };
  
  private subscriptions: Subscription[] = [];

  constructor(private monthlyReportService: MonthlyReportControllerService,
    private commonService: CommonService, private enumService: EnumControllerService) { }
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  ngOnInit() {
    this.subscriptions.push(
    this.commonService.connector.subscribe(() => {
      this.listIssueDates();
    }));
    this.initDropdowns();
  }

  initDropdowns() {
    this.subscriptions.push(
    this.enumService.listMonthlyReportStatusUsingGET(this.commonService.getCurrentUser().token).subscribe(data => {
      this.monthlyReportStatusOptions = data.labelValues;
    }));
  }

  listReportGroups() {
    if (this.selectedPeriod == null) {
      this.reportGroups = [];
      this.reports = [];
      return;
    }
    this.subscriptions.push(
    this.monthlyReportService.getReportGroupsUsingGET(this.selectedPeriod, this.commonService.getCurrentUser().token).subscribe(data => {
      this.reportGroups = data;
      if (this.selectedReportGroup==null) {
        this.listReports(null);
      } else {
        this.listReports(this.selectedReportGroup.customerId);
      }
    }));
  }
  
  listReports(customerId : number) {
    if (this.selectedPeriod == null) {
      this.reports = [];
      return;
    }
    if (customerId == null || isUndefined(customerId)) {
      this.reports = [];
      return;
    }
    this.subscriptions.push(
    this.monthlyReportService.getReportsUsingGET(customerId, this.selectedPeriod, this.commonService.getCurrentUser().token).subscribe(data => {
      this.reports = data;
    }));
  }


  listIssueDates() {
    if (TABLE_SELECTOR.MONTHLY_REPORT != this.commonService.getselectTable()) {
      return;
    }
    this.subscriptions.push(
    this.monthlyReportService.listIssueDatesUsingGET(this.commonService.getCurrentUser().token).subscribe(data => {
      this.issueDatesOptions = data.monthList;
    },
    //@ts-ignore
    error => { },
    () => { this.commonService.setDisableTree(true); }));
  }

  // ----------------------- Send email  -----------------------------------------

  sendReportConfirmation() {
    this.sendMailMessage = this.selectedReportGroup ? 'Szeretné a kijelölt havi reportot elküldeni?' : 'Szeretné az összes havi reportokat elküldeni?';
    
    this.displaySendMail = true;
    /*
    if (this.selectedReportGroup!=null) {
      this.confirmationService.confirm({ message: 'Szeretné a <b>kijelölt</b> havi reportot elküldeni?', accept: () => { this.sendSelectedReports(this.selectedReportGroup.customerId); } });
    }
    else {
      this.confirmationService.confirm({ message: 'Szeretné az <b>összes</b> havi reportokat elküldeni?', accept: () => { this.sendAllReports() } });
    }
    */
  }

  sendSelectedReports(id: number) {
    let request : SendReportRequest = {};
    request.reportIds = [id];
    request.customEmail = this.otherEmailAddress ? this.otherEmailAddress : null;
    this.subscriptions.push(
    this.monthlyReportService.sendReportsUsingPOST(request, this.selectedPeriod, this.commonService.getCurrentUser().token, "response").subscribe(() => {
      this.listReportGroups();
    }));
  }

  sendAllReports() {
    let request : SendReportRequest = {};
    request.customEmail = this.otherEmailAddress ? this.otherEmailAddress : null;
    this.subscriptions.push(
    this.monthlyReportService.sendReportsUsingPOST(request, this.selectedPeriod, this.commonService.getCurrentUser().token, "response").subscribe(() => {
      this.listReportGroups();
    }));
  }

  showCustomerComment(customerId: number, comment: string) {
    this.displayCustomerComment = true;
    this.commentText = comment;
    this.commentCustomerId = customerId;
  }

  saveCustomerComment() {
    let request: AddMontlyReportCommentRequest = {};
    request.comment = this.commentText;
    this.monthlyReportService.addCustomerCommentUsingPOST(request, this.commentCustomerId, this.selectedPeriod, this.commonService.getCurrentUser().token, "response").subscribe(() => {
      this.displayCustomerComment = false;
      this.listReportGroups();
    });
  }

  showInvoiceComment(report: MonthlyReportDto) {
    this.displayInvoiceComment = true;
    this.commentText = report.comment;
    this.commentReport = report;
  }

  saveInvoiceComment() {
    let request: AddMontlyReportCommentRequest = {};
    request.comment = this.commentText;
    this.subscriptions.push(
    this.monthlyReportService.addCommentUsingPOST(request, this.commentReport.id, this.commonService.getCurrentUser().token, "response").subscribe(() => {
      this.displayInvoiceComment = false;
      this.commentReport.comment = this.commentText;
    }));
  }

  findLabel(options: LabelValue[], value: string): string {
    return this.commonService.findLabel(options, value);
  }
  getTableWidthInpx(): string {
    //return '450px'
    //return this.commonService.getTableWidthInpx();
    return (this.commonService.getTableWidth()-450) + 'px'
  }

  getTableHeightInpx(): string {
    return this.commonService.getTableHeightInpx();
  }
  
  getTableHeightInpx2(): string {
    return (this.commonService.getTableHeight() + 49) + 'px';
  }

  getTableHeight(): number {
    return this.commonService.getTableHeight();
  }

  getContentWidth(): number {
    return this.commonService.getContentWidth();
  }

  onRowSelect(event) {
    this.listReports(event.data.customerId);
  }

  getSendReportButtonLabel(){
    return this.otherEmailAddress ? 'Megadott címre küldés' : 'Ügyfél címére küldés'
  }
  
  sendReport(){
    this.selectedReportGroup? this.sendSelectedReports(this.selectedReportGroup.customerId) : this.sendAllReports();
    this.displaySendMail = false;
  }
}