import { Injectable, OnDestroy } from '@angular/core';
import { TABLE_SELECTOR } from './enums/table-selector';
import { Http } from './classes/http';
import { HttpClient } from '@angular/common/http';
import { User } from 'src/app/model/model/client/user';
import { BehaviorSubject } from 'rxjs';
import { TreeItem } from '../../model/model/treeItem';
import { DocumentControllerService } from '../../model/api/documentController.service';

import { LabelValue } from '../../model/model/models';
import { NotificationDto } from '../../model/model/notificationDto';
import { PodTypeEnum } from './enums/podType';
import { TreeTypeEnum } from './enums/treeType';
import { UserIdleService } from 'angular-user-idle';
import { LoginControllerService } from '../user-surface/login/service/loginController.service';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { ComponentConnector } from '../user-surface/shared/component-connector/component-connector';
import { ConsuptionUnitEnum } from './enums/consuptionUnit';
import { InvoiceFilterOptions } from 'src/app/model/model/invoiceFilterOptions';

@Injectable({ providedIn: 'root' })
export class CommonService extends Http implements OnDestroy {
  public selectedItem: TreeItem;
  public tablePageSize = 100;
  public panelHide: boolean = false;
  public maxUploadFileSize: number = 10485760;

  public numberFormatRounding2Digits = '1.0-2';
  public numberFormatRounding4Digits = '1.0-4';
  public numberFormatRoundingInteger = '1.0-0';

  public spinner: string = 'none';
  public maxDownloadItem = 50;
  public componentHeight: number;
  public tableWith: number = 0;

  public displayPodEditor: boolean = false;

  public datePickerLanguage = {
    firstDayOfWeek: 0,
    dayNames: ['hétfő', 'kedd', 'szerda', 'csütrötök', 'péntek', 'szombat', 'vasárnap'],
    dayNamesShort: ['Hét', 'Ke', 'Sze', 'Csüt', 'Pén', 'Szo', 'Vas'],
    dayNamesMin: ['H', 'K', 'S', 'C', 'P', 'S', 'V'],
    monthNames: [
      'január',
      'február',
      'március',
      'április',
      'május',
      'június',
      'július',
      'augusztus',
      'szeptember',
      'október',
      'november',
      'december',
    ],
    monthNamesShort: ['jan', 'feb', 'már', 'apr', 'máj', 'jún', 'júl', 'aug', 'szep', 'okt', 'nov', 'dec'],
    today: 'Ma',
    clear: 'Törlés',
    weekHeader: 'Hét',
  };

  public loaderDelay: number = 50;
  public headerPanelHeight: number = 100;
  public paginatortHeight: number = 36;
  public footerHeight: number = 45;
  public componentHeightSubValue: number;
  public zoom_to: number = 1;
  public connector: BehaviorSubject<ComponentConnector> = new BehaviorSubject<ComponentConnector>(<ComponentConnector>{});

  public invoiceFilter: BehaviorSubject<InvoiceFilterOptions> = new BehaviorSubject<InvoiceFilterOptions>(<InvoiceFilterOptions>null);

  public TreePanelWidth: number = 400;

  public unreadNotifications: NotificationDto[];

  public unreadNOtificationsRefreshIntervall: number = 30000;
  public notificationTypeOptions: any[];

  public loginSubject: BehaviorSubject<User> = new BehaviorSubject<User>(<User>{});

  private selectedTreeItem: TreeItem;

  private selectedTable: TABLE_SELECTOR;

  constructor(
    //@ts-ignore
    private httpClient: HttpClient,
    private documentController: DocumentControllerService,
    private userIdle: UserIdleService,
    private loginService: LoginControllerService,
    private router: Router
  ) {
    super(httpClient);

    let x = this.getUserSetting();
    if (x != null) {
      this.TreePanelWidth = this.getUserUXSettingValue('TreePanel', 'width') ? this.getUserUXSettingValue('TreePanel', 'width') : 400;
    }
    this.componentHeightSubValue = this.headerPanelHeight + this.footerHeight;
  }

  ngOnDestroy() {}

  public disableTree: boolean = false;

  public setDisableTree(disable: boolean): void {
    this.disableTree = disable;
    this.panelHide = disable;
  }

  public isDisableTree(): boolean {
    return this.disableTree;
  }

  public getPaginatorHeighthInpx(): string {
    return this.paginatortHeight + 'px';
  }

  public getTableWidthInpx(): string {
    return this.getTableWidth() + 'px';
  }

  getContentWidth(): number {
    return this.panelHide ? window.document.documentElement.clientWidth : window.document.documentElement.clientWidth - this.TreePanelWidth;
  }

  public getTableWidth(): number {
    if (this.selectedTable == TABLE_SELECTOR.INVOICE_PROCESSING) {
      return this.panelHide ? window.document.documentElement.clientWidth / 2 : 300;
    } else if (this.selectedTable == TABLE_SELECTOR.NOTIFICATIONS) {
      return this.panelHide
        ? window.document.documentElement.clientWidth / 2
        : (window.document.documentElement.clientWidth - this.TreePanelWidth) / 2;
    } else {
      return this.panelHide
        ? window.document.documentElement.clientWidth
        : window.document.documentElement.clientWidth - this.TreePanelWidth;
    }
  }
  public getModalHeight():number{
    return this.componentHeight * 0.9
  }

  public getTableHeight(): number {
    let baseHeight = this.componentHeight - this.headerPanelHeight;

    if (this.selectedTable == TABLE_SELECTOR.INVOICES) {
      //  return window.document.documentElement.clientHeight - (navbarHeight + tblheaderHeight + tblpaginatorHeight);

      return (baseHeight -= this.paginatortHeight + 7);
    }

    if (this.selectedTable == TABLE_SELECTOR.DOCUMENTS) {
      return (baseHeight += 8);
    }

    if (this.selectedTable == TABLE_SELECTOR.CONTRACTS) {
      return baseHeight;
    }

    if (this.selectedTable == TABLE_SELECTOR.LOG) {
      return baseHeight;
    }

    if (this.selectedTable == TABLE_SELECTOR.USERS) {
      return baseHeight;
    }

    if (this.selectedTable == TABLE_SELECTOR.MONTHLY_REPORT) {
      return (baseHeight += this.paginatortHeight + 36);
    }

    if (this.selectedTable == TABLE_SELECTOR.NOTIFICATIONS) {
      return baseHeight;
    }

    if (this.selectedTable == TABLE_SELECTOR.INVOICE_PROCESSING) {
      return (baseHeight = 300);
    }
    if (this.selectedTable == TABLE_SELECTOR.PODS) {
      return (baseHeight -= 10);
    }
    if (this.selectedTable == TABLE_SELECTOR.METERVALUE) {
      return (baseHeight = window.document.documentElement.clientHeight - (this.headerPanelHeight - 5));
    }
    if (this.selectedTable == TABLE_SELECTOR.CONFIG) {
      return baseHeight;
    }
    if (this.selectedTable == TABLE_SELECTOR.PROVIDER_INTEGRATION) {
      return (baseHeight -= 40);
    }
    if (this.selectedTable == TABLE_SELECTOR.CONTROL_PANEL) {
      return baseHeight;
    }
    return baseHeight;
  }

  public getTableHeightInpx(): string {
    return this.getTableHeight() + 'px';
  }

  public getCurrentUser(): User {
    return this._currentUser;
  }

  private _currentUser: User = null;

  //@ts-ignore
  public changeLogin(user: User) {
    this._currentUser = JSON.parse(localStorage.getItem("currentUser")) as User;
    this.loginSubject.next(this._currentUser);
  }
  public isAdminUser(): boolean {
    return this.getCurrentUser().authority == 'ADMIN';
  }

  public isCustomerAdminUser(): boolean {
    return this.getCurrentUser().authority == 'CUSTOMER_ADMIN';
  }

  public isPod(item: any): boolean {
    return item != null && PodTypeEnum[item.type] != null;
  }

  public isCustomer(item: any) {
    return item != null && item.type == TreeTypeEnum[TreeTypeEnum.CUSTOMER];
  }

  public downloadDocument(uuid: string, filenev: string) {
    this.documentController.downloadUsingGET(uuid, this.getCurrentUser().token, 'response').subscribe((data) => {
      //@ts-ignore
      import('file-saver').then((FileSaver) => {
        FileSaver.saveAs(data.body, this.getFilenameFromHeader(data, filenev));
      });
    });
  }

  public getFilenameFromHeader(data: any, defaultFileName: string): string {
    let disposition: string = data.headers.get('Content-Disposition') as string;
    let fileName: string = disposition.match('filename="(.*?)"')[1];
    if (fileName == null) {
      fileName = defaultFileName;
    }
    return fileName;
  }

  public selectTable(selector: TABLE_SELECTOR) {
    this.selectedTable = selector;
    let data: ComponentConnector = { selectedTreeItem: this.selectedTreeItem, selectedTable: this.selectedTable };
    this.connector.next(data);
  }

  public getselectTable() {
    return this.selectedTable;
  }

  public selectTreeItem(item: TreeItem) {
    this.selectedTreeItem = item;
    let data: ComponentConnector = { selectedTreeItem: this.selectedTreeItem, selectedTable: this.selectedTable };
    this.connector.next(data);
  }

  public findLabel(options: LabelValue[], value: string): string {
    let result: string = value;
    if (options == null) {
      return result;
    }
    options.forEach((item) => {
      if (item.value == value) {
        result = item.label;
      }
    });
    return result;
  }

  public zoom_in() {
    this.zoom_to = this.zoom_to + 0.25;
  }

  public zoom_out() {
    if (this.zoom_to > 1) {
      this.zoom_to = this.zoom_to - 0.25;
    }
  }

  public StartWatchIdle() {
    this.userIdle.resetTimer();
    this.userIdle.startWatching();

    this.userIdle
      .onTimerStart()
      .pipe(take(1))
      .subscribe(() => {});

    this.userIdle.onTimeout().subscribe(() => {
      this.loginService.logout(this.getCurrentUser().token).subscribe(() => {
        sessionStorage.removeItem('currentUser');

        this.router.navigateByUrl('/login');
      });
    });
  }

  stop() {
    this.userIdle.stopTimer();
  }

  stopWatching() {
    this.userIdle.stopWatching();
  }

  startWatching() {
    this.userIdle.startWatching();
  }

  restart() {
    this.userIdle.resetTimer();
  }

  getUserSetting(): any {
    let objUserSetting = JSON.parse(localStorage.getItem('UserSettings'));

    return !objUserSetting ? null : objUserSetting;
  }

  private insertUserSetting(userSetting: any) {
    localStorage.setItem('UserSettings', JSON.stringify(userSetting));
  }

  deleteUSerSetting() {
    localStorage.removeItem('UserSettings');
  }
  removeSetting(key: string) {
    let x = this.getUserSetting();
    delete x['' + key + ''];
    localStorage.removeItem('UserSettings');
    this.insertUserSetting(x);
  }

  addComponentSetting(componentName: string, propertyName: string, displayName: string, value: any) {
    let objUserSetting = this.getUserSetting() ? this.getUserSetting() : {};
    objUserSetting['' + componentName + '_' + propertyName + ''] = JSON.stringify({
      savedValue: value,
      displayname: displayName,
    } as Object);
    this.insertUserSetting(objUserSetting);
  }

  existUserUXSetting(componentName: string, propertyname: string): boolean {
    let objUserSetting = this.getUserSetting() ? this.getUserSetting() : {};
    return objUserSetting.hasOwnProperty(`${componentName}_${propertyname}`);
  }

  getUserSettingBykey(key: string): any {
    let setting = this.getUserSetting();
    return !setting ? null : JSON.parse(setting[key]);
  }

  getUserSettingKeys() {
    let setting = this.getUserSetting();
    return !setting ? [] : Object.keys(this.getUserSetting());
  }

  getUserUXSettingValue(componentName: string, propertyname: string): any {
    if (!this.existUserUXSetting(componentName, propertyname)) return;
    let objUserSetting = this.getUserSetting() ? this.getUserSetting() : {};
    let x: Object = JSON.parse(objUserSetting['' + componentName + '_' + propertyname + '']);
    //@ts-ignore
    return x['savedValue'];
  }

  getCustomerByTreeItem(item: TreeItem): TreeItem {
    return item.type == 'CUSTOMER' ? item : this.getCustomerByTreeItem(item.parent);
  }
/*
  getTreeItemByCustomerId(customerId: string): TreeItem {
    /// TODO Ezt befejezni
    return null;
  }
*/
  showInvoiceList(customerId: string, filteredItem: string, value: string) {
    
    let data: InvoiceFilterOptions = {
      customerid: customerId,
      routerlink: filteredItem,
      value: value,
    };
    
    this.selectTreeItem(null);
    this.selectedItem = null;

    this.invoiceFilter.next(data);
    this.selectTable(TABLE_SELECTOR.INVOICES);
  }

  automaticClearUserSettings() {
    let userSetting = this.getUserSetting();

    if (userSetting) {
      let first = JSON.parse(userSetting['' + Object.keys(userSetting)[0] + '']);
      if (!first.hasOwnProperty('savedValue')) {
        this.deleteUSerSetting();
      }
    }
  }

  getUnitOptionsByPodType(podType: string): LabelValue[] {
    let result: LabelValue[] = [];
    if (podType == 'ELECTRIC') {
      result = [{ label: ConsuptionUnitEnum[ConsuptionUnitEnum.kWh], value: ConsuptionUnitEnum[ConsuptionUnitEnum.kWh] }];
    } else if (podType == 'GAS') {
      result = [
        { label: ConsuptionUnitEnum[ConsuptionUnitEnum.kWh], value: ConsuptionUnitEnum[ConsuptionUnitEnum.kWh] },
        { label: ConsuptionUnitEnum[ConsuptionUnitEnum.MJ], value: ConsuptionUnitEnum[ConsuptionUnitEnum.MJ] },
        { label: ConsuptionUnitEnum[ConsuptionUnitEnum.M3], value: ConsuptionUnitEnum[ConsuptionUnitEnum.M3] }
      ];
    } else if (podType == 'WATER') {
      result = [{ label: ConsuptionUnitEnum[ConsuptionUnitEnum.M3], value: ConsuptionUnitEnum[ConsuptionUnitEnum.M3] }];
    } else if (podType == 'HEAT') {
      result = [
        { label: ConsuptionUnitEnum[ConsuptionUnitEnum.kWh], value: ConsuptionUnitEnum[ConsuptionUnitEnum.kWh] },
        { label: ConsuptionUnitEnum[ConsuptionUnitEnum.MJ], value: ConsuptionUnitEnum[ConsuptionUnitEnum.MJ] },
      ];
    } else {
      result = this.getBaseUnitOptions();
    }
    return result;
  }

  getBaseUnitOptions(): LabelValue[] {
    return this.getLabelValuesFromEnum(ConsuptionUnitEnum);
  }
  //string enumokból csinál labelValue listát
  getLabelValuesFromEnum(penum: any) {
    let res: LabelValue[] = Object.keys(penum).map((mt) => {
      return { label: penum[mt], value: mt } as LabelValue;
    });
    return res;
  }
}
