import { Component } from "@angular/core";
import { EnumControllerService } from "../../../../model/api/enumController.service";
import { InvoiceControllerService } from "../../../../model/api/invoiceController.service";
import { InvoiceShareControllerService } from "../../../../model/api/invoiceShareController.service";
//import { InvoiceControllerService } from "../../../../model/api/invoiceController.service";
import { InvoiceshareDto } from "../../../../model/model/invoiceshareDto";
import { CommonService } from "../../../common/common.service";

@Component({
    selector: "invoice-share",
    templateUrl: "./invoice-share.component.html",
    styleUrls: ["./invoice-share.component.css"],
  })


export class InvoiceShareComponent {

    public cols: any;
    public currentInc: number = 0;
    public shares: InvoiceshareDto[];
    public invoiceId : number;
    public currencyOptions: any;
    public loadInvoiceShares(invoiceId: number) {
        
        if (invoiceId === undefined) {
            return;
        }
        this.invoiceId = invoiceId;
        this.invoiceShareService.listInvoicesharesUsingGET(invoiceId, this.commonService.getCurrentUser().token).subscribe(resp => {
            this.shares = resp;
        });
    }

    exportExcel() {
      this.invoiceService
      .exportInvoiceDetailsXlsUsingGET(this.invoiceId, this.commonService.getCurrentUser().token, 'response')
      .subscribe((data) => {
        import('file-saver').then((FileSaver) => {
          FileSaver.saveAs(data.body, this.commonService.getFilenameFromHeader(data, 'szamla.xlsx'));
        });
      });
    }

    constructor(private commonService: CommonService, 
        private invoiceShareService: InvoiceShareControllerService,
        private invoiceService: InvoiceControllerService,
        private enumService: EnumControllerService,
        ) {
      }

      ngOnInit() {
        this.cols = [
          { field: 'name', header: 'Költségviselő', size: "100px", align: "left" },
          { field: 'percent', header: 'Arány', size: "50px", align: "right" },
          { field: 'net', header: 'Nettó', size: "50px", align: "right" },
          { field: 'vat', header: 'Áfa', size: "50px", align: "right" },
          { field: 'amount', header: 'Bruttó', size: "50px", align: "right" }
        ];
        this.enumService.listCurrenciesUsingGET(this.commonService.getCurrentUser().token).subscribe((resp) => {
          this.currencyOptions = resp.labelValues;
        });
    
      }

      getTableWidthInpx(): string {
        return this.commonService.getTableWidthInpx();
      }
    
      getTableHeightInpx(): string {
        return this.commonService.getTableHeightInpx();
      }
      getTablePageSize(): number {
        return this.commonService.tablePageSize;
      }

      getNumberFormatRoundingInteger(): string {
        return this.commonService.numberFormatRoundingInteger;
      }
    
      findLabel(value: string): string {
        return this.commonService.findLabel(this.currencyOptions, value);
      }
 
}  