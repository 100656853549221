import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { TreeItem } from 'src/app/model/model/treeItem';
import { Subscription } from 'rxjs/internal/Subscription';
import { CommonService } from '../../common/common.service';
import { TABLE_SELECTOR } from '../../common/enums/table-selector';
import { MeterDto, ListMeterRequest, CreateMeterRequest, LabelValue } from 'src/app/model/model/models';
import { MeterControllerService } from 'src/app/model/api/api';
import { Table } from 'primeng/components/table/table';
import { exit } from 'process';
import { ToasterService } from '../shared/toaster/toaster.service';
import { ConfirmationService } from 'primeng/api';
import { NgForm } from '@angular/forms';
import { MeterTypeEnum } from '../../common/enums/meterType';

@Component({
  selector: 'meter-list',
  templateUrl: './meter-list.component.html',
  styleUrls: ['./meter-list.component.css'],
})
export class MeterListComponent implements OnInit {
  @ViewChild('table', { static: true }) table: Table;
  @ViewChild('formMeter', { static: true }) formMeter: NgForm;

  @Output() onSelect: EventEmitter<any> = new EventEmitter();

  constructor(
    private commonService: CommonService,
    private meterControllerService: MeterControllerService,
    private confirmationService: ConfirmationService,
    private toasterService: ToasterService
  ) {}
  public cols: any[];
  selectedTreeItem: TreeItem;
  private subscriptions: Subscription[] = [];
  public currentInc: number = 0;
  public metersTotalCount: number = 0;
  public currentCustomerID: string;
  public currentPodId: number;
  public meterFormValues: MeterDto = { id: null, name: '', serialNumber: '', type: 'NORMAL' };
  public meterTypeOptions: LabelValue[] = [];
  public meters: MeterDto[] = [];
  public selectedMeter: MeterDto;
  public displayMeterEditor: boolean = false;

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  ngOnInit() {
    this.setTableCols();
    this.subscriptions.push(
      this.commonService.connector.subscribe((data) => {
        if (data.selectedTreeItem) {
          this.selectedTreeItem = data.selectedTreeItem;
          this.currentCustomerID = this.commonService.getCustomerByTreeItem(data.selectedTreeItem).key;
          this.selectedTreeItem.podId ? (this.currentPodId = this.selectedTreeItem.podId) : (this.currentPodId = null);
        }
        this.filter();
      })
    );
  }

  public filter() {
    if (!this.selectedTreeItem) return;
    this.commonService.setDisableTree(false);
    this.listMeters();
    this.meters = [];
  }

  public listMeters() {
    if (TABLE_SELECTOR.METERVALUE != this.commonService.getselectTable()) return;
    let req: ListMeterRequest = this.buildListRequest();
    if (!req.customerId || !req.podId) return;
    this.subscriptions.push(
      this.meterControllerService.listMetersUsingPOST(req, this.commonService.getCurrentUser().token).subscribe((data) => {
        this.meters = data;
        if (data.length > 0) {
          this.selectedMeter = this.meters[0];
          this.onSelect.emit({ data: { ...this.meters[0] } });
        }
      })
    );
  }

  private buildListRequest(): ListMeterRequest {
    let req: ListMeterRequest = {} as ListMeterRequest;
    if (this.currentCustomerID) req.customerId = +this.currentCustomerID;
    if (this.currentPodId) req.podId = this.currentPodId;
    return req;
  }

  private setTableCols() {
    this.cols = [
      { field: 'name', header: 'Saját elnevezés', size: '150px', align: 'left' },
      { field: 'serialNumber', header: 'Gyár szám', size: '150px', align: 'left' },
      { field: 'operations', header: 'Műveletek', size: '100px', align: 'center' },
    ];
  }

  public getTableHeightInpx(): string {
    return this.commonService.getTableHeight() - 68 + 'px';
  }

  activateMeter(data: MeterDto) {
    this.confirmationService.confirm({
      message: 'Szeretné <b>aktiválni</b> a(z) ' + data.name + ' nevű mérőórát?',
      accept: () => {
        this.meterControllerService.activateMeterUsingGET(data.id, this.commonService.getCurrentUser().token).subscribe(() => {
          this.filter();
          this.onSelect.emit(null);
        });
      },
    });
  }

  inactivateMeter(data: MeterDto) {
    this.confirmationService.confirm({
      message: 'Szeretné <b>inaktiválni</b> a(z) ' + data.name + ' nevű mérőórát?',
      accept: () => {
        this.meterControllerService.inactivateMeterUsingGET(data.id, this.commonService.getCurrentUser().token).subscribe(() => {
          this.filter();
          this.onSelect.emit(null);
        });
      },
    });
  }

  public editMeter(data) {
    if (data) {
      this.meterFormValues.name = data.name;
      this.meterFormValues.serialNumber = data.serialNumber;
      this.meterFormValues.type = data.type ? data.type : 'NORMAL'
      this.meterFormValues.id = data.id;
    } else {
      this.meterFormValues.id = null;
      this.meterFormValues.name = null;
      this.meterFormValues.serialNumber = null;
    }
    this.meterTypeOptions = this.commonService.getLabelValuesFromEnum(MeterTypeEnum);
    this.displayMeterEditor = true;
  }

  isDisabled() {
    return false;
    //(this.meterFormValues.name === null) || (this.meterFormValues.serialNumber === null);
  }

  public validate(): boolean {
    if (this.meterFormValues.name === null) {
      this.toasterService.showToaster({ severity: 'error', summary: 'A Saját elnevezés kitöltése kötelező', detail: '', life: 30000 });
      return false;
    }

    if (this.meterFormValues.serialNumber === null) {
      this.toasterService.showToaster({ severity: 'error', summary: 'A Gyári szám kitöltése kötelező', detail: '', life: 30000 });
      return false;
    }
    return true;
  }

  //@ts-ignore
  public saveMeter() {
    if (!this.validate()) return exit;
    let req: CreateMeterRequest = {
      name: this.meterFormValues.name,
      serialNumber: this.meterFormValues.serialNumber,
      podId: this.currentPodId,
      customerId: +this.currentCustomerID,
      type: this.meterFormValues.type ? this.meterFormValues.type : 'NORMAL',
    };

    if (this.meterFormValues.id != null) {
      this.subscriptions.push(
        this.meterControllerService
          .editMeterUsingPOST(req, this.meterFormValues.id, this.commonService.getCurrentUser().token)
          .subscribe(() => {
            this.displayMeterEditor = false;
            this.formMeter.resetForm();
            this.filter();
          })
      );
    } else {
      this.subscriptions.push(
        this.meterControllerService.addMeterUsingPOST(req, this.commonService.getCurrentUser().token).subscribe(() => {
          this.displayMeterEditor = false;
          this.formMeter.resetForm();
          this.filter();
        })
      );
    }
  }

  cancelEdit() {
    this.displayMeterEditor = false;
  }

  deleteMeter(rowData) {
    this.confirmationService.confirm({
      message: `Szeretné törölni a(z) ${rowData.name} elnevezésű mérőórát?`,
      accept: () => {
        this.meterControllerService.deleteMeterUsingGET(rowData.id, this.commonService.getCurrentUser().token).subscribe(() => {
          this.displayMeterEditor = false;
          this.filter();
          this.onSelect.emit(null);
        });
      },
    });
  }

  SelectionChange(event: any) {
    this.onSelect.emit(event);
  }

  isElectricPOD(): boolean {
    return this.selectedTreeItem && this.selectedTreeItem.type == 'ELECTRIC';
  }

  isTwoWayMeter():boolean{
      
    return this.selectedMeter && MeterTypeEnum[this.selectedMeter.type] === MeterTypeEnum['TWO_WAY']

  }

}
