import { Component, Input, OnDestroy, Output } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'tree-chooser',
  template: `
    <p-tree
      [style]="{ width: '100%', 'overflow-x': 'hidden', 'overflow-y': 'hidden', height: '100%', 'padding-bottom': '2.5rem' }"
      selectionMode="single"
      [value]="value"
      filter="true"
      filterMode="lenient"
      [(selection)]="selection"
      (onNodeSelect)="nodeSelect()"
      filterPlaceholder="Keresés..."
    >
      <ng-template let-node pTemplate="CUSTOMER">
        <span style="color: #0057A0" class="ui-treenode-icon pi pi-user ng-star-inserted"></span>
        <span style="padding-left: 0.5em;" [innerHTML]="node.label"></span>
      </ng-template>
      <ng-template let-node pTemplate="CODE">
        <span style="color: #009EDE" class="ui-treenode-icon pi pi-ticket ng-star-inserted"></span>
        <span style="padding-left: 0.5em;" [innerHTML]="node.label"></span>
      </ng-template>
      <ng-template let-node pTemplate="SITE">
        <span style="color: #009EDE" class="ui-treenode-icon pi pi-th-large ng-star-inserted"></span>
        <span style="padding-left: 0.5em;" [innerHTML]="node.label"></span>
      </ng-template>
      <ng-template let-node pTemplate="ADDRESS">
        <span style="color: #009EDE" class="ui-treenode-icon pi pi-home ng-star-inserted"></span>
        <span style="padding-left: 0.5em;" [innerHTML]="node.label"></span>
      </ng-template>

      <ng-template let-node pTemplate="GAS">
        <pod-type template="GAS" [label]="node.label"></pod-type>
      </ng-template>

      <ng-template let-node pTemplate="ELECTRIC">
        <pod-type template="ELECTRIC" [label]="node.label"></pod-type>
      </ng-template>

      <ng-template let-node pTemplate="WATER">
        <pod-type template="WATER" [label]="node.label"></pod-type>
      </ng-template>
      <ng-template let-node pTemplate="HEAT">
        <pod-type template="HEAT" [label]="node.label"></pod-type>
      </ng-template>
    </p-tree>
  `,
})
export class TreeChooserComponent implements OnDestroy {
  @Input() selection: any;

  @Input() value: TreeNode[];

  @Output() selectionChange: EventEmitter<any> = new EventEmitter();

  constructor() {}
  ngOnDestroy(): void {}

  nodeSelect() {
    this.selectionChange.emit(this.selection);
  }
}
