/**
 * OLC System
 * Ügyfél és számlakezelő rendszer
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ContractResponse } from '../model/contractResponse';
import { CreateContractRequest } from '../model/createContractRequest';
import { CreateContractResponse } from '../model/createContractResponse';
import { ListContractRequest } from '../model/listContractRequest';
import { ListContractResponse } from '../model/listContractResponse';
import { UpdateContractRequest } from '../model/updateContractRequest';
import { UpdateContractResponse } from '../model/updateContractResponse';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { environment } from '../../../environments/environment';


@Injectable()
export class ContractControllerService {

    protected basePath = environment.apiBaseUrl + '/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * createContract
     * 
     * @param body request
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createContractUsingPOST(body: CreateContractRequest, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<CreateContractResponse>;
    public createContractUsingPOST(body: CreateContractRequest, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreateContractResponse>>;
    public createContractUsingPOST(body: CreateContractRequest, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreateContractResponse>>;
    public createContractUsingPOST(body: CreateContractRequest, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createContractUsingPOST.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CreateContractResponse>(`${this.basePath}/contract/create`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * delete
     * 
     * @param contractId contractId
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUsingGET(contractId: number, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteUsingGET(contractId: number, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteUsingGET(contractId: number, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteUsingGET(contractId: number, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (contractId === null || contractId === undefined) {
            throw new Error('Required parameter contractId was null or undefined when calling deleteUsingGET.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<any>(`${this.basePath}/contract/delete/${encodeURIComponent(String(contractId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * listContracts
     * 
     * @param body request
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listContractsUsingPOST(body: ListContractRequest, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<ListContractResponse>;
    public listContractsUsingPOST(body: ListContractRequest, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListContractResponse>>;
    public listContractsUsingPOST(body: ListContractRequest, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListContractResponse>>;
    public listContractsUsingPOST(body: ListContractRequest, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling listContractsUsingPOST.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ListContractResponse>(`${this.basePath}/contract/list`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * loadContract
     * 
     * @param contractId contractId
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loadContractUsingGET(contractId: number, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<ContractResponse>;
    public loadContractUsingGET(contractId: number, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ContractResponse>>;
    public loadContractUsingGET(contractId: number, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ContractResponse>>;
    public loadContractUsingGET(contractId: number, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (contractId === null || contractId === undefined) {
            throw new Error('Required parameter contractId was null or undefined when calling loadContractUsingGET.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<ContractResponse>(`${this.basePath}/contract/load/${encodeURIComponent(String(contractId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * modifyContract
     * 
     * @param body request
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public modifyContractUsingPOST(body: UpdateContractRequest, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<UpdateContractResponse>;
    public modifyContractUsingPOST(body: UpdateContractRequest, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UpdateContractResponse>>;
    public modifyContractUsingPOST(body: UpdateContractRequest, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UpdateContractResponse>>;
    public modifyContractUsingPOST(body: UpdateContractRequest, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling modifyContractUsingPOST.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UpdateContractResponse>(`${this.basePath}/contract/update`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * uploadContractDocument
     * 
     * @param file 
     * @param contractId contractId
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadContractDocumentUsingPOST(file: Blob, contractId: number, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public uploadContractDocumentUsingPOST(file: Blob, contractId: number, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public uploadContractDocumentUsingPOST(file: Blob, contractId: number, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public uploadContractDocumentUsingPOST(file: Blob, contractId: number, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling uploadContractDocumentUsingPOST.');
        }

        if (contractId === null || contractId === undefined) {
            throw new Error('Required parameter contractId was null or undefined when calling uploadContractDocumentUsingPOST.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.post<any>(`${this.basePath}/contract/upload/${encodeURIComponent(String(contractId))}`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
