import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';


export class Http {
    constructor(private http: HttpClient) { }

    public httpGet(url: string): Observable<any> {
        return this.http.get<any>(url);
    }

    public httpPost(url: string, body: Object, opts: Object): Observable<any> {
        return this.http.post<any>(url, body, opts);
    }

}