import { Component, OnInit, ViewChild } from "@angular/core";
import { MenuItem, TreeNode, ConfirmationService } from "primeng/api";
import { ContextMenu } from "primeng/contextmenu";
import { Dialog } from "primeng/dialog";
import { PodDto, PodControllerService, EnumControllerService, CustomerTreeControllerService, TreeItem, SavePodRequest, UpdateCustomerTreeRequest, CreateCustomerChildRequest } from "src/app/model";
import { CommonService } from "src/app/modules/common/common.service";
import { TreeTypeEnum } from "src/app/modules/common/enums/treeType";
import { TreeUtils } from "src/app/modules/common/tree.utils";
import { InvoiceShareControllerService } from "../../../../model/api/invoiceShareController.service";
import { WorkflowControllerService } from "../../../../model/api/workflowController.service";
import { ComboItem } from "../../contract-manager/combo-item.inteface";
import { InvoiceshareEditorComponent } from "../invoiceshare-editor/invoiceshare-editor.component";
import { ToasterService } from "../toaster/toaster.service";
import { WorkflowEditorComponent } from "../workflow-editor/workflow-editor.component";



@Component({
  selector: "tree",
  templateUrl: "./tree.component.html",
  styleUrls: ["./tree.component.css"]
})
export class TreeComponent implements OnInit {

  
  @ViewChild('workflowEditor', { static: false }) workflowEditor: WorkflowEditorComponent;

  @ViewChild('invoiceshareEditor', { static: false }) invoiceshareEditor: InvoiceshareEditorComponent;

  public componentHeight: number;
  displayPodEditor:boolean;
  displayWorkflowEditor:boolean;
  podFormValues : PodDto = {};
  disableButton: boolean;
  editTreeItemName: string;
  editTreeItemType: TreeTypeEnum;
  editTreeItemLabel: string;
  displayTreeItemEditor: boolean;
  isModify: boolean = false;
  public podTypeOptions: ComboItem[] = [];

  public rhdTariffOptions: ComboItem[] = [];
  
  public podControlOptions: ComboItem[] = [];
  
  
  @ViewChild("cm", { static: false }) cm: ContextMenu;

  @ViewChild("treeItemEditor", { static: false }) treeItemEditor: Dialog;
  

  public contextMenuItems: MenuItem[];

  tree: TreeNode[];

  constructor(
    private commonService: CommonService,
    private treeUtils: TreeUtils,
    private podService: PodControllerService,
    private workflowService : WorkflowControllerService,
    private invoiceshareService : InvoiceShareControllerService,
    private enumService : EnumControllerService,
    private confirmationService: ConfirmationService,
    private customerTreeService: CustomerTreeControllerService,
    private toasterService: ToasterService
    
  ) { }

  ngOnInit() {
    this.componentHeight = window.innerHeight - this.commonService.componentHeightSubValue;
    this.commonService.loginSubject.subscribe(() =>{
      this.loadTree();
    });
    this.initDropdowns();
  }

  public loadTree() {
    this.customerTreeService.getTreeUsingGET(this.commonService.getCurrentUser().token).subscribe(response => {
      this.tree = response.items;

      if (this.tree.length > 0) {
        if (!this.commonService.isAdminUser()) {
          this.onSelect(this.tree[0]);
        }
        else if (this.commonService.selectedItem!=null) {
          let item = this.treeUtils.findTreeItemByKey(this.commonService.selectedItem.key, this.tree, null);
          if (item == null && this.commonService.selectedItem["parent"] != null) {
            item = this.treeUtils.findTreeItemByKey(this.commonService.selectedItem["parent"].key, this.tree, null);  
          }
          this.onSelect(item);
          this.expandTreeChain(item); 
        }
      }
    });
  }


  initDropdowns() {
    this.enumService.listPodTypesUsingGET(this.commonService.getCurrentUser().token).subscribe(resp => {
      resp.labelValues.forEach(labelValue => {
        this.podTypeOptions.push({label:labelValue.label,value:labelValue.value})
      });
    });
    this.enumService.listRhdTariffsUsingGET(this.commonService.getCurrentUser().token).subscribe(resp => {
      resp.labelValues.forEach(labelValue => {
        this.rhdTariffOptions.push({label:labelValue.label,value:labelValue.value})
      });
    });
    this.enumService.listPodControlsUsingGET(this.commonService.getCurrentUser().token).subscribe(resp => {
      resp.labelValues.forEach(labelValue => {
        this.podControlOptions.push({label:labelValue.label,value:labelValue.value})
      });
    });    
  }

  // @ts-ignore
  public onCustomerMenu(event: MouseEvent, item: TreeItem) {
    this.onSelect(item);
    this.contextMenuItems = [];
    if (this.commonService.isAdminUser()) {
      this.contextMenuItems.push({ label: "Új kód", icon: 'pi pi-ticket', command: () => this.newCode(item) });
      this.contextMenuItems.push({ label: "Új telephely", icon: 'pi pi-th-large', command: () => this.newSite(item) });
      this.contextMenuItems.push({ label: "Ügyfél szerkesztése", icon: 'pi pi-pencil', command: () => this.editCustomer(item) });
      this.contextMenuItems.push({ label: "Ügyfél törlése", icon: 'pi pi-trash', command: () => this.delete(item) });
    }
    if (this.commonService.isAdminUser() || this.commonService.isCustomerAdminUser()) {
      this.contextMenuItems.push({ label: "Aláírás szerkesztés", icon: 'pi pi-pencil', command: () => this.editWorkflowConfig(item) });
      this.contextMenuItems.push({ label: "Költségmegosztás ", icon: 'fa fa-cubes', command: () => this.editInvoiceshareConfig(item) });
    }
  }

  // @ts-ignore
  public onCodeMenu(event: MouseEvent, item: TreeItem) {
    this.onSelect(item);
    this.contextMenuItems = [];
    if (this.commonService.isAdminUser()) {
      this.contextMenuItems.push({ label: "Új telephely", icon: 'pi pi-th-large', command: () => this.newSite(item) });
      this.contextMenuItems.push({ label: "Kód szerkesztése", icon: 'pi pi-pencil', command: () => this.editCode(item) });
      this.contextMenuItems.push({ label: "Kód mozgatása", icon: 'pi pi-arrow-circle-left', command: () => this.move(item)});
      this.contextMenuItems.push({ label: "Kód másolása", icon: 'pi pi-copy', command: () => this.copy(item) });
      this.contextMenuItems.push({ label: "Kód törlése", icon: 'pi pi-trash', command: () => this.delete(item) });
      
    }
    if (this.commonService.isAdminUser() || this.commonService.isCustomerAdminUser()) {
      this.contextMenuItems.push({ label: "Aláírás szerkesztés", icon: 'pi pi-pencil', command: () => this.editWorkflowConfig(item) });
      this.contextMenuItems.push({ label: "Költségmegosztás ", icon: 'fa fa-cubes', command: () => this.editInvoiceshareConfig(item) });
    }
  }

  // @ts-ignore
  public onSiteMenu(event: MouseEvent, item: TreeItem) {
    this.onSelect(item);
    this.contextMenuItems = [];
    if (this.commonService.isAdminUser()) {
      this.contextMenuItems.push({ label: "Új cím", icon: 'pi pi-home', command: () => this.newAddress(item) });
      this.contextMenuItems.push({ label: "Telephely szerkesztése", icon: 'pi pi-pencil', command: () => this.editSite(item) });
      this.contextMenuItems.push({ label: "Telephely mozgatása", icon: 'pi pi-arrow-circle-left', command: () => this.move(item)});
      this.contextMenuItems.push({ label: "Telephely másolása", icon: 'pi pi-copy', command: () => this.copy(item) });
      this.contextMenuItems.push({ label: "Telephely törlése", icon: 'pi pi-trash', command: () => this.delete(item) });
    }
    if (this.commonService.isAdminUser() || this.commonService.isCustomerAdminUser()) {
      this.contextMenuItems.push({ label: "Aláírás szerkesztés", icon: 'pi pi-pencil', command: () => this.editWorkflowConfig(item) });
      this.contextMenuItems.push({ label: "Költségmegosztás ", icon: 'fa fa-cubes', command: () => this.editInvoiceshareConfig(item) });
    }
  }

  // @ts-ignore
  public onAddressMenu(event: MouseEvent, item: TreeItem) {
    this.onSelect(item);
    this.contextMenuItems = [];
    if (this.commonService.isAdminUser()) {
      this.contextMenuItems.push({ label: "Új POD", icon: 'pi pi-circle-on', command: () => this.newPod(item) });
      this.contextMenuItems.push({ label: "Cím szerkesztése", icon: 'pi pi-pencil', command: () => this.editAddress(item) });
      this.contextMenuItems.push({ label: "Cím mozgatása", icon: 'pi pi-arrow-circle-left', command: () => this.move(item) });
      this.contextMenuItems.push({ label: "Cím másolása", icon: 'pi pi-copy', command: () => this.copy(item)});
      this.contextMenuItems.push({ label: "Cím törlése", icon: 'pi pi-trash', command: () => this.delete(item) });
      
    }

    if (this.commonService.isAdminUser() || this.commonService.isCustomerAdminUser()) {
      this.contextMenuItems.push({ label: "Aláírás szerkesztés", icon: 'pi pi-pencil', command: () => this.editWorkflowConfig(item) });
      this.contextMenuItems.push({ label: "Költségmegosztás ", icon: 'fa fa-cubes', command: () => this.editInvoiceshareConfig(item) });
    }

  }
  
  // @ts-ignore
  public onPodMenu(event: MouseEvent, item: TreeItem) {
    this.onSelect(item);
    this.contextMenuItems = [];
    if (this.commonService.isAdminUser()) {
        this.contextMenuItems.push({ label: "POD szerkesztés", icon: 'pi pi-pencil', command: () => this.editPod(item) });
        this.contextMenuItems.push({ label: "POD mozgatása", icon: 'pi pi-arrow-circle-left', command: () => this.move(item) });
        this.contextMenuItems.push({ label: "POD másolása", icon: 'pi pi-copy', command: () => this.copy(item) });
        this.contextMenuItems.push({ label: "POD törlése", icon: 'pi pi-trash', command: () => this.delete(item) });
      if (item.status == 'ACTIVE') {
        this.contextMenuItems.push({ label: "POD inaktiválás", icon: 'pi pi-ban', command: () => this.inactivatePod(item) });
      }
      if (item.status == 'INACTIVE') {
        this.contextMenuItems.push({ label: "POD aktiválás", icon: 'pi pi-check', command: () => this.activatePod(item) });
      }
    }
    if (this.commonService.isAdminUser() || this.commonService.isCustomerAdminUser()) {
      this.contextMenuItems.push({ label: "Aláírás szerkesztés", icon: 'pi pi-pencil', command: () => this.editWorkflowConfig(item) });
      this.contextMenuItems.push({ label: "Költségmegosztás ", icon: 'fa fa-cubes', command: () => this.editInvoiceshareConfig(item) });
    }
  }

  editCustomer(item: TreeItem) {
    this.treeItemEditor.header = "Ügyfél szerkesztése";
    this.displayTreeItemEditor = true;
    this.editTreeItemLabel = "Név";
    this.editTreeItemName = item.label;
    this.editTreeItemType = TreeTypeEnum.CUSTOMER;
    this.isModify = true;
  }

  //@ts-ignore
  newCode(item: TreeItem) {    
    this.treeItemEditor.header = "Új kód";
    this.displayTreeItemEditor = true;
    this.editTreeItemLabel = "Kód";
    this.editTreeItemName = '';
    this.editTreeItemType = TreeTypeEnum.CODE;
    this.isModify = false;
  }
  editCode(item: TreeItem) {
    this.treeItemEditor.header = "Kód szerkesztése";
    this.displayTreeItemEditor = true;
    this.editTreeItemLabel = "Kód";
    this.editTreeItemName = item.label;
    this.editTreeItemType = TreeTypeEnum.CODE;
    this.isModify = true;
  }
  //@ts-ignore
  newSite(item: TreeItem) {
    this.treeItemEditor.header = "Új telephely";
    this.displayTreeItemEditor = true;
    this.editTreeItemLabel = "Telephely";
    this.editTreeItemName = '';
    this.editTreeItemType = TreeTypeEnum.SITE;
    this.isModify = false;
  }
  
  editSite(item: TreeItem) {
    this.treeItemEditor.header = "Telephely szerkesztése";
    this.displayTreeItemEditor = true;
    this.editTreeItemLabel = "Telephely";
    this.editTreeItemName = item.label;
    this.editTreeItemType = TreeTypeEnum.SITE;
    this.isModify = true;
  }
  //@ts-ignore
  newAddress(item: TreeItem) {
    this.treeItemEditor.header = "Új cím";
    this.displayTreeItemEditor = true;
    this.editTreeItemLabel = "Cím";
    this.editTreeItemName = '';
    this.editTreeItemType = TreeTypeEnum.ADDRESS;
    this.isModify = false;
  }
  
  editAddress(item: TreeItem) {
    this.treeItemEditor.header = "Cím szerkesztése";
    this.displayTreeItemEditor = true;
    this.editTreeItemLabel = "Cím";
    this.editTreeItemName = item.label;
    this.editTreeItemType = TreeTypeEnum.ADDRESS;
    this.isModify = true;
  }
  //@ts-ignore
  newPod(item: TreeItem) {
    this.podFormValues = {};
    this.displayPodEditor = true;
  }

  editPod(item: TreeItem) {
    this.podService.getPodUsingGET(item.podId, this.commonService.getCurrentUser().token).subscribe(resp => {
      this.podFormValues = resp.pod;
      this.displayPodEditor = true;
    });   
  }
  
  savePod() {
    if (this.podFormValues.type != 'ELECTRIC') {
      this.podFormValues.rhdTariff = null;
      this.podFormValues.control = null;
    }
    if (this.podFormValues.type != 'GAS') {
      this.podFormValues.capacity = null;
    }
    let req : SavePodRequest = {
      parentPath: (this.commonService.selectedItem.parentPath + '.' + this.commonService.selectedItem.key), 
      pod : this.podFormValues
    }
    this.podService.saveUsingPOST(req, this.commonService.getCurrentUser().token).subscribe(() => {
      this.displayPodEditor = false;
      this.loadTree();
    });  
  }

  editWorkflowConfig(item: TreeItem) {
    this.workflowService.loadConfigUsingGET(parseInt(item.key), this.commonService.getCurrentUser().token).subscribe(resp => {
      this.workflowEditor.config = resp;
      this.workflowEditor.visible = true;
    }); 
  }

  editInvoiceshareConfig(item: TreeItem) {
    this.invoiceshareService.loadConfigUsingGET(parseInt(item.key), this.commonService.getCurrentUser().token).subscribe(resp => {
      this.invoiceshareEditor.config = resp;
      this.invoiceshareEditor.visible = true;
    }); 
  }

  inactivatePod(item: TreeItem) {
    this.confirmationService.confirm({ message: 'Szeretné <b>inaktiválni</b> a(z) '+item.label+' nevű POD-ot?', accept: () => { 
      this.customerTreeService.inactivatePodUsingGET(parseInt(item.key), this.commonService.getCurrentUser().token).subscribe(() => {
        this.loadTree();
      });  
  }});
  }

  activatePod(item: TreeItem) {
    this.confirmationService.confirm({ message: 'Szeretné <b>aktiválni</b> a(z) '+item.label+' nevű POD-ot?', accept: () => { 
      this.customerTreeService.activatePodUsingGET(parseInt(item.key), this.commonService.getCurrentUser().token).subscribe(() => {
        this.loadTree();
      });  
   }});
  }

  isValidateInputValue(data: string): boolean{
    //Csak szám, betű, pont vessző perjel szóköz kötőjel lehet  
    return !/[^A-Za-z0-9éÉáÁűŰúÚőŐóÓüÜöÖíÍ.,/ -]/.test(data)  
  }

  saveTreeItem(){
    if (this.editTreeItemType == TreeTypeEnum.ADDRESS)  {
      if(!this.isValidateInputValue(this.editTreeItemName)) {      
        this.toasterService.showToaster({severity:'error', summary:'Nem megengedett karakter!', detail:'Csak alfanumerikus karakterke és ,./- szóköz a megengedett', life:30000});
        return
      } 
      }
    
    if (this.isModify) {
      let request: UpdateCustomerTreeRequest = {
        name: this.editTreeItemName,
        treeId: Number(this.commonService.selectedItem.key)
      };      
      this.customerTreeService.updateCustomerTreeUsingPOST(request, this.commonService.getCurrentUser().token).subscribe(() => {
        this.displayTreeItemEditor = false;
        this.loadTree();
      });  
    } else {
      let request : CreateCustomerChildRequest = {
        name: this.editTreeItemName,
        treeId: Number(this.commonService.selectedItem.key),
        type: this.editTreeItemType
      };
      this.customerTreeService.createCustomerChildUsingPOST(request, this.commonService.getCurrentUser().token).subscribe(() => {
        this.displayTreeItemEditor = false;
        this.loadTree();
      });
    }
  } 

  delete(item: TreeItem) {
    this.confirmationService.confirm({ message: 'Szeretné törölni a(z) <b>'+item.label+'</b> nevű elemet?', accept: () => { 
      this.customerTreeService.deleteUsingGET(parseInt(item.key), this.commonService.getCurrentUser().token).subscribe(() => {
        this.loadTree();
      });
    }});

  }
  
  onSelect(item: TreeItem) {
    this.commonService.selectedItem = item;
    this.commonService.selectTreeItem(item);
  }
  //@ts-ignore
  onUnSelect(item: TreeItem) {
    this.commonService.selectedItem = null;
    this.commonService.selectTreeItem(null);
  }

  expandTreeChain(item:TreeNode) {
    let chain:TreeNode[] = this.treeUtils.getTreeChain(item);
    chain.forEach(element => {
      element.expanded = true;
    });
  }


  treeChooserHeader:string;
  displayTreeChooser:boolean;
  choosableTreeItems:any[];
  treeChooserSelectedTreeItem:TreeItem;
  chooseAction:string;
  sourceId:number;

  move(item: TreeItem) {
    this.chooseAction = 'MOVE'; 
    this.sourceId = parseInt(item.key);
    this.displayTreeChooser = true;
    this.customerTreeService.getTreeUsingGET(this.commonService.getCurrentUser().token).subscribe(response => {
      this.choosableTreeItems = response.items;
    });
  }

  copy(item: TreeItem) {
    this.chooseAction = 'COPY';
    this.sourceId = parseInt(item.key);
    this.displayTreeChooser = true;
    this.customerTreeService.getTreeUsingGET(this.commonService.getCurrentUser().token).subscribe(response => {
      this.choosableTreeItems = response.items;
    });
  }

  onTreeChooserSelect(){
    let destinationId = parseInt(this.treeChooserSelectedTreeItem.key);
    if (this.chooseAction=='MOVE') {
      this.customerTreeService.moveUsingPOST(this.commonService.getCurrentUser().token, destinationId, this.sourceId).subscribe(() => {
        this.loadTree();
        this.displayTreeChooser = false;
      });
    }

    if (this.chooseAction=='COPY') {
      this.customerTreeService.copyUsingPOST(this.commonService.getCurrentUser().token, destinationId, this.sourceId).subscribe(() => {
        this.loadTree();
        this.displayTreeChooser = false;
      });
    }
  }

isDisable() {

  if (this.podFormValues === null) {
    return true;
  }

  if (this.podFormValues.type === null) {
    return true;
  }

  if (this.podFormValues.type == 'ELECTRIC' && (this.podFormValues.name + '').length == 33) {
    return false;
  }

  if (this.podFormValues.type =='GAS' && (this.podFormValues.name + '').length == 16) {
    return false;
  }

  if (this.podFormValues.type == 'WATER' && (this.podFormValues.name + '').length > 0) {
    return false;
  }

  if (this.podFormValues.type == 'HEAT' && (this.podFormValues.name + '').length > 0) {
    return false;
  } 

  return true;
}

get selectedItem(): TreeItem {
  return this.commonService.selectedItem;
}

set selectedItem(item) {
  this.commonService.selectedItem = item;
}
}
