import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError as observableThrowError, Observable } from "rxjs";
import { tap, catchError, finalize } from "rxjs/operators";
import { ToastMessage } from "../shared/toaster/toast-message";
import { ToasterService } from "../shared/toaster/toaster.service";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private toasterService: ToasterService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let toastData: ToastMessage;
    this.toasterService.showSpinner();

    return next.handle(req).pipe(
      tap((evt) => {
        if (evt instanceof HttpResponse) {
          if (evt.status == 200) {
          }
        }
      }),
      catchError((err: any) => {
        
        this.toasterService.hideSpinner();
        if (err instanceof HttpErrorResponse) {
          try {
            toastData = {
              severity: "error",
              summary: "Szerver error!",
              detail: err.error.message,
              life: 30000,
            };
            this.toasterService.showToaster(toastData);
          } catch (e) {
            toastData = {
              severity: "error",
              summary: "Belső hiba!",
              detail: e.message,
              life: 30000,
            };
            this.toasterService.showToaster(toastData);
          }
        }
        return observableThrowError(err);
      }),
      finalize(() => {
        this.toasterService.hideSpinner();
      })
    );
  }
}
