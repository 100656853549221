import { Component, OnInit, ViewChild } from "@angular/core";
import { CommonService } from "../../common/common.service";
import { DocumentFilter } from "./document-filter";
import { NgForm } from "@angular/forms";
import { Paginator } from "primeng/paginator";
import { DocumentControllerService } from "../../../model/api/documentController.service";
import { DocumentDto, ListDocumentsRequest, TreeItem } from "../../../model/model/models";
import { LazyLoadEvent, ConfirmationService } from "primeng/api";
import { TABLE_SELECTOR } from "../../common/enums/table-selector";
import { ToasterService } from "../shared/toaster/toaster.service";

@Component({
  selector: "document-table",
  templateUrl: "./document-table.component.html",
  styleUrls: ["./document-table.component.css"]
})
export class DocumentTableComponent implements OnInit {
  @ViewChild("p", { static: true }) paginator: Paginator;

  @ViewChild("form", { static: true }) uploadForm: NgForm;

  public selectedDocuments: DocumentDto[];

  public documents: DocumentDto[];

  public hu: Object;

  public filterValues: DocumentFilter;

  public sortField:string;

  public sortOrder:number = 0;

  public cols: any[];

  public selectedTreeItem : TreeItem;

  public displayUpload: boolean = false;

  public isUploadDocumentDisabled: boolean;

  public uploadingDocumentFile: File;
  public uploadingDocumentFileName: string = 'Nincs fájl kiválasztva';

  public documentUploadFormValues: any;

  public documentTotalCount: number = 0;

  public currentInc: number = 0;

  constructor(
    private commonService: CommonService,
    private documentController: DocumentControllerService,
    private confirmationService: ConfirmationService,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    this.documentUploadFormValues = {};
    this.cols = [
      { field: "documentName", header: "Azon.", size:"200px", align:"left" },
      { field: "fileName", header: "Fájlnév", size:"200px", align:"left" },
      { field: "podName", header: "Ügyfél / Cím / POD", size:"200px", align:"left" },
      { field: "uploaded", header: "Feltöltve", size:"150px", align:"center" },
      { field: "operations", header: "Műveletek", size:"100px", align:"center" }
    ];
    this.hu = this.commonService.datePickerLanguage;
    this.filterValues = <DocumentFilter>{};
    this.commonService.connector.subscribe(data => {
      this.selectedTreeItem = data.selectedTreeItem;
      this.selectedDocuments = [];
      this.filter();
    });    
  }

  buildListDocumentsRequest() : ListDocumentsRequest {
    let request: ListDocumentsRequest = {
      treeId: this.selectedTreeItem == null ? null : parseInt(this.selectedTreeItem.key),
      pageNumber: this.paginator.getPage(),
      pageSize: this.commonService.tablePageSize,
      sortField: this.sortField,
      direction: this.sortOrder,
      documentName: this.filterValues != null ? this.filterValues.documentName : null,
      fileName: this.filterValues != null ? this.filterValues.fileName : null,
      uploadedFrom: this.filterValues != null && this.filterValues.uploaded !=null ? this.filterValues.uploaded[0] : null,
      uploadedTo: this.filterValues != null && this.filterValues.uploaded !=null ? this.filterValues.uploaded[1] : null,
    };
    return request;
  }
  public listDocuments(): void {
    if (TABLE_SELECTOR.DOCUMENTS != this.commonService.getselectTable()) {
      return;
    }
    this.commonService.setDisableTree(false);
    this.documentController.listDocumentsUsingPOST(this.buildListDocumentsRequest(), this.commonService.getCurrentUser().token).subscribe(data => {
        this.documents = data.documentsPage.content;
        this.documentTotalCount = data.documentsPage.totalElements;
      });
  }

  public sort(event: LazyLoadEvent) {
    this.sortField = event.sortField;
    this.sortOrder = event.sortOrder;
    this.selectedDocuments = [];
    this.listDocuments();
  }

  public filter(){
    this.selectedDocuments = [];
    this.paginator.changePage(0);
    this.currentInc = 0;
    this.listDocuments();
  }
  
  public paginate(): void {
    this.currentInc = this.paginator.getPage() * this.commonService.tablePageSize;
    this.listDocuments();
  }

  downloadDocument(row : number): void {
    this.commonService.downloadDocument(this.documents[row].uuid, this.documents[row].fileName);
  }
  
  deleteDocument(row : number): void {
    this.confirmationService.confirm({ message: 'Szeretné a törölni a '+this.documents[row].documentName+' nevű dokumentumot?', accept: () => { 
      this.documentController.deleteUsingGET1(this.documents[row].id, this.commonService.getCurrentUser().token).subscribe(() =>{
        this.filter();
      });
    }
   });
  }

  uploadDocPanel(): void {
    this.displayUpload = true;
    this.uploadingDocumentFile = null;
    this.uploadingDocumentFileName =  null;
  }

  validate() : boolean {
    if (this.documentUploadFormValues.documentName == null) {
      this.toasterService.showToaster({severity:'error', summary:'A dokumentum neve mező kitöltése kötelező', detail:'', life:30000});
      return false;
  }
  
  if (this.uploadingDocumentFile == null){
    this.toasterService.showToaster({severity:'error', summary:'Nincs dokumentum kiválasztva', detail:'', life:30000});  
    return false;
  }    
  if (this.uploadingDocumentFile.size > this.commonService.maxUploadFileSize) {
    this.toasterService.showToaster({severity:'error', summary:'A kiválasztott dokumentum mérete nagyobb mint a megengedett', detail:'', life:30000});  
    return false;
    }
   
    return true;
  }

  uploadDoc(): void {
    if (!this.validate()) {
      return;
    }
    
    this.documentController.createDocumentUsingPOST(this.uploadingDocumentFile, this.commonService.getCurrentUser().token, this.documentUploadFormValues["documentName"], 
        this.selectedTreeItem.podId, parseInt(this.selectedTreeItem.key)).subscribe(() => {
          this.filterValues = <DocumentFilter>{};
          this.filter();
          this.displayUpload = false;
          this.uploadingDocumentFile = null;
          this.uploadingDocumentFileName =  null;
          this.documentUploadFormValues = {};
          
        }
    );
  }

  uploadFileChange(event): void {
    let target = event.target || event.srcElement;
    this.uploadingDocumentFile = target.files[0];
    this.uploadingDocumentFileName =  this.uploadingDocumentFile.name;
    target.value = '';
  }

  getTableWidthInpx(): string {
    return this.commonService.getTableWidthInpx();
  }

  getTableHeightInpx(): string {
    return this.commonService.getTableHeightInpx();
  }
  isPod(item) : boolean {
    return this.commonService.isPod(item);
  }
  getMaxUploadFileSize(): number {
    return this.commonService.maxUploadFileSize;
  }
  getTablePageSize() : number {
    return this.commonService.tablePageSize;
  }

  public getCurrentPageReportTemplate() {
    let from = this.paginator.getPage() * this.commonService.tablePageSize + 1;
    let to = (this.paginator.getPage() + 1) * this.commonService.tablePageSize;
    const total = this.documentTotalCount | 0;
    to = Math.min(to, total);
    return `{currentPage}. oldal [ ${from} - ${to}  a(z) ${total} -ből]`
  }

}
