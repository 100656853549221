import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { BasicErrorControllerService } from './api/basicErrorController.service';
import { BusinessLogControllerService } from './api/businessLogController.service';
import { CommentControllerService } from './api/commentController.service';
import { ContractControllerService } from './api/contractController.service';
import { CustomerTreeControllerService } from './api/customerTreeController.service';
import { DictionaryControllerService } from './api/dictionaryController.service';
import { DispatcherControllerService } from './api/dispatcherController.service';
import { DocumentControllerService } from './api/documentController.service';
import { EnumControllerService } from './api/enumController.service';
import { InvoiceControllerService } from './api/invoiceController.service';
import { MonthlyReportControllerService } from './api/monthlyReportController.service';
import { NotificationControllerService } from './api/notificationController.service';
import { PodControllerService } from './api/podController.service';
import { UserControllerService } from './api/userController.service';
import { ConfigControllerService } from './api/configController.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    BasicErrorControllerService,
    BusinessLogControllerService,
    CommentControllerService,
    ContractControllerService,
    CustomerTreeControllerService,
    DictionaryControllerService,
    DispatcherControllerService,
    DocumentControllerService,
    EnumControllerService,
    InvoiceControllerService,
    MonthlyReportControllerService,
    NotificationControllerService,
    PodControllerService,
    ConfigControllerService,
    UserControllerService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
