import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ToastMessage } from './toast-message';
import { CommonService } from '../../../common/common.service';

@Injectable({providedIn: "root"})
export class ToasterService {
  constructor(private messageService: MessageService, private commonService: CommonService) { }  
  public showToaster(toastMessage: ToastMessage){
    this.messageService.add(toastMessage);
  }
  
  private checkShowSpinner: boolean = false;
  
  public showSpinner(): void{
    this.checkShowSpinner = true;
    setTimeout(() => {      
      this.checkShowSpinner ? this.commonService.spinner = 'block': this.commonService.spinner = 'none';
    }, this.commonService.loaderDelay );
  }
  
  public hideSpinner(): void{
    this.checkShowSpinner = false;
    this.commonService.spinner = 'none';    
  }
}
