import { Component, OnInit, ViewChild, Input } from '@angular/core';

import { CommonService } from '../../common/common.service';
import { NgForm } from '@angular/forms';

import { ContractTypeEnum } from '../../common/enums/contract-type';
import { ContractControllerService } from '../../../model/api/contractController.service';
import { ContractDto } from '../../../model/model/contractDto';
import { DocumentControllerService } from '../../../model/api/documentController.service';
import { UpdateContractRequest } from '../../../model/model/updateContractRequest';
import { EnumControllerService } from '../../../model/api/enumController.service';
import { ContractTableComponent } from '../contract-table/contract-table.component';
import { TreeItem, LabelValue } from '../../../model/model/models';
import { PeriodTypeEnum } from '../../common/enums/periodType';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { TreeNode } from 'primeng/api';
import { CustomerTreeControllerService } from '../../../model/api/customerTreeController.service';
import { TreeUtils } from '../../common/tree.utils';
import { ToasterService } from '../shared/toaster/toaster.service';
import { CreateContractRequest } from '../../../model/model/createContractRequest';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-contract-manager',
  templateUrl: './contract-manager.component.html',
  styleUrls: ['./contract-manager.component.css'],
  inputs: ['visible'],
})
export class ContractManagerComponent implements OnInit {
  @ViewChild('pdfViewer', { static: true }) pdfViewer: PdfViewerComponent;
  @ViewChild('formSzerzodes', { static: true }) formSzerzodes: NgForm;

  public visible: boolean;

  public id: number;

  public hu: any;

  public pdfSrc: string;

  public uploadingDocumentFile: File;

  public uploadingDocumentFileName: string = 'Nincs fájl kiválasztva';

  public formValues: ContractDto;

  public turningDayDate: string;

  public providerOptions: LabelValue[] = [];

  public typeOptions: LabelValue[] = [];

  public podTypeOptions: LabelValue[] = [];

  public periodTypeOptions: LabelValue[] = [];

  public unitOptions: LabelValue[] = [];

  public currencyOptions: LabelValue[] = [];

  private customerId: number;

  public contractDataHeight: number;

  public inProgress: boolean = false;

  private subscriptions: Subscription[] = [];
  public windowHeight: number;
  tree: TreeNode[];

  podTree: TreeNode[] = [];

  _selectedTreeItem: TreeItem;
  _selectedPodTreeItem: TreeItem;

  constructor(
    private commonService: CommonService,
    private contractController: ContractControllerService,
    private documentController: DocumentControllerService,
    private enumService: EnumControllerService,
    private customerTreeService: CustomerTreeControllerService,
    private treeUtils: TreeUtils,
    private contractTable: ContractTableComponent,
    private toasterService: ToasterService
  ) {}

  @Input()
  set contractId(id: number) {
    this.id = id;
    this.pdfViewer.clear();
    if (this.id != undefined) {
      this.initContract(this.id);
    } else {
      this.pdfSrc = null;
      this.newContract();
    }
  }

  newContract() {
    this.formValues = <ContractDto>{};
    this.formValues.podType = 'ELECTRIC';
    this.formValues.podList = [];
    this.formValues.periodType = PeriodTypeEnum[PeriodTypeEnum.INDEFINITE];
    this.formValues.lastCall = false;
    this.changePodType();
  }

  initContract(id: number) {
    this.pdfSrc = null;
    this.subscriptions.push(
      this.contractController.loadContractUsingGET(id, this.commonService.getCurrentUser().token).subscribe((data) => {
        this.formValues = data.contract;
        this.customerId = this.formValues.customerId;
        this.changePodType();
        this.documentController
          .downloadUsingGET(data.contract.uuid, this.commonService.getCurrentUser().token, 'response')
          .subscribe((data) => {
            this.pdfSrc = URL.createObjectURL(data.body);
          });
      })
    );
  }

  ngOnInit() {
    this.formValues = <ContractDto>{};
    this.hu = this.commonService.datePickerLanguage;

    this.windowHeight = this.commonService.getModalHeight();

    this.initDropdowns();
    this.subscriptions.push(
      this.commonService.connector.subscribe((data) => {
        if (data != null && data.selectedTreeItem != null) {
          this.customerId = parseInt(data.selectedTreeItem.key);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  initDropdowns() {
    this.unitOptions = this.commonService.getBaseUnitOptions();

    this.subscriptions.push(
      this.enumService.listProvidersUsingGET(this.commonService.getCurrentUser().token).subscribe((resp) => {
        this.providerOptions = resp.labelValues;
      })
    );
    this.subscriptions.push(
      this.enumService.listPodTypesUsingGET(this.commonService.getCurrentUser().token).subscribe((resp) => {
        this.podTypeOptions = resp.labelValues;
      })
    );
    this.subscriptions.push(
      this.enumService.listPeriodTypesUsingGET(this.commonService.getCurrentUser().token).subscribe((resp) => {
        this.periodTypeOptions = resp.labelValues;
      })
    );
    this.subscriptions.push(
      this.enumService.listContractTypesUsingGET(this.commonService.getCurrentUser().token).subscribe((resp) => {
        this.typeOptions = resp.labelValues;
      })
    );
    this.subscriptions.push(
      this.enumService.listCurrenciesUsingGET(this.commonService.getCurrentUser().token).subscribe((resp) => {
        this.currencyOptions = resp.labelValues;
      })
    );
  }

  save(): void {
    if (!this.validate()) {
      return;
    }
    if (this.id == undefined || this.id == null) {
      this.saveNewContract();
    } else {
      this.saveExistContract();
    }
  }

  validate(): boolean {
    if (this.formValues.podType == null) {
      this.toasterService.showToaster({ severity: 'error', summary: 'A POD típus kitöltése kötelező', detail: '', life: 30000 });
      return false;
    }
    if (this.formValues.type == null) {
      this.toasterService.showToaster({ severity: 'error', summary: 'A szerződéstípus kitöltése kötelező', detail: '', life: 30000 });
      return false;
    }
    if (this.formValues.periodType == null) {
      this.toasterService.showToaster({ severity: 'error', summary: 'A szerződés fajtája kitöltése kötelező', detail: '', life: 30000 });
      return false;
    }
    if (this.formValues.provider == null) {
      this.toasterService.showToaster({ severity: 'error', summary: 'A szolgáltató kitöltése kötelező', detail: '', life: 30000 });
      return false;
    }
    if (this.formValues.unitPrice == null) {
      if (![ContractTypeEnum[ContractTypeEnum.HH], ContractTypeEnum[ContractTypeEnum.HCS]].includes(this.formValues.type)) {
        this.toasterService.showToaster({ severity: 'error', summary: 'Az egységár nem lehet üres', detail: '', life: 30000 });
        return false;
      }
    }
    if (this.formValues.currency == null) {
      if (![ContractTypeEnum[ContractTypeEnum.HH], ContractTypeEnum[ContractTypeEnum.HCS]].includes(this.formValues.type)) {
        this.toasterService.showToaster({ severity: 'error', summary: 'A deviza kitöltése kötelező', detail: '', life: 30000 });
        return false;
      }
    }
    if (this.formValues.unit == null) {
      if (![ContractTypeEnum[ContractTypeEnum.HH], ContractTypeEnum[ContractTypeEnum.HCS]].includes(this.formValues.type)) {
        this.toasterService.showToaster({ severity: 'error', summary: 'A mértékegység kitöltése kötelező', detail: '', life: 30000 });
        return false;
      }
    }
    if (this.formValues.contractStart == null) {
      this.toasterService.showToaster({ severity: 'error', summary: 'A szerződés kezdete nincs kitöltve', detail: '', life: 30000 });
      return false;
    }
    if (
      (this.formValues.periodType == 'EXPIRE' ||
        this.formValues.periodType == 'TURNING_DAY' ||
        this.formValues.periodType == 'INDEFINITE') &&
      this.formValues.contractStart != null &&
      this.formValues.contractEnd != null
    ) {
      if (this.formValues.contractStart > this.formValues.contractEnd) {
        this.toasterService.showToaster({
          severity: 'error',
          summary: 'Hibás dátum',
          detail: 'A szerződés kezdete nem lehet nagyobb mint a szerződés vége',
          life: 30000,
        });
        return false;
      }
    }
    if (this.formValues.periodType == 'TURNING_DAY' && this.formValues.terminationEnd != null && this.formValues.terminationStart != null) {
      if (this.formValues.terminationStart > this.formValues.terminationEnd) {
        this.toasterService.showToaster({
          severity: 'error',
          summary: 'Hibás dátum',
          detail: 'A felmodási időszak kezdete nem lehet nagyobb mint a felmodási időszak vége',
          life: 30000,
        });
        return false;
      }
    }
    if (this.formValues.periodType == 'TURNING_DAY' && this.formValues.terminationEnd != null && this.formValues.terminationStart == null) {
      if (this.formValues.terminationStart > this.formValues.terminationEnd) {
        this.toasterService.showToaster({
          severity: 'error',
          summary: 'Hibás dátum',
          detail: 'A felmodási időszak kezdete mező nincs kitöltve',
          life: 30000,
        });
        return false;
      }
    }
    return true;
  }

  saveNewContract(): void {
    let request: CreateContractRequest = <CreateContractRequest>{};
    request = this.formValues;
    request.customerId = this.customerId;
    this.subscriptions.push(
      this.contractController.createContractUsingPOST(request, this.commonService.getCurrentUser().token).subscribe((data) => {
        this.id = data.id;
        this.uploadDocumentForContract(this.id);
      })
    );
  }

  private finishSave() {
    this.contractTable.filter();
    this.visible = false;
    this.uploadingDocumentFile = null;
    this.uploadingDocumentFileName = null;
    this.formSzerzodes.resetForm();
  }

  saveExistContract(): void {
    let request: UpdateContractRequest = <UpdateContractRequest>{};
    request = this.formValues;
    request['uuid'] = undefined;
    request['warningActive'] = undefined;
    request['customerName'] = undefined;
    request['customerCode'] = undefined;
    this.subscriptions.push(
      this.contractController.modifyContractUsingPOST(request, this.commonService.getCurrentUser().token).subscribe(() => {
        this.uploadDocumentForContract(this.formValues.id);
        this.contractTable.filter();
        this.visible = false;
      })
    );
  }

  uploadDocumentForContract(contractID: number): void {
    if (this.uploadingDocumentFile != null) {
      this.subscriptions.push(
        this.contractController
          .uploadContractDocumentUsingPOST(this.uploadingDocumentFile, contractID, this.commonService.getCurrentUser().token)
          .subscribe(() => {
            this.finishSave();
          })
      );
    } else {
      this.finishSave();
    }
  }

  isDisableSave(): boolean {
    return !this.commonService.isAdminUser();
  }

  uploadFileChange(event: any): void {
    let target = event.target || event.srcElement;

    this.uploadingDocumentFile = target.files[0];
    this.uploadingDocumentFileName = this.uploadingDocumentFile.name;
    if (typeof FileReader !== 'undefined') {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        this.pdfSrc = e.target.result;
      };
      reader.readAsArrayBuffer(this.uploadingDocumentFile);
    }
    target.value = '';
  }

  changePodType(): void {
    this.unitOptions = this.commonService.getUnitOptionsByPodType(this.formValues.podType);
    this.loadPods();
  }
  // @ts-ignore
  changeType(event): void {
    if (
      this.formValues.type == ContractTypeEnum[ContractTypeEnum.HH] ||
      this.formValues.type == ContractTypeEnum[ContractTypeEnum.HCS] ||
      this.formValues.type == ContractTypeEnum[ContractTypeEnum.EGYETEMES]
    ) {
      this.formValues.periodType = PeriodTypeEnum[PeriodTypeEnum.INDEFINITE];
    }
  }

  isDisableField(fieldName: string): boolean {
    let result: boolean = false;
    switch (fieldName) {
      case 'periodType': //Fajta
        result = [
          ContractTypeEnum[ContractTypeEnum.HH],
          ContractTypeEnum[ContractTypeEnum.HCS],
          ContractTypeEnum[ContractTypeEnum.EGYETEMES],
        ].includes(this.formValues.type);
        break;
      case 'unitPrice': //Egységár
        result = [ContractTypeEnum[ContractTypeEnum.HH], ContractTypeEnum[ContractTypeEnum.HCS]].includes(this.formValues.type);
        break;
      case 'moleculePrice': //Molekula díj
        result = [ContractTypeEnum[ContractTypeEnum.HH], ContractTypeEnum[ContractTypeEnum.HCS]].includes(this.formValues.type);
        break;
      case 'energyPrice': //Teljesítmény díj
        result = [ContractTypeEnum[ContractTypeEnum.HH], ContractTypeEnum[ContractTypeEnum.HCS]].includes(this.formValues.type);
        break;
      case 'trafficPrice': //Forgalmi díj
        result = [ContractTypeEnum[ContractTypeEnum.HH], ContractTypeEnum[ContractTypeEnum.HCS]].includes(this.formValues.type);
        break;
      case 'lastCall': //Last call
        result = [ContractTypeEnum[ContractTypeEnum.EGYETEMES]].includes(this.formValues.type);
        break;
      case 'top': //Top
        result = [ContractTypeEnum[ContractTypeEnum.EGYETEMES]].includes(this.formValues.type);
        break;
      default:
        result = false;
    }
    return result;
  }

  public loadPods() {
    this.subscriptions.push(
      this.customerTreeService
        .getTreeWithPodsUsingGET(this.formValues.podType, this.customerId, this.commonService.getCurrentUser().token)
        .subscribe((response) => {
          
          this.tree = response.items;
          this.treeUtils.expandTree(this.tree);
          this.podTree = [];
          this.formValues.podList.forEach((podId) => {
            let treeItem = this.treeUtils.findTreeItemByPodId(podId, this.tree, null);
            this.addTreeItem(treeItem);
          });
        })
    );
  }

  addAllTreeItem() {
    let allPods: TreeItem[] = [];
    this.tree.map((item) => {
      allPods = [...allPods, ...this.getPods(item)];
    });
    
    allPods.forEach((element) => {
      this.addTreeItem(element);
    });
  }

  addTreeItem(treeItem: TreeItem) {
    if (treeItem == null) {
      return;
    }
    //clone + copy
    let clonedItem = this.treeUtils.cloneParent(treeItem);

    clonedItem.children = treeItem.children;
    let root: TreeNode = this.treeUtils.findRoot(clonedItem);
    this.treeUtils.merge(this.podTree, root);

    //delete original
    this.deleteTreeItem(treeItem, this.tree);
    this.formValues.podList.push(treeItem.podId);
    this.treeUtils.expandTree(this.podTree);
    this._selectedTreeItem = null;
  }

  removeAllTreeItem() {
    let allPods: TreeItem[] = [];
    this.podTree.map((item) => {
      allPods = [...allPods, ...this.getPods(item)];
    });
    
    allPods.forEach((element) => {
      this.removeTreeItem(element);
    });

  }

  removeTreeItem(treeItem: any) {
    if (treeItem == null) {
      return;
    }
    //clone + copy
    let clonedItem = this.treeUtils.cloneParent(treeItem);
    clonedItem.children = treeItem.children;
    let root: TreeNode = this.treeUtils.findRoot(clonedItem);
    this.treeUtils.merge(this.tree, root);

    //delete original
    this.deleteTreeItem(treeItem, this.podTree);
   
    this.formValues.podList = this.formValues.podList.filter((item)=>{return item  != treeItem.podId})
/*
    const index = this.formValues.podList.indexOf(treeItem.podId, 0);
    if (index > -1) {
      this.formValues.podList.splice(index, 1);
    }
*/
    this.treeUtils.expandTree(this.tree);
    this._selectedPodTreeItem = null;
  }

  deleteTreeItem(item: TreeNode, tree: TreeNode[]) {
    let totalTree = tree;
    if (item.parent != null) {
      tree = item.parent.children;
    }
    const index: number = tree.indexOf(item);
    if (index !== -1) {
      tree.splice(index, 1);
      if (tree.length == 0 && item.parent != null) {
        this.deleteTreeItem(item.parent, totalTree);
      }
    }
  }

  zoom_in() {
    this.commonService.zoom_in();
  }

  zoom_out() {
    this.commonService.zoom_out();
  }

  getZoom_to(): number {
    return this.commonService.zoom_to;
  }

  hasPodTree() {
    return this.podTree.length > 0;
  }

  hasTree() {
    return this.tree.length > 0;
  }

  getPods(item: TreeItem): TreeItem[] {
    let pods: TreeItem[] = [];
    if (item.children != null && item.children.length > 0) {
      if (this.isPod(item.children[0])) {
        pods = [...pods, ...item.children];
      } else {
        item.children.forEach((element) => {
          pods = [...pods, ...this.getPods(element)];
        });
      }
    }
    return pods;
  }

  isPod(item: any): boolean {
    return this.commonService.isPod(item);
  }
  getMaxUploadFileSize(): number {
    return this.commonService.maxUploadFileSize;
  }
}
