import { Component, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { Calendar } from 'primeng/calendar';


@Component({
    selector: "range-calendar",
    template: `
    <p-calendar #_calendar dataType="string" showButtonBar="true" class="ui-g-12" [style]="{'width':'100%','min-width':'100%'}" [locale]="calendarLocale" 
            [panelStyle]="{'width':'870px'}" dateFormat="yy-mm-dd" [monthNavigator]="false" [yearNavigator]="false" yearRange="2000:2030" appendTo="body" 
            [readonlyInput]="true" selectionMode="range" (onSelect)="select($event)" (onClearClick)="clearClick($event)" (onTodayClick)="todayClick($event)" [numberOfMonths]=3 [(ngModel)]="model">
    </p-calendar>
    `
  })

export class RangeCalendar  {
    public calendarLocale = {
        firstDayOfWeek: 0,
        dayNames: [ "hétfő", "kedd", "szerda", "csütrötök", "péntek", "szombat", "vasárnap"],
        dayNamesShort: ["Hét", "Ke", "Sze", "Csüt", "Pén", "Szo", "Vas"],
        dayNamesMin: ["H", "K", "S", "C", "P", "S", "V"],
        monthNames: [ "január", "február", "március", "április", "május", "június", "július", "augusztus", "szeptember", "október", "november", "december" ],
        monthNamesShort: [ "jan", "feb", "már", "apr", "máj", "jún", "júl", "aug", "szep", "okt", "nov", "dec" ],
        today: 'Ma',
        clear: 'Törlés',
        weekHeader: 'Hét'
      };
    
    constructor() { }

    @ViewChild('_calendar', { static: false }) _calendar:Calendar;

    model: any;

    @Input() value: any;

    @Output() valueChange: EventEmitter<any> = new EventEmitter();

    @Output() onSelect: EventEmitter<any> = new EventEmitter();

    @Output() onClearClick: EventEmitter<any> = new EventEmitter();
    
    @Output() onTodayClick: EventEmitter<any> = new EventEmitter();

    select(event) {
      this.value = this.model;
      this.valueChange.emit(this.value);
      this.onSelect.emit(event);
      if (this.value[1] != null) {
        this._calendar.hideOverlay();
      }
    }

    clearClick(event) {
      this.value = this.model;
      this.valueChange.emit(this.value);
      this.onClearClick.emit(event);
    }

    todayClick(event) {
      this.value = this.model;
      this.valueChange.emit(this.value);
      this.onTodayClick.emit(event);
    }

    clear() {
      this._calendar.updateModel(null);
      this._calendar.updateInputfield();
      this._calendar.hideOverlay();
    }

}