import { Component, OnInit, Input } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { UserControllerService } from '../../../../model/api/userController.service';
import { ListUserRequest } from '../../../../model/model/listUserRequest';
import { WorkflowControllerService } from '../../../../model/api/workflowController.service';
import { StatePodConfigDto } from '../../../../model/model/statePodConfigDto';
import { StateUsersDto } from '../../../../model/model/stateUsersDto';
import { UserDto } from '../../../../model/model/userDto';
import { WeightUserDto } from '../../../../model/model/weightUserDto';
import { CommonService } from '../../../common/common.service';
import { PodControllerService } from '../../../../model/api/podController.service';



@Component({
  selector: 'workflow-editor',
  templateUrl: './workflow-editor.component.html',
  styleUrls: ['./workflow-editor.component.css'],
  providers: [MessageService],
  inputs: ['visible']
})
export class WorkflowEditorComponent implements OnInit {

  private subscriptions: Subscription[] = [];

  public visible: boolean;

  public configDto: StatePodConfigDto;

  public podName : string;

  public users : UserDto[] = [];

  public selectedUser : UserDto[] = [];

  public errorMessage: string;
  
  constructor(
    private userService : UserControllerService,
    private commonService : CommonService,
    private workflowService: WorkflowControllerService,
    private podService: PodControllerService,
    ) {
  }

  @Input()
  set config(configDto: StatePodConfigDto) {
    this.configDto = configDto;
    this.selectedUser  = [];
    this.configDto.states.forEach(this.fillDummyUsers);
    this.getPodInfo();
    this.listAvailableUsers();
    
  }

  buildListUsersRequest(): ListUserRequest {
    let request: ListUserRequest = {
      sortField: 'name',
      direction: 1,
      treeId: this.configDto.treeId,
    };
    return request;
  }

  listAvailableUsers() : void {
    this.subscriptions.push(
      this.userService.listUsersUsingPOST(this.buildListUsersRequest(), this.commonService.getCurrentUser().token).subscribe(
        (data) => {
          this.users = data.usersPage;
        })
    );
  }

  getPodInfo() : void {
    if (this.configDto.podCount==1) {
      this.subscriptions.push(
        this.podService.getPodUsingGET(this.configDto.podId, this.commonService.getCurrentUser().token).subscribe(resp => {
          this.podName = resp.pod.name;
        })
      );
    }
    
  }

  ngOnInit() {
    this.configDto =<StatePodConfigDto>{};
    this.configDto.states = [];
  }

  ngOnDestroy(){
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  addUser(state : StateUsersDto, user:UserDto) {
    let weightUser = <WeightUserDto>{};
    weightUser.id = user.id;
    weightUser.email = user.email;
    weightUser.name =user.name;
    weightUser.weight = 1;

    if (state.users && state.users.findIndex(item => item.email === weightUser.email) != -1) {
      return;
    }
    state.users ? state.users.push(weightUser) : (state.users = [weightUser]);
    this.removeDummyUsers(state);
    this.fillDummyUsers(state);
  }

  removeUser(state:StateUsersDto, user:WeightUserDto) {
    state.users = [...state.users.filter((item) => item != user)];
  }

  

  save() :void{
    this.configDto.states.forEach(this.removeDummyUsers);
    this.subscriptions.push(
      this.workflowService.saveConfigUsingPOST(this.configDto, this.commonService.getCurrentUser().token).subscribe(data => {
          data;
          this.visible = false;
        })
    );
  }

  fillDummyUsers(state:StateUsersDto) {
    if (state.users.length<5) {
      for (let i = state.users.length; i < 5; i++) {
        state.users.push({});
      }
    }
  }

  removeDummyUsers(state:StateUsersDto) {
    state.users = [...state.users.filter((u) => u.id)]
  }

  isDisable() {
    return this.configDto.states.length==0;
  }

}
