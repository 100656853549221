/**
 * OLC System
 * Ügyfél és számlakezelő rendszer
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpEvent }  from '@angular/common/http';
import { Observable }                                        from 'rxjs';
import { AddMontlyReportCommentRequest } from '../model/addMontlyReportCommentRequest';
import { ListMonthlyReportMonthResponse } from '../model/listMonthlyReportMonthResponse';
import { MonthlyReportDto } from '../model/monthlyReportDto';
import { MonthlyReportGroupDto } from '../model/monthlyReportGroupDto';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { environment } from '../../../environments/environment';
import { SendReportRequest } from '../model/sendReportRequest';

@Injectable()
export class MonthlyReportControllerService {

    protected basePath = environment.apiBaseUrl + '/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * addComment
     * 
     * @param body request
     * @param monthlyReportId monthlyReportId
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addCommentUsingPOST(body: AddMontlyReportCommentRequest, monthlyReportId: number, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public addCommentUsingPOST(body: AddMontlyReportCommentRequest, monthlyReportId: number, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public addCommentUsingPOST(body: AddMontlyReportCommentRequest, monthlyReportId: number, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public addCommentUsingPOST(body: AddMontlyReportCommentRequest, monthlyReportId: number, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addCommentUsingPOST.');
        }

        if (monthlyReportId === null || monthlyReportId === undefined) {
            throw new Error('Required parameter monthlyReportId was null or undefined when calling addCommentUsingPOST.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/monthlyReport/addComment/${encodeURIComponent(String(monthlyReportId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * addCustomerComment
     * 
     * @param body request
     * @param customerId customerId
     * @param period period
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addCustomerCommentUsingPOST(body: AddMontlyReportCommentRequest, customerId: number, period: string, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public addCustomerCommentUsingPOST(body: AddMontlyReportCommentRequest, customerId: number, period: string, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public addCustomerCommentUsingPOST(body: AddMontlyReportCommentRequest, customerId: number, period: string, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public addCustomerCommentUsingPOST(body: AddMontlyReportCommentRequest, customerId: number, period: string, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addCustomerCommentUsingPOST.');
        }

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling addCustomerCommentUsingPOST.');
        }

        if (period === null || period === undefined) {
            throw new Error('Required parameter period was null or undefined when calling addCustomerCommentUsingPOST.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/monthlyReport/addCustomerComment/${encodeURIComponent(String(period))}/${encodeURIComponent(String(customerId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * generateExcel
     * 
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generateExcelUsingGET(authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public generateExcelUsingGET(authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public generateExcelUsingGET(authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public generateExcelUsingGET(authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<any>(`${this.basePath}/monthlyReport/generate/excel`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * generateReports
     * 
     * @param period period
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generateReportsUsingGET(period: string, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public generateReportsUsingGET(period: string, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public generateReportsUsingGET(period: string, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public generateReportsUsingGET(period: string, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (period === null || period === undefined) {
            throw new Error('Required parameter period was null or undefined when calling generateReportsUsingGET.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<any>(`${this.basePath}/monthlyReport/generate/${encodeURIComponent(String(period))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getReportGroups
     * 
     * @param period period
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReportGroupsUsingGET(period: string, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<MonthlyReportGroupDto>>;
    public getReportGroupsUsingGET(period: string, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MonthlyReportGroupDto>>>;
    public getReportGroupsUsingGET(period: string, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MonthlyReportGroupDto>>>;
    public getReportGroupsUsingGET(period: string, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (period === null || period === undefined) {
            throw new Error('Required parameter period was null or undefined when calling getReportGroupsUsingGET.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<Array<MonthlyReportGroupDto>>(`${this.basePath}/monthlyReport/reports/groups/${encodeURIComponent(String(period))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getReports
     * 
     * @param customerId customerId
     * @param period period
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReportsUsingGET(customerId: number, period: string, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<MonthlyReportDto>>;
    public getReportsUsingGET(customerId: number, period: string, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MonthlyReportDto>>>;
    public getReportsUsingGET(customerId: number, period: string, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MonthlyReportDto>>>;
    public getReportsUsingGET(customerId: number, period: string, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling getReportsUsingGET.');
        }

        if (period === null || period === undefined) {
            throw new Error('Required parameter period was null or undefined when calling getReportsUsingGET.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<Array<MonthlyReportDto>>(`${this.basePath}/monthlyReport/reports/${encodeURIComponent(String(period))}/${encodeURIComponent(String(customerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * listIssueDates
     * 
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listIssueDatesUsingGET(authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<ListMonthlyReportMonthResponse>;
    public listIssueDatesUsingGET(authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListMonthlyReportMonthResponse>>;
    public listIssueDatesUsingGET(authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListMonthlyReportMonthResponse>>;
    public listIssueDatesUsingGET(authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<ListMonthlyReportMonthResponse>(`${this.basePath}/monthlyReport/listIssueDates`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


    /**
     * sendSelectedReports
     * 
     * @param body reportRequest
     * @param period period
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendReportsUsingPOST(body: SendReportRequest, period: string, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public sendReportsUsingPOST(body: SendReportRequest, period: string, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public sendReportsUsingPOST(body: SendReportRequest, period: string, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public sendReportsUsingPOST(body: SendReportRequest, period: string, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling sendSelectedReportsUsingPOST.');
        }

        if (period === null || period === undefined) {
            throw new Error('Required parameter period was null or undefined when calling sendSelectedReportsUsingPOST.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/monthlyReport/send/${encodeURIComponent(String(period))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
