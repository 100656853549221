import {
  Component,
  OnInit,
  Input,
  ViewChild,
  EventEmitter,
  Output,
  HostListener,
  ElementRef,
} from "@angular/core";
import { Calendar } from "primeng/calendar";

@Component({
  selector: "period-input",
  templateUrl: "./period-input.component.html",
  styleUrls: ["./period-input.component.css"],
})
export class PeriodInputComponent implements OnInit {
  @Input()
  placeholder: string = "placeholder";

  @ViewChild("startCalendar", { static: false }) startCalendar: Calendar;
  @ViewChild("endCalendar", { static: false }) endCalendar: Calendar;

  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Output() onSelect: EventEmitter<any> = new EventEmitter();

  valuestr: string = "";
  @Input() value: any;


  public needTransform: boolean= false;

  constructor(private eRef: ElementRef) {}
  showCalendars: boolean = false;
  startDate: string = null;
  endDate: string = null;

  public calendarLocale = {
    firstDayOfWeek: 0,
    dayNames: [
      "hétfő",
      "kedd",
      "szerda",
      "csütrötök",
      "péntek",
      "szombat",
      "vasárnap",
    ],
    dayNamesShort: ["Hét", "Ke", "Sze", "Csüt", "Pén", "Szo", "Vas"],
    dayNamesMin: ["H", "K", "S", "C", "P", "S", "V"],
    monthNames: [
      "január",
      "február",
      "március",
      "április",
      "május",
      "június",
      "július",
      "augusztus",
      "szeptember",
      "október",
      "november",
      "december",
    ],
    monthNamesShort: [
      "jan",
      "feb",
      "már",
      "apr",
      "máj",
      "jún",
      "júl",
      "aug",
      "szep",
      "okt",
      "nov",
      "dec",
    ],
    today: "Ma",
    clear: "Törlés",
    weekHeader: "Hét",
  };

  ngOnInit() {}
  //@ts-ignore
  clearClick($event) {
    this.valuestr = "";
    this.startDate = null;
    this.endDate = null;
    this.setValue();
    this.closeClick(null);
  }
  //@ts-ignore
  selectStart($event) {
    if (
      this.endCalendar.value &&
      this.startCalendar.value &&
      !(this.startCalendar.value > this.endCalendar.value)
    ) {
      this.setValue();
    } else if (this.startCalendar.value && !this.endCalendar.value) {
      this.setValue();
    } else {
      this.startDate = null;
      this.startCalendar.updateModel(null);
      this.startCalendar.updateInputfield();
      this.startCalendar.hideOverlay();
      this.setValue();
    }
  }

  //@ts-ignore
  selectEnd($event) {
    if (
      this.endCalendar.value &&
      this.startCalendar.value &&
      !(this.startCalendar.value > this.endCalendar.value)
    ) {
      this.setValue();
    } else if (this.endCalendar.value && !this.startCalendar.value) {
      this.setValue();
    } else {
      this.endDate = null;
      this.endCalendar.updateModel(null);
      this.endCalendar.updateInputfield();
      this.endCalendar.hideOverlay();
      this.setValue();
    }
  }
  //@ts-ignore
  closeClick($event) {
    this.showCalendars = false;
  }
  //@ts-ignore
  startDateClearClick($event) {
    this.startDate = null;
    this.setValue();
  }

  //@ts-ignore
  endDateClearClick($event) {
    this.endDate = null;
    this.setValue();
  }

  setValue() {
    if (!this.startDate && !this.endDate) {
      this.valuestr = "";
    } else if (this.startDate && !this.endDate) {
      this.valuestr = `${this.startDate} - `;
    } else if (!this.startDate && this.endDate) {
      this.valuestr = ` - ${this.endDate}`;
    } else if (this.startDate && this.endDate) {
      this.valuestr = `${this.startDate} - ${this.endDate}`;
    }

    this.valueChange.emit([this.startDate, this.endDate]);
    this.onSelect.emit([this.startDate, this.endDate]);
    if (this.endDate && this.startDate) this.closeClick(null);
  }

  inputClick($event) {
    this.showCalendars = true;
    this.needTransform = $event.clientX > 500;    
  }

  clear() {
    this.valuestr = "";
    this.startDate = null;
    if (this.startCalendar) {
      this.startCalendar.updateModel(null);
      this.startCalendar.updateInputfield();
      this.startCalendar.hideOverlay();
    }
    this.endDate = null;
    if (this.endCalendar) {
      this.endCalendar.updateModel(null);
      this.endCalendar.updateInputfield();
      this.endCalendar.hideOverlay();
    }
  }

  @HostListener("document:click", ["$event"])
  clickOutSide(event) {
    if (this.eRef.nativeElement.contains(event.target)) {
    } else {
      this.showCalendars = false;
    }
  }
}
