import { Component, OnInit, Input } from "@angular/core";
import { ProviderIntegrationDto } from "src/app/model/model/providerIntegrationDto";
import { LabelValue } from "src/app/model/model/labelValue";
import { CommonService } from "../../common/common.service";
import { Subscription } from "rxjs";
import { ProviderIntegrationControllerService } from "src/app/model";
@Component({
  selector: "provider-integration-manager",
  templateUrl: "./provider-integration-manager.component.html",
  styleUrls: ["./provider-integration-manager.component.css"],
})
export class ProviderIntegrationManagerComponent implements OnInit {
  @Input()
  providerOptions: LabelValue[] = [];

  @Input()
  customerOptions: LabelValue[] = [];

  //public newItem: boolean = false;
  //public updateItem: boolean = false;
  //public selectProviderIntegrationID: number;

  public cols: any;
  public formValue: ProviderIntegrationDto | any = {};
  hu: any;
  displayListContract: boolean = false;
  private subscriptions: Subscription[] = [];
  public filteredContracts: any[] = [];
  public selectedContracts: any[] = [];
  public contractIds: any[] = [];
  public extraInfo: string;
  private setTableCols() {
    this.cols = [
      {
        field: "customerCode",
        header: "Vevőkód",
        size: "250px",
        align: "left",
      },
      {
        field: "operations",
        header: "Műveletek",
        size: "100px",
        align: "center",
      },
    ];
  }

  selectContract() {
    this.getLabelValueExtra();
  }

  getLabelValueExtra() {
    this.subscriptions.push(
      this.providerIntegrationControllerService
        .getProvidersUsingGET(this.commonService.getCurrentUser().token)
        .subscribe((item) => {
          this.extraInfo = item.find(
            (element) => element.value === this.formValue.provider
          ).info;
        })
    );
  }

  addCustomerCode(customerCode) {
    if (!customerCode.value) return;
    const value = customerCode.value;
    customerCode.value = "";

    if (
      this.formValue.customerCodes &&
      this.formValue.customerCodes.indexOf(value) != -1
    )
      return;

    this.formValue.customerCodes
      ? this.formValue.customerCodes.push(value)
      : (this.formValue.customerCodes = [value]);
  }

  removeCustomerCode(customerCode) {
    this.formValue.customerCodes = [
      ...this.formValue.customerCodes.filter((item) => item != customerCode),
    ];
  }

  constructor(
    private providerIntegrationControllerService: ProviderIntegrationControllerService,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    this.hu = this.commonService.datePickerLanguage;
    this.setTableCols();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  isDisableSaveButton(): boolean {
    if (!this.formValue.provider) return true;
    return (
      !this.formValue.provider ||
      !this.formValue.customerId ||
      !this.formValue.password ||
      !this.formValue.username ||
      !this.formValue.downloadStart ||
      !this.formValue.downloadEnd || 
      !this.formValue.customerCodes ||
      this.formValue.customerCodes.length == 0
    );
  }
}
