/**
 * OLC System
 * Ügyfél és számlakezelő rendszer
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateMeterRequest } from '../model/createMeterRequest';
import { ListMeterRequest } from '../model/listMeterRequest';
import { ListMeterValuesResponse } from '../model/listMeterValuesResponse';
import { MeterDto } from '../model/meterDto';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { environment } from '../../../environments/environment';
import { MeterValueRespone } from '../model/meterValueRespone';
import { MeterValueRequest } from '../model/meterValueRequest';


@Injectable()
export class MeterControllerService {

    protected basePath = environment.apiBaseUrl + '/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * activateMeter
     * 
     * @param meterId meterId
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public activateMeterUsingGET(meterId: number, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public activateMeterUsingGET(meterId: number, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public activateMeterUsingGET(meterId: number, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public activateMeterUsingGET(meterId: number, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (meterId === null || meterId === undefined) {
            throw new Error('Required parameter meterId was null or undefined when calling activateMeterUsingGET.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<any>(`${this.basePath}/meter/activateMeter/${encodeURIComponent(String(meterId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**

     * addMeter
     * 
     * @param body request
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addMeterUsingPOST(body: CreateMeterRequest, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public addMeterUsingPOST(body: CreateMeterRequest, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public addMeterUsingPOST(body: CreateMeterRequest, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public addMeterUsingPOST(body: CreateMeterRequest, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addMeterUsingPOST.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/meter/addMeter`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * addMeterValuePhoto
     * 
     * @param file 
     * @param authorization JWT Authorization token
     * @param meterValueId 
     * @param type 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addMeterValuePhotoUsingPOST(file: Blob, authorization?: string, meterValueId?: number, type?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public addMeterValuePhotoUsingPOST(file: Blob, authorization?: string, meterValueId?: number, type?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public addMeterValuePhotoUsingPOST(file: Blob, authorization?: string, meterValueId?: number, type?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public addMeterValuePhotoUsingPOST(file: Blob, authorization?: string, meterValueId?: number, type?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling addMeterValuePhotoUsingPOST.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (meterValueId !== undefined && meterValueId !== null) {
            queryParameters = queryParameters.set('meterValueId', <any>meterValueId);
        }
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);






        }

        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.post<string>(`${this.basePath}/meter/addMeterValuePhoto`,

            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * addMeterValue
     * 
     * @param body request
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addMeterValueUsingPOST(body: MeterValueRequest, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<MeterValueRespone>;
    public addMeterValueUsingPOST(body: MeterValueRequest, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MeterValueRespone>>;
    public addMeterValueUsingPOST(body: MeterValueRequest, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MeterValueRespone>>;
    public addMeterValueUsingPOST(body: MeterValueRequest, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addMeterValueUsingPOST.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MeterValueRespone>(`${this.basePath}/meter/addMeterValue`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteMeter
     * 
     * @param meterId meterId
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteMeterUsingGET(meterId: number, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteMeterUsingGET(meterId: number, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteMeterUsingGET(meterId: number, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteMeterUsingGET(meterId: number, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (meterId === null || meterId === undefined) {
            throw new Error('Required parameter meterId was null or undefined when calling deleteMeterUsingGET.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<any>(`${this.basePath}/meter/deleteMeter/${encodeURIComponent(String(meterId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteMeterValue
     * 
     * @param id id
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteMeterValueUsingGET(id: number, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteMeterValueUsingGET(id: number, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteMeterValueUsingGET(id: number, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteMeterValueUsingGET(id: number, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteMeterValueUsingGET.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get<any>(`${this.basePath}/meter/deleteMeterValue/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * editMeter
     * 
     * @param body request
     * @param meterId meterId
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public editMeterUsingPOST(body: CreateMeterRequest, meterId: number, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public editMeterUsingPOST(body: CreateMeterRequest, meterId: number, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public editMeterUsingPOST(body: CreateMeterRequest, meterId: number, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public editMeterUsingPOST(body: CreateMeterRequest, meterId: number, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling editMeterUsingPOST.');
        }

        if (meterId === null || meterId === undefined) {
            throw new Error('Required parameter meterId was null or undefined when calling editMeterUsingPOST.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/meter/editMeter/${encodeURIComponent(String(meterId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * exportMeterValues
     * 
     * @param meterId meterId
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportMeterValuesUsingGET(meterId: number, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public exportMeterValuesUsingGET(meterId: number, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public exportMeterValuesUsingGET(meterId: number, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public exportMeterValuesUsingGET(meterId: number, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (meterId === null || meterId === undefined) {
            throw new Error('Required parameter meterId was null or undefined when calling exportMeterValuesUsingGET.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            //'application/octet-stream'
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get<any>(`${this.basePath}/meter/exportMeterValues/${encodeURIComponent(String(meterId))}`,
            {  responseType: 'blob' as 'json',
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * inactivateMeter
     * 
     * @param meterId meterId
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inactivateMeterUsingGET(meterId: number, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public inactivateMeterUsingGET(meterId: number, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public inactivateMeterUsingGET(meterId: number, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public inactivateMeterUsingGET(meterId: number, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (meterId === null || meterId === undefined) {
            throw new Error('Required parameter meterId was null or undefined when calling inactivateMeterUsingGET.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<any>(`${this.basePath}/meter/inactivateMeter/${encodeURIComponent(String(meterId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * listMeterValues
     * 
     * @param meterId meterId
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listMeterValuesUsingGET(meterId: number, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<ListMeterValuesResponse>;
    public listMeterValuesUsingGET(meterId: number, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListMeterValuesResponse>>;
    public listMeterValuesUsingGET(meterId: number, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListMeterValuesResponse>>;
    public listMeterValuesUsingGET(meterId: number, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (meterId === null || meterId === undefined) {
            throw new Error('Required parameter meterId was null or undefined when calling listMeterValuesUsingGET.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<ListMeterValuesResponse>(`${this.basePath}/meter/listMeterValues/${encodeURIComponent(String(meterId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * listMeters
     * 
     * @param body request
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listMetersUsingPOST(body: ListMeterRequest, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<MeterDto>>;
    public listMetersUsingPOST(body: ListMeterRequest, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MeterDto>>>;
    public listMetersUsingPOST(body: ListMeterRequest, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MeterDto>>>;
    public listMetersUsingPOST(body: ListMeterRequest, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling listMetersUsingPOST.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<MeterDto>>(`${this.basePath}/meter/listMeters`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
