/**
 * OLC System
 * Ügyfél és számlakezelő rendszer
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpEvent }  from '@angular/common/http';
import { Observable }                                        from 'rxjs';
import { LabelValueResponse } from '../model/labelValueResponse';
import { BASE_PATH}                     from '../variables';
import { Configuration }                                     from '../configuration';
import { environment } from '../../../environments/environment';


@Injectable()
export class DictionaryControllerService {

    protected basePath = environment.apiBaseUrl + '/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }


    /**
     * add
     * 
     * @param ocrText ocrText
     * @param value value
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addUsingGET(ocrText: string, value: string, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<LabelValueResponse>;
    public addUsingGET(ocrText: string, value: string, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LabelValueResponse>>;
    public addUsingGET(ocrText: string, value: string, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LabelValueResponse>>;
    public addUsingGET(ocrText: string, value: string, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ocrText === null || ocrText === undefined) {
            throw new Error('Required parameter ocrText was null or undefined when calling addUsingGET.');
        }

        if (value === null || value === undefined) {
            throw new Error('Required parameter value was null or undefined when calling addUsingGET.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<LabelValueResponse>(`${this.basePath}/dictionary/add/${encodeURIComponent(String(ocrText))}/${encodeURIComponent(String(value))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
