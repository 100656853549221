import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  EnumControllerService,
  LabelValue,
  LabelValueExtra,
  LabelValueResponse,
  ProviderIntegrationControllerService,
} from 'src/app/model';
import { ProviderIntegrationMessageDto } from 'src/app/model/model/providerIntegrationMessageDto';
import { ProviderIntegrationMessageListResponse } from 'src/app/model/model/providerIntegrationMessageListResponse';
import { CommonService } from 'src/app/modules/common/common.service';

@Component({
  selector: 'provider-integration-messages',
  templateUrl: './provider-integration-message.component.html',
  styleUrls: ['./provider-integration-message.component.css'],
})
export class ProviderIntegrationMessagesComponent implements OnInit, OnDestroy {
  constructor(
    private commonService: CommonService,
    private confirmationService: ConfirmationService,

    private enumControllerService: EnumControllerService,
    private providerIntegrationControllerService: ProviderIntegrationControllerService
  ) {}

  private messageStatusOptions: LabelValue[] = [];
  public providerOptions: LabelValueExtra[] = [];
  private subscriptions: Subscription[] = [];
  providerIntegrationMessages: ProviderIntegrationMessageDto[] = [];
  selectedProviderIntegrationMessage: ProviderIntegrationMessageDto;
  public cols: any;
  public currentInc: number = 0;

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  private setTableCols() {
    this.cols = [
      {
        field: 'provider',
        header: 'Szolgáltató',
        size: '250px',
        align: 'left',
      },
      {
        field: 'username',
        header: 'Felhasználó',
        size: '150px',
        align: 'left',
      },
      {
        field: 'periodStart',
        header: 'Letöltési időszak',
        size: '100px',
        align: 'center',
      },

      {
        field: 'processStart',
        header: 'Folyamat ideje', //egy oszlopba
        size: '250px',
        align: 'center',
      },
      {
        field: 'status',
        header: 'Állapot',
        size: '150px',
        align: 'left',
      },

      {
        field: 'result',
        header: 'Eredmény',
        size: '600px',
        align: 'left',
      },
      {
        field: 'operations',
        header: 'Műveletek',
        size: '100px',
        align: 'center',
      },
    ];
  }

  ngOnInit() {
    this.setTableCols();
    this.currentInc = 0;
    //(this.paginator.getPage() || 0) * this.commonService.tablePageSize;
    this.getList();
    this.initDropDowns();
  }
  getList() {
    this.subscriptions.push(
      this.providerIntegrationControllerService
        .listMessagesUsingGET(this.commonService.getCurrentUser().token)
        .subscribe((data: ProviderIntegrationMessageListResponse) => {
          this.providerIntegrationMessages = [...data.waitingMessages, ...data.runningMessage, ...data.finishedMessages,  ];
    })
    );
  }
  
  initDropDowns() {
    this.subscriptions.push(
      this.enumControllerService
        .listProviderIntegrationMessageStatusUsingGET(this.commonService.getCurrentUser().token)
        .subscribe((data: LabelValueResponse) => {
          this.messageStatusOptions = data.labelValues;
        })
    );

    this.subscriptions.push(
      this.providerIntegrationControllerService
        .getProvidersUsingGET(this.commonService.getCurrentUser().token)
        .subscribe((resp) => {
          this.providerOptions = resp;
        })
    );
  }

  getTableHeightInpx(): string {
    return this.commonService.getTableHeight() + 110 + 'px';
  }

  findLabel(value: string, toWhat: string): string {
    let result: string = '';
    if (toWhat == 'provider') {
      result = this.commonService.findLabel(this.providerOptions, value);
    } else if (toWhat == 'status') {
      result = this.commonService.findLabel(this.messageStatusOptions, value);
    }
    return result;
  }

  cancelMessage(rowData: any) {
    this.confirmationService.confirm({
      message: `Szeretné megszakítani a kijelölt folyamatot?`,
      accept: () => {
        this.providerIntegrationControllerService
          .cancelMessageUsingGET(rowData.uuid, this.commonService.getCurrentUser().token)
          .pipe(take(1))
          .subscribe(() => {
            this.getList();
          });
      },
    });
  }
}
