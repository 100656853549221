export enum TABLE_SELECTOR {
    INVOICES,
    DOCUMENTS,
    CONTRACTS,
    LOG,
    MONTHLY_REPORT,
    USERS,
    NOTIFICATIONS,
    INVOICE_PROCESSING,
    PODS,
    METERVALUE,
    CONFIG,
    PROVIDER_INTEGRATION,
    CONTROL_PANEL
    
}