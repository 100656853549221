import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginControllerService } from './service/loginController.service';
import { CommonService } from '../../common/common.service';
import { JwtToken } from 'src/app/model/model/client/jwt-token';
import * as jwt_decode from 'jwt-decode';
import { UserControllerService } from '../../../model/api/userController.service';
import { User } from 'src/app/model/model/client/user';
import { Subscription } from 'rxjs';
import { ToasterService } from '../shared/toaster/toaster.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})

export class LoginComponent implements OnInit, OnDestroy {
  public password: string;
  public userName: string;
  public email: string;
  public displayForgetPassword: boolean = false;

  @ViewChild('form', { static: false }) form: NgForm;

  constructor(private loginService: LoginControllerService,
              private commonService: CommonService,
              private router: Router,
              private userControllerService: UserControllerService,
              private toasterService: ToasterService,
  ) {
  }

  private subscriptions: Subscription[] = [];
  somethingWentWrong: string;

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
    this.somethingWentWrong = '';
  }

  ngOnInit() {
    if (localStorage.getItem('currentUser') != null) {
      this.router.navigateByUrl('/user-surface');
    }
    this.somethingWentWrong = '';
  }

  login(): void {
    let request = {
      email: this.userName,
      password: this.password,
    };
    this.subscriptions.push(
    this.loginService.login(request, 'response').subscribe(response => {
        const token: JwtToken = jwt_decode(response.headers.get('authorization')) as JwtToken;
        const user: User = {
          username: this.userName,
          id: Number(token.id),
          token: response.headers.get('authorization'),
          authority: token.authorities[0],
        } as User;
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.commonService.changeLogin(user);
        this.commonService.StartWatchIdle();
        this.router.navigateByUrl('/user-surface');
      },

      // @ts-ignore
      error => {
        this.somethingWentWrong = 'Hibás bejelentkési adatok!';
      },
    ));
  }

  public sendForgetPasswordRequest(): void {
    if (this.email != null) {
      this.subscriptions.push(
        this.userControllerService.generatePasswordByEmailUsingGET(this.email).subscribe(() => {
          this.toasterService.showToaster({
            severity: 'success',
            summary: 'Sikeres jelszó kérés!',
            detail: 'Új jelszó elküldve a ' + this.email + 'címre',
            life: 3000,
          });
          this.displayForgetPassword = false;
        })),
        (err => {
          this.toasterService.showToaster({ severity: 'error', summary: err.error.error, detail: err.error.message, life: 3000 });
        });
    }
  }

}
