import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { isUndefined } from 'util';
import { CommonService } from '../../common/common.service';
import { InvoiceControllerService } from '../../../model/api/invoiceController.service';
import { InvoiceDetailsDto } from '../../../model/model/invoiceDetailsDto';
import { DocumentControllerService } from '../../../model/api/documentController.service';
import { ListInvoiceFieldDefinitionRequest } from '../../../model/model/listInvoiceFieldDefinitionRequest';
import { InvoiceFieldDefinitionDto } from '../../../model/model/invoiceFieldDefinitionDto';
import { MessageService, TreeNode } from 'primeng/api';
import { InvoiceStatusEnum } from '../../common/enums/invoiceStatus';
import { CustomerTreeControllerService } from '../../../model/api/customerTreeController.service';
import { TreeItem, LabelValue, CreateInvoiceRequest, UpdateInvoiceRequest } from '../../../model/model/models';
import { EnumControllerService } from '../../../model/api/enumController.service';
import { PodControllerService } from '../../../model/api/podController.service';
import { DictionaryControllerService } from '../../../model/api/dictionaryController.service';
import { TreeUtils } from '../../common/tree.utils';
import { ToasterService } from '../shared/toaster/toaster.service';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { Subscription } from 'rxjs';
import { InvoiceTableComponent } from '../invoice-table-component/invoice-table.component';
import { WorkflowControllerService } from '../../../model/api/workflowController.service';
import { ExecuteEventRequest } from '../../../model/model/executeEventRequest';
import { WorkflowLogComponent } from './workflow-log/workflow-log.component';
import { InvoiceShareComponent } from './invoice-share/invoice-share.component';

@Component({
  selector: 'invoice-manager',
  templateUrl: './invoice-manager.component.html',
  styleUrls: ['./invoice-manager.component.css'],
  providers: [MessageService],
  inputs: ['visible'],
})
export class InvoiceManagerComponent implements OnInit {
  @ViewChild('pdfViewer', { static: true }) pdfViewer: PdfViewerComponent;
  @ViewChild('formInvice', { static: true }) form: NgForm;

  @ViewChild('workflowLogPanel', { static: true }) workflowLogPanel: WorkflowLogComponent;
  @ViewChild('invoiceSharePanel', { static: true }) invoiceSharePanel: InvoiceShareComponent;

  private subscriptions: Subscription[] = [];

  public visible: boolean;

  public visibleConfirmWorkflow: boolean;

  public confirmWorkflowHeader: string;

  public visibleInvoiceButtons: boolean = true;

  public visibleWorkflowButtons: boolean = false;

  public workflowEvent: any;

  public workflowComment: string;

  public workflowPin: string;

  public workflowButtons: LabelValue[] = [];

  activeTabIndex: number = 0;

  public pdfSrc: string;

  public pdfPage : number;

  public id: number;

  public uploadingDocumentFile: File;

  public uploadingDocumentFileName: string = 'Nincs fájl kiválasztva';

  public invoiceDetails: InvoiceDetailsDto = <InvoiceDetailsDto>{};

  public invoiceDetailsData: any = {};

  public cim: string;

  public errorMessage: string;

  public fields: InvoiceFieldDefinitionDto[];

  public hu: any;

  public providerOptions: LabelValue[] = [];

  public typeOptions: LabelValue[] = [];

  public subTypeOptions: LabelValue[] = [];

  public statusOptions: LabelValue[] = [];

  public podRhdTariffOptions: LabelValue[] = [];

  public podControlOptions: LabelValue[] = [];

  public unitOptions: LabelValue[] = [];

  public currencyOptions: LabelValue[] = [];

  constructor(
    private commonService: CommonService,
    private invoiceService: InvoiceControllerService,
    private documentService: DocumentControllerService,
    private enumService: EnumControllerService,
    private podService: PodControllerService,
    private treeUtils: TreeUtils,
    private dictionaryService: DictionaryControllerService,
    private toasterService: ToasterService,
    private invoiceTable: InvoiceTableComponent,
    private workflowService: WorkflowControllerService,
    private customerTreeService: CustomerTreeControllerService
  ) {}

  @Input()
  set invoiceId(id: number) {
    this.id = id;
    this.activeTabIndex = 0;
    this.pdfViewer.clear();
    
    if (this.id != undefined) {
      this.initInvoice(this.id);
    } else {
      this.pdfSrc = null;
      this.pdfPage = 1;
      this.newInvoice();
    }
    this.handleChange({ index: 0 });
  }

  initInvoice(id: number) {
    this.pdfSrc = null;
    this.pdfPage = 1;
    this.subscriptions.push(
      this.invoiceService.getInvoiceDetailsUsingGET(id, this.commonService.getCurrentUser().token).subscribe((data) => {
        this.invoiceDetails = data.invoiceDetails;
        this.initFields(this.invoiceDetails);
        this.loadDocument(this.invoiceDetails.attachedFileUuid);
      })
    );
    this.getWorkflowButtons();
  }

  newInvoice() {
    this.invoiceDetails = <InvoiceDetailsDto>{};
    this.invoiceDetailsData = {};
  }

  ngOnInit() {
    this.hu = this.commonService.datePickerLanguage;
    this.initDropDown();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  initDropDown() {
    this.unitOptions = this.commonService.getBaseUnitOptions();

    this.enumService.listCurrenciesUsingGET(this.commonService.getCurrentUser().token).subscribe((resp) => {
      this.currencyOptions = resp.labelValues;
    });
  }
  loadDocument(uuid: string) {
    this.documentService.downloadUsingGET(uuid, this.commonService.getCurrentUser().token, 'response').subscribe((data) => {
      this.pdfSrc = URL.createObjectURL(data.body);
      this.pdfPage = this.invoiceDetailsData.pdfPage == null ? 1 : this.invoiceDetailsData.pdfPage;
    });
  }

  reloadFields() {
    this.initFields(this.invoiceDetails);
  }

  initFields(invoiceDto: InvoiceDetailsDto) {
    let req: ListInvoiceFieldDefinitionRequest = {
      provider: invoiceDto.provider,
      podType: invoiceDto.podType,
      subType: invoiceDto.subType,
      type: invoiceDto.type,
    };
    this.invoiceService.listFieldsUsingPOST(req, this.commonService.getCurrentUser().token).subscribe((resp) => {
      this.fields = resp.fields;
      this.providerOptions = resp.providerOptions;
      this.typeOptions = resp.typeOptions;
      this.subTypeOptions = resp.subTypeOptions;
      this.podControlOptions = resp.podControlOptions;
      this.podRhdTariffOptions = resp.podRhdTariffOptions;
      this.statusOptions = resp.statusOptions;
      this.unitOptions = this.commonService.getUnitOptionsByPodType(req.podType);
      this.invoiceDetails = invoiceDto;
      if (this.invoiceDetails.rawData != null) {
        this.invoiceDetailsData = JSON.parse(this.invoiceDetails.rawData);
      }
      this.invoiceDetailsData = this.invoiceDetailsData == null ? {} : this.invoiceDetailsData;
      
      this.getAddress(this.invoiceDetails);
    });
  }

  getAddress(invoiceDto: InvoiceDetailsDto) {
    if (invoiceDto.customerId != null && invoiceDto.podId != null) {
      this.customerTreeService
        .getPODAddressUsingGET(invoiceDto.customerId, invoiceDto.podId, this.commonService.getCurrentUser().token)
        .subscribe((resp) => {
          this.cim = resp.address;
        });
    }
  }

  getRawData(): string {
    return JSON.stringify(this.invoiceDetailsData);
  }

  getUploadedFile(): File {
    return this.uploadingDocumentFile;
  }

  uploadFileChange(event: any): void {
    let target = event.target || event.srcElement;

    this.uploadingDocumentFile = target.files[0];
    this.uploadingDocumentFileName = this.uploadingDocumentFile.name;
    if (typeof FileReader !== 'undefined') {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        this.pdfSrc = e.target.result;
        this.pdfPage = 1;
      };
      reader.readAsArrayBuffer(this.uploadingDocumentFile);
    }
    target.value = '';
  }

  //----------------------------------- Pod chooser popup --------------------------------------
  displayPodChooser: boolean;
  podChooserTree: TreeNode[];
  selectedPod: TreeItem;

  openPodChooser() {
    this.customerTreeService.getTreeUsingGET(this.commonService.getCurrentUser().token).subscribe((response) => {
      this.podChooserTree = response.items;
      this.displayPodChooser = true;
    });
  }

  selectPod() {
    if (!this.hasPod() && this.invoiceDetails.podName != null && this.invoiceDetails.podName.length > 0) {
      this.dictionaryService
        .addUsingGET(this.invoiceDetails.podName, this.selectedPod.label, this.commonService.getCurrentUser().token)
        .subscribe(() => {});
    }
    this.invoiceDetails.podId = this.selectedPod.podId;
    this.invoiceDetails.podName = this.selectedPod.label;
    this.cim = (this.selectedPod as TreeNode).parent.label;
    let root: TreeNode = this.treeUtils.findRoot(this.selectedPod);
    this.invoiceDetails.customerId = parseInt(root.key);
    this.invoiceDetails.customerName = root.label;

    this.podService.getPodUsingGET(this.selectedPod.podId, this.commonService.getCurrentUser().token).subscribe((response) => {
      this.invoiceDetails.podType = response.pod.type;
      this.invoiceDetails.podRhdTariff = response.pod.rhdTariff;
      this.invoiceDetails.podControl = response.pod.control;
      this.initFields(this.invoiceDetails);
    });

    this.displayPodChooser = false;
  }

  isPodSelectDisabled() {
    return this.selectedPod == null || this.selectedPod.podId == null;
  }

  isNewInvoiceOrMissingDocument() {
    return this.invoiceDetails.id == null || this.invoiceDetails.attachedFileUuid == null;
  }

  isNewInvoice() {
    return this.invoiceDetails.id == null || this.invoiceDetails.attachedFileUuid == null;
  }

  hasPod(): boolean {
    return this.invoiceDetails.podId != null;
  }
  zoom_in() {
    this.commonService.zoom_in();
  }

  zoom_out() {
    this.commonService.zoom_out();
  }
  getZoom_to(): number {
    return this.commonService.zoom_to;
  }

  getMaxUploadFileSize(): number {
    return this.commonService.maxUploadFileSize;
  }

  handleChange(event: any) {
    if (event.index == 0) {
      this.visibleInvoiceButtons = true;
      this.visibleWorkflowButtons = false;
    } else if (event.index == 1) {
      this.workflowLogPanel.currentState = this.invoiceDetails.stateName;
      this.workflowLogPanel.loadWorkflowLog(this.invoiceDetails.id);
      this.visibleInvoiceButtons = false;
      this.visibleWorkflowButtons = true;
    } else {
      this.invoiceSharePanel.loadInvoiceShares(this.invoiceDetails.id);
      this.visibleInvoiceButtons = true;
      this.visibleWorkflowButtons = false;
    }
  }

  getWorkflowButtons(): void {
    this.subscriptions.push(
      this.workflowService.getEventsUsingGET(this.id, this.commonService.getCurrentUser().token).subscribe((resp) => {
        this.workflowButtons = resp;
      })
    );
  }

  onWorkflowEvent(button) {
    this.workflowComment = '';
    this.workflowPin = '';
    this.workflowEvent = button.value;
    this.confirmWorkflowHeader = button.label + ' megerősítése';
    this.visibleConfirmWorkflow = true;
  }

  saveWorkflowEvent() {
    let req: ExecuteEventRequest = <ExecuteEventRequest>{};
    req.invoiceId = this.id;
    req.pin = this.workflowPin;
    req.eventId = this.workflowEvent;
    req.comment = this.workflowComment;
    this.workflowService.executeEventUsingPOST(req, this.commonService.getCurrentUser().token).subscribe((resp) => {
      resp;
      this.visibleConfirmWorkflow = false;
      this.finishSave();
    });
  }

  saving(): void {
    if (!this.validate()) {
      return;
    }
    if (this.invoiceDetails.id == null || isUndefined(this.invoiceDetails.id)) {
      //create invoice
      let request: CreateInvoiceRequest = <CreateInvoiceRequest>{};
      request.invoiceDetails = this.invoiceDetails;
      request.invoiceDetails.rawData = this.getRawData();
      this.invoiceService.createInvoiceUsingPOST(request, this.commonService.getCurrentUser().token).subscribe((resp) => {
        let invoiceId: number = resp.id;
        this.uploadInvoiceDocument(invoiceId);
      });
    } else {
      //modify invoice
      let request: UpdateInvoiceRequest = {};
      request.invoiceDetails = this.invoiceDetails;
      request.invoiceDetails.rawData = this.getRawData();
      this.invoiceService.modifyInvoiceUsingPOST(request, this.commonService.getCurrentUser().token).subscribe(() => {
        this.uploadInvoiceDocument(this.invoiceDetails.id);
      });
    }
  }

  private uploadInvoiceDocument(invoiceId: number): void {
    if (this.uploadingDocumentFile != null) {
      this.invoiceService
        .uploadInvoiceDocumentUsingPOST(this.uploadingDocumentFile, invoiceId, this.commonService.getCurrentUser().token)
        .subscribe(() => {
          this.finishSave();
        });
    } else {
      this.finishSave();
    }
  }

  private finishSave() {
    this.invoiceTable.filter(true);
    this.visible = false;
    this.uploadingDocumentFile = null;
    this.uploadingDocumentFileName = null;
    this.form.resetForm();
  }

  validate(): boolean {
    if (!this.invoiceDetails.provider) {
      this.toasterService.showToaster({
        severity: 'error',
        summary: 'Szolgáltató nincs megadva',
        detail: 'A szolgáltató mező nincs kitöltve',
        life: 30000,
      });
      return false;
    }

    if (this.invoiceDetails.settlementPeriodStart != null && this.invoiceDetails.settlementPeriodEnd != null) {
      if (this.invoiceDetails.settlementPeriodStart > this.invoiceDetails.settlementPeriodEnd) {
        this.toasterService.showToaster({
          severity: 'error',
          summary: 'Hibás dátum',
          detail: 'Az időszak kezdete későbbi mint a vége',
          life: 30000,
        });
        return false;
      }
    }

    if (this.invoiceDetails.settlementPeriodStart == null && this.invoiceDetails.settlementPeriodEnd != null) {
      this.toasterService.showToaster({
        severity: 'error',
        summary: 'Hibás dátum',
        detail: 'Az időszak kezdete mező nincs kitöltve',
        life: 30000,
      });
      return false;
    }
    return true;
  }

  checked(): void {
    this.invoiceService.checkedUsingGET(this.invoiceDetails.id, this.commonService.getCurrentUser().token).subscribe(() => {
      this.initInvoice(this.invoiceDetails.id);
    });
  }

  error(): void {
    this.invoiceService.errorUsingGET1(this.invoiceDetails.id, this.commonService.getCurrentUser().token).subscribe(() => {
      this.initInvoice(this.invoiceDetails.id);
    });
  }

  isDisableVerified(): boolean {
    //TODO: ha nem volt valtoztatas a form-on, csak akkor enabled
    return this.invoiceDetails == null || this.invoiceDetails.status != InvoiceStatusEnum[InvoiceStatusEnum.PROCESSED];
  }

  isDisableSaving(): boolean {
    //TODO: form valid check
    return false; //(this.invoiceDetails != null && this.invoiceDetails.status == InvoiceStatusEnum.CHECKED);
  }

  isAdminUser() {
    return this.commonService.isAdminUser();
  }
  getMaxHeight() {
    return this.commonService.getModalHeight();
  }
}
