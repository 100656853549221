import { Component, OnInit, ViewChild } from '@angular/core';
import { BusinessLogControllerService } from 'src/app/model/api/businessLogController.service';
import { GetBusinessLogRequest, LabelValue } from 'src/app/model';
import { CommonService } from '../../common/common.service';
import { Paginator } from 'primeng/paginator';
import { BusinessLogDto } from '../../../model/model/businessLogDto';
import { TABLE_SELECTOR } from '../../common/enums/table-selector';
import { LazyLoadEvent } from 'primeng/api';
import { LogFilter } from './log-filter';
import { EnumControllerService } from '../../../model/api/enumController.service';
import { CustomerTreeControllerService } from '../../../model/api/customerTreeController.service';

@Component({
  selector: 'business-log-table',
  templateUrl: './business-log.component.html',
  styleUrls: ['./business-log.component.css']
})
export class BusinessLogComponent implements OnInit {

  public sortField: string;

  public sortOrder: number = 0;

  public cols: any;
  public currentInc: number = 0;
  public selectedLog: BusinessLogDto[];
  public logs: BusinessLogDto[];

  filterValues: LogFilter;

  public logTotalCount: number = 0;


  public hu: Object;

  public eventOptions: any[];
  public customerOptions: any[];

  @ViewChild('p', { static: true }) paginator: Paginator;

  constructor(private businessLogService: BusinessLogControllerService, 
    private customerTreeService : CustomerTreeControllerService,
    private enumControllerService: EnumControllerService, private commonService: CommonService) { }

  ngOnInit() {
    this.cols = [
      { field: 'created', header: 'Rögzítve', size: "150px", align: "center" },
      { field: 'event', header: 'Típus', size: "150px", align: "left" },
      { field: 'customer', header: 'Ügyfél', size: "200px", align: "left" },
      { field: 'userName', header: 'Felhasználónév', size: "200px", align: "left" },
      { field: 'userEmail', header: 'Email cím', size: "200px", align: "left" },
      { field: 'description', header: 'Leírás', size: "450px", align: "left" }
    ];
    this.enumControllerService.listLogTypeUsingGET(this.commonService.getCurrentUser().token).subscribe(data => {
      this.eventOptions = data.labelValues;
    });
    this.customerOptions = [];
    this.customerTreeService.getTreeOnlyCustomersUsingGET(this.commonService.getCurrentUser().token).subscribe(data => {
      data.items.forEach(item => {
        this.customerOptions.push({label:item.label, value:item.key});
      });
    });
    
    this.hu = this.commonService.datePickerLanguage;
    this.filterValues = <LogFilter>{};
    this.commonService.connector.subscribe(() => {
      this.filter();
    });
  }

  buildListLogsRequest(): GetBusinessLogRequest {
    let request: GetBusinessLogRequest = {
      pageNumber: this.paginator.getPage(),
      pageSize: this.commonService.tablePageSize,
      sortField: this.sortField,
      direction: this.sortOrder,
      description: this.filterValues != null ? this.filterValues.description : null,
      event: this.filterValues != null ? this.filterValues.event : null,
      userEmail: this.filterValues != null ? this.filterValues.userEmail : null,
      userName: this.filterValues != null ? this.filterValues.userName : null,
      customerId: this.filterValues != null ? this.filterValues.customerId : null,

      createdFrom: this.filterValues != null && this.filterValues.created != null ? this.filterValues.created[0] : null,
      createdTo: this.filterValues != null && this.filterValues.created != null ? this.filterValues.created[1] : null,
    };
    return request;
  }

  listLogs() {
    if (TABLE_SELECTOR.LOG != this.commonService.getselectTable()) {
      return;
    }

    this.businessLogService.getUserLogsUsingPOST(this.buildListLogsRequest(), this.commonService.getCurrentUser().token).subscribe(data => {
      this.logs = data.logs.content;
      
      this.logTotalCount = data.logs.totalElements;      
    },
      //@ts-ignore
      error => { },
      () => { this.commonService.setDisableTree(true); });
  }

  public sort(event: LazyLoadEvent) {
    this.sortField = event.sortField;
    this.sortOrder = event.sortOrder;
    this.listLogs();
  }

  public filter() {
    this.paginator.changePage(0);
    this.listLogs();
  }

  public paginate(): void {
    this.listLogs();
  }

  getTableWidthInpx(): string {
    return this.commonService.getTableWidthInpx();
  }

  getTableHeightInpx(): string {
    return this.commonService.getTableHeightInpx();
  }
  getTablePageSize(): number {
    return this.commonService.tablePageSize;
  }
  findLabel(options: LabelValue[], value: string): string {
    return this.commonService.findLabel(options, value);
  }
  
  public getCurrentPageReportTemplate() {
    let from =  this.paginator.getPage() * this.commonService.tablePageSize + 1;
    let to =  (this.paginator.getPage()+1) * this.commonService.tablePageSize;
    const total = this.logTotalCount | 0;
    to = Math.min(to, total);
    return `{currentPage}. oldal [ ${from} - ${to}  a(z) ${total} -ből]`
  }  
  

}
