export * from './basicErrorController.service';
import { BasicErrorControllerService } from './basicErrorController.service';
export * from './businessLogController.service';
import { BusinessLogControllerService } from './businessLogController.service';
export * from './commentController.service';
import { CommentControllerService } from './commentController.service';
export * from './configController.service';
import { ConfigControllerService } from './configController.service';
export * from './contractController.service';
import { ContractControllerService } from './contractController.service';
export * from './customerTreeController.service';
import { CustomerTreeControllerService } from './customerTreeController.service';
export * from './dictionaryController.service';
import { DictionaryControllerService } from './dictionaryController.service';
export * from './dispatcherController.service';
import { DispatcherControllerService } from './dispatcherController.service';
export * from './documentController.service';
import { DocumentControllerService } from './documentController.service';
export * from './enumController.service';
import { EnumControllerService } from './enumController.service';
export * from './invoiceController.service';
import { InvoiceControllerService } from './invoiceController.service';
export * from './meterController.service';
import { MeterControllerService } from './meterController.service';
export * from './monthlyReportController.service';
import { MonthlyReportControllerService } from './monthlyReportController.service';
export * from './notificationController.service';
import { NotificationControllerService } from './notificationController.service';
export * from './podController.service';
import { PodControllerService } from './podController.service';
export * from './providerIntegrationController.service';
import { ProviderIntegrationControllerService } from './providerIntegrationController.service';
export * from './userController.service';
import { UserControllerService } from './userController.service';
export const APIS = [BasicErrorControllerService, BusinessLogControllerService, CommentControllerService, ConfigControllerService, ContractControllerService, CustomerTreeControllerService, DictionaryControllerService, DispatcherControllerService, DocumentControllerService, EnumControllerService, InvoiceControllerService, MeterControllerService, MonthlyReportControllerService, NotificationControllerService, PodControllerService, ProviderIntegrationControllerService, UserControllerService];
