import { Component, OnInit, ViewChild, HostListener, OnDestroy } from '@angular/core';
import { CommonService } from '../../common/common.service';
import { TABLE_SELECTOR } from '../../common/enums/table-selector';
import { MainDecorator } from './main.decorator';
import { CustomerTreeControllerService } from '../../../model/api/customerTreeController.service';
import { CreateCustomerRequest } from '../../../model/model/createCustomerRequest';
import { TreeUtils } from '../../common/tree.utils';
import { ResizeEvent } from 'angular-resizable-element';
import { TreeComponent } from '../shared/tree-component/tree.component';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
})
@MainDecorator
export class MainComponent implements OnInit, OnDestroy {
  @ViewChild('customerTree', { static: true }) tree: TreeComponent;
  
  public uploadingFile: File;
  public uploadingFileName: string = 'Nincs fájl kiválasztva';
  public displayCustomerPopup: boolean = false;
  public displayExcelImport: boolean = false;
  //public spinner: string = 'none';
  constructor(
    private commonService: CommonService,
    private treeUtils: TreeUtils,
    private customerTreeService: CustomerTreeControllerService,
  ) {}
  ngOnDestroy(): void {}

  public componentHeight: number;
  public buttonPanelHeight: number;
  public showTable: string;

  public customerName: string;

  ngOnInit() {
    this.commonService.automaticClearUserSettings();
    this.componentHeight = window.document.documentElement.clientHeight - this.commonService.componentHeightSubValue;
    this.commonService.componentHeight = this.componentHeight;
    this.buttonPanelHeight = this.commonService.headerPanelHeight / 2;

    this.commonService.connector.subscribe((data) => {
      if (data != null && data.selectedTable != null) {
        this.showTable = TABLE_SELECTOR[data.selectedTable];
      }
    });
  }

  getIcon(): string {
    return !this.commonService.panelHide ? 'pi pi-chevron-left' : 'pi pi-chevron-right';
  }

  panelResize() {
    !this.commonService.panelHide ? (this.commonService.panelHide = true) : (this.commonService.panelHide = false);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.componentHeight = event.target.innerHeight - this.commonService.componentHeightSubValue;
    this.commonService.componentHeight = this.componentHeight;
  }

  @HostListener('document:click', ['$event'])
  //@ts-ignore
  click(event) {
    this.commonService.restart();
  }

  public createCustomer(): void {
    let request: CreateCustomerRequest = {
      name: this.customerName,
    };
    this.customerTreeService.createCustomerUsingPOST(request, this.commonService.getCurrentUser().token).subscribe(() => {
      this.customerName = '';
      this.displayCustomerPopup = false;
      this.tree.loadTree();
    });
  }

  public expandAll() {
    this.commonService.spinner = 'block';
    if (!this.commonService.selectedItem) {
      setTimeout(async () => {
        await Promise.all(
          this.tree.tree.map( (node) => {
            this.treeUtils.expandTreeRecursive(node, true);
          })
        ).finally(() => {
          this.commonService.spinner = 'none';
        });
      }, 10);
    } else {
      this.treeUtils.expandTreeRecursive(this.commonService.selectedItem, true);
      this.commonService.spinner = 'none';
    }
  }

  public collapseAll() {
    this.commonService.spinner = 'block';
    if (!this.commonService.selectedItem) {
      setTimeout(async () => {
        await Promise.all(
          this.tree.tree.map( (node) => {
            this.treeUtils.expandTreeRecursive(node, false);
          })
        ).finally(() => {
          this.commonService.spinner = 'none';
        });
      }, 10);
    } else {
      this.treeUtils.expandTreeRecursive(this.commonService.selectedItem, false);
      this.commonService.spinner = 'none';
    }
  }

  uploadFileChange(event): void {
    let target = event.target || event.srcElement;
    this.uploadingFile = target.files[0];
    this.uploadingFileName = this.uploadingFile.name;
    target.value = '';
  }

  uploadExcel(): void {
    this.customerTreeService.importExcelUsingPOST(this.uploadingFile, this.commonService.getCurrentUser().token).subscribe(() => {
      this.displayExcelImport = false;
      this.uploadingFile = null;
      this.uploadingFileName = null;
      this.tree.loadTree();
    });
  }
  public isDisableTree(): boolean {
    return this.commonService.isDisableTree();
  }
  isAdminUser(): boolean {
    return this.commonService.isAdminUser();
  }
  getMaxUploadFileSize(): number {
    return this.commonService.maxUploadFileSize;
  }
  getSpinner(): string {
    return this.commonService.spinner;
  }
  isPanelHide(): boolean {
    return this.commonService.panelHide;
  }

  onResizeEnd(event: ResizeEvent): void {
    this.commonService.TreePanelWidth = event.rectangle.right;
    this.commonService.addComponentSetting('TreePanel', 'width', 'Fa panel, szélesség', event.rectangle.right);
  }

  getTreePanelWidth() {
    return this.commonService.TreePanelWidth;
  }
}
