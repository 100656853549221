import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { InvoiceControllerService } from 'src/app/model/api/invoiceController.service';
import { CommonService } from 'src/app/modules/common/common.service';
import { ToasterService } from '../toaster/toaster.service';

@Component({
  selector: 'account-continuity-report-trigger',
  templateUrl: './account-continuity-report-trigger.component.html',
  styleUrls: ['./account-continuity-report-trigger.component.css'],
})
export class AccountContinuityReportTriggerComponent implements OnInit, OnDestroy{
  constructor(
    private invoiceControllerService: InvoiceControllerService,
    private commonService: CommonService,
    private toasterService: ToasterService
  ) {}
  formDate: string = '';
  hu: any;
  private subscriptions: Subscription[] = [];

  ngOnInit() {
    this.hu = this.commonService.datePickerLanguage;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  startReport() {
    this.subscriptions.push(
      this.invoiceControllerService.getContinuousInvoiceReportUsingGET(this.formDate, this.commonService.getCurrentUser().token).subscribe(
        // @ts-ignore
        (data) => {
          this.toasterService.showToaster({
            severity: 'success',
            summary: 'Számlafolytonosság riport készítése elindult!',
            detail: '',
            life: 30000,
          });
        }
      )
    );
  }
}
