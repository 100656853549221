import { Component } from "@angular/core";
import { WorkflowControllerService } from "../../../../model/api/workflowController.service";
import { LabelValue } from "../../../../model/model/labelValue";
import { StateInvoiceLogDto } from "../../../../model/model/stateInvoiceLogDto";
import { CommonService } from "../../../common/common.service";

@Component({
    selector: "workflow-log",
    templateUrl: "./workflow-log.component.html",
    styleUrls: ["./workflow-log.component.css"],
  })


export class WorkflowLogComponent {

    public cols: any;
    public currentInc: number = 0;
    public logs: StateInvoiceLogDto[];
  
    public currentState : string;
  
    public loadWorkflowLog(id: number) {
        
        if (id === undefined) {
            return;
        }
        this.workflowService.listStateInvoiceLogUsingGET(id, this.commonService.getCurrentUser().token).subscribe(resp => {
            this.logs = resp;
          });
    }


    constructor(private commonService: CommonService, 
        private workflowService: WorkflowControllerService,
        ) {
      }

      ngOnInit() {
        this.cols = [
          { field: 'stateName', header: 'Állapot', size: "100px", align: "left" },
          { field: 'userName', header: 'Felhasználó', size: "150px", align: "left" },
          { field: 'event', header: 'Esemény', size: "100px", align: "left" },
          { field: 'eventTime', header: 'Időpont', size: "100px", align: "left" },
          { field: 'comment', header: 'Leírás', size: "350px", align: "left" }
        ];
      }

      getTableWidthInpx(): string {
        return this.commonService.getTableWidthInpx();
      }
    
      getTableHeightInpx(): string {
        return this.commonService.getTableHeightInpx();
      }
      getTablePageSize(): number {
        return this.commonService.tablePageSize;
      }
      findLabel(options: LabelValue[], value: string): string {
        return this.commonService.findLabel(options, value);
      }
      
 
}  