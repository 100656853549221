import { Component, OnInit} from '@angular/core';
import { ConfigControllerService } from 'src/app/model/api/configController.service';
import { ConfigDto } from 'src/app/model/model/configDto';
import { CommonService } from '../../common/common.service';
import { TABLE_SELECTOR } from '../../common/enums/table-selector';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { UpdateConfigRequest } from 'src/app/model/model/updateConfigRequest';
import { EnumControllerService } from 'src/app/model/api/enumController.service';
import { LabelValue } from 'src/app/model/model/labelValue';

@Component({
  selector: 'config-table',
  templateUrl: './config-table.component.html',
  styleUrls: ['./config-table.component.css']
})
export class ConfigTableComponent implements OnInit {
  public cols: any;
  private subscriptions: Subscription[] = [];
  public configs: ConfigDto[];
  public configTotalCount: number = 0;
  public showConfigEditor: boolean = false;
  public selectedConfig: ConfigDto;
  public currentInc: number = 0;
  public displayConfigEditor: boolean = false;
  public editConfigLabel: string = '';
  public editConfigValue: string = '';

  public configOptions: any[];

  constructor(private configControllerService: ConfigControllerService,
    private enumService: EnumControllerService,
    private commonService: CommonService,) { }
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  ngOnInit() {
    this.cols = [
      { field: 'name', header: 'Megnevezés', size: "150px", align: "left" },
      { field: 'value', header: 'Érték', size: "150px", align: "left" },
      { field: "operations", header: "Műveletek", size: "100px", align: "center" }
    ];
    this.subscriptions.push(
      this.commonService.connector.subscribe(() => {
        this.listConfigs();
      }));

    this.subscriptions.push(this.enumService.listConfigNameUsingGET(this.commonService.getCurrentUser().token).subscribe(data => {
      this.configOptions = data.labelValues;
    }));
  }

  listConfigs() {

    if (TABLE_SELECTOR.CONFIG != this.commonService.getselectTable()) {
      return;
    }

    this.configControllerService.listUsingGET(this.commonService.getCurrentUser().token).pipe(take(1)).subscribe(data => {
      this.configs = data;
    },
      //@ts-ignore
      error => { },
      () => { this.commonService.setDisableTree(true); }
    );
  }

  getTableWidthInpx(): string {
    return this.commonService.getTableWidthInpx();
  }

  getTableHeightInpx(): string {
    return this.commonService.getTableHeightInpx();
  }

  editConfig(row: ConfigDto) {
    this.editConfigLabel = row.name;
    this.editConfigValue = row.value;
    this.displayConfigEditor = true;
  }
  saveConfig() {
    let request: UpdateConfigRequest = {};
    request.name = this.editConfigLabel;
    request.value = this.editConfigValue;

    this.configControllerService.updateConfigUsingPOST(request, this.commonService.getCurrentUser().token).subscribe(() => {
      this.listConfigs();
    },
      //@ts-ignore
      (error) => { },
      () => {
        this.listConfigs();
      })
  }

  findLabel(options: LabelValue[], value: string): string {
    return this.commonService.findLabel(options, value);
  }

}

