/**
 * OLC System
 * Ügyfél és számlakezelő rendszer
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface YearMonth { 
    leapYear?: boolean;
    month?: YearMonth.MonthEnum;
    monthValue?: number;
    year?: number;
}
export namespace YearMonth {
    export type MonthEnum = 'JANUARY' | 'FEBRUARY' | 'MARCH' | 'APRIL' | 'MAY' | 'JUNE' | 'JULY' | 'AUGUST' | 'SEPTEMBER' | 'OCTOBER' | 'NOVEMBER' | 'DECEMBER';
    export const MonthEnum = {
        JANUARY: 'JANUARY' as MonthEnum,
        FEBRUARY: 'FEBRUARY' as MonthEnum,
        MARCH: 'MARCH' as MonthEnum,
        APRIL: 'APRIL' as MonthEnum,
        MAY: 'MAY' as MonthEnum,
        JUNE: 'JUNE' as MonthEnum,
        JULY: 'JULY' as MonthEnum,
        AUGUST: 'AUGUST' as MonthEnum,
        SEPTEMBER: 'SEPTEMBER' as MonthEnum,
        OCTOBER: 'OCTOBER' as MonthEnum,
        NOVEMBER: 'NOVEMBER' as MonthEnum,
        DECEMBER: 'DECEMBER' as MonthEnum
    };
}