import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { UserSurfaceModule } from './modules/user-surface/user-surface.module';
import { LoginComponent } from './modules/user-surface/login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularDualListBoxModule } from 'angular-dual-listbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { PasswordModule } from 'primeng/password';
import { MenubarModule } from 'primeng/menubar';
import { MessageService } from 'primeng/api';
import { ToasterService } from './modules/user-surface/shared/toaster/toaster.service';
import { ChartModule } from 'primeng/chart';
import { RouteReuseStrategy } from '@angular/router';
import { CacheRouteReuseStrategy } from './cache-route-reuse.strategy';
import { DialogModule } from 'primeng/dialog';
import { LoginControllerService } from './modules/user-surface/login/service/loginController.service';
import { UserIdleModule } from 'angular-user-idle';
import { ResizableModule } from 'angular-resizable-element';
import { TreeModule } from './olcTree/tree';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    UserIdleModule.forRoot({ idle: 890, timeout: 10, ping: 20 }),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    UserSurfaceModule,
    BrowserAnimationsModule,
    TreeModule,
    AngularDualListBoxModule,
    ConfirmDialogModule,
    DialogModule,
    MessageModule,
    MessagesModule,
    PasswordModule,
    ChartModule,
    MenubarModule,
    ResizableModule,
  ],
  providers: [MessageService,
    LoginControllerService, ToasterService,
    {
      provide: RouteReuseStrategy,
      useClass: CacheRouteReuseStrategy,
    }],
  bootstrap: [AppComponent],
})
export class AppModule {
}
