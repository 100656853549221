/**
 * OLC System
 * Ügyfél és számlakezelő rendszer
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpEvent }  from '@angular/common/http';
import { Observable }                                        from 'rxjs';
import { ModelAndView } from '../model/modelAndView';
import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { environment } from '../../../environments/environment';


@Injectable()
export class BasicErrorControllerService {

    protected basePath = environment.apiBaseUrl + '/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * errorHtml
     * 
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public errorHtmlUsingDELETE(authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<ModelAndView>;
    public errorHtmlUsingDELETE(authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ModelAndView>>;
    public errorHtmlUsingDELETE(authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ModelAndView>>;
    public errorHtmlUsingDELETE(authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/html'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.delete<ModelAndView>(`${this.basePath}/error`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * errorHtml
     * 
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public errorHtmlUsingGET(authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<ModelAndView>;
    public errorHtmlUsingGET(authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ModelAndView>>;
    public errorHtmlUsingGET(authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ModelAndView>>;
    public errorHtmlUsingGET(authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/html'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get<ModelAndView>(`${this.basePath}/error`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * errorHtml
     * 
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public errorHtmlUsingHEAD(authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<ModelAndView>;
    public errorHtmlUsingHEAD(authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ModelAndView>>;
    public errorHtmlUsingHEAD(authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ModelAndView>>;
    public errorHtmlUsingHEAD(authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/html'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.head<ModelAndView>(`${this.basePath}/error`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * errorHtml
     * 
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public errorHtmlUsingOPTIONS(authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<ModelAndView>;
    public errorHtmlUsingOPTIONS(authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ModelAndView>>;
    public errorHtmlUsingOPTIONS(authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ModelAndView>>;
    public errorHtmlUsingOPTIONS(authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/html'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.options<ModelAndView>(`${this.basePath}/error`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * errorHtml
     * 
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public errorHtmlUsingPATCH(authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<ModelAndView>;
    public errorHtmlUsingPATCH(authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ModelAndView>>;
    public errorHtmlUsingPATCH(authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ModelAndView>>;
    public errorHtmlUsingPATCH(authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/html'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.patch<ModelAndView>(`${this.basePath}/error`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * errorHtml
     * 
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public errorHtmlUsingPOST(authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<ModelAndView>;
    public errorHtmlUsingPOST(authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ModelAndView>>;
    public errorHtmlUsingPOST(authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ModelAndView>>;
    public errorHtmlUsingPOST(authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/html'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.post<ModelAndView>(`${this.basePath}/error`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * errorHtml
     * 
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public errorHtmlUsingPUT(authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<ModelAndView>;
    public errorHtmlUsingPUT(authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ModelAndView>>;
    public errorHtmlUsingPUT(authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ModelAndView>>;
    public errorHtmlUsingPUT(authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/html'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.put<ModelAndView>(`${this.basePath}/error`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
