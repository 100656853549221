/**
 * OLC System
 * Ügyfél és számlakezelő rendszer
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateDocumentResponse } from '../model/createDocumentResponse';
import { ListDocumentsRequest } from '../model/listDocumentsRequest';
import { ListDocumentsResponse } from '../model/listDocumentsResponse';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { environment } from '../../../environments/environment';


@Injectable()
export class DocumentControllerService {

    protected basePath = environment.apiBaseUrl + '/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * createDocument
     * 
     * @param file 
     * @param authorization JWT Authorization token
     * @param name 
     * @param podId 
     * @param treeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDocumentUsingPOST(file: Blob, authorization?: string, name?: string, podId?: number, treeId?: number, observe?: 'body', reportProgress?: boolean): Observable<CreateDocumentResponse>;
    public createDocumentUsingPOST(file: Blob, authorization?: string, name?: string, podId?: number, treeId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreateDocumentResponse>>;
    public createDocumentUsingPOST(file: Blob, authorization?: string, name?: string, podId?: number, treeId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreateDocumentResponse>>;
    public createDocumentUsingPOST(file: Blob, authorization?: string, name?: string, podId?: number, treeId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling createDocumentUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        if (podId !== undefined && podId !== null) {
            queryParameters = queryParameters.set('podId', <any>podId);
        }
        if (treeId !== undefined && treeId !== null) {
            queryParameters = queryParameters.set('treeId', <any>treeId);
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }
            
        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.post<CreateDocumentResponse>(`${this.basePath}/document/new`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * delete
     * 
     * @param documentId documentId
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUsingGET1(documentId: number, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteUsingGET1(documentId: number, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteUsingGET1(documentId: number, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteUsingGET1(documentId: number, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling deleteUsingGET1.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<any>(`${this.basePath}/document/delete/${encodeURIComponent(String(documentId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * download
     * 
     * @param uuid uuid
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadUsingGET(uuid: string, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public downloadUsingGET(uuid: string, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public downloadUsingGET(uuid: string, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public downloadUsingGET(uuid: string, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling downloadUsingGET.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<any>(`${this.basePath}/document/download/${encodeURIComponent(String(uuid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
   /**
     * downloadZip
     * 
     * @param body uuidList
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadZipUsingPOST(body: Array<string>, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public downloadZipUsingPOST(body: Array<string>, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public downloadZipUsingPOST(body: Array<string>, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public downloadZipUsingPOST(body: Array<string>, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling downloadZipUsingPOST.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/zip'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/document/zip`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * listDocuments
     * 
     * @param body request
     * @param authorization JWT Authorization token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listDocumentsUsingPOST(body: ListDocumentsRequest, authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<ListDocumentsResponse>;
    public listDocumentsUsingPOST(body: ListDocumentsRequest, authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListDocumentsResponse>>;
    public listDocumentsUsingPOST(body: ListDocumentsRequest, authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListDocumentsResponse>>;
    public listDocumentsUsingPOST(body: ListDocumentsRequest, authorization?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling listDocumentsUsingPOST.');
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ListDocumentsResponse>(`${this.basePath}/document`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
