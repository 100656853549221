import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../../common/common.service';
import { InvoiceFilter } from './invoice-filter';
import { CommentRequest, CommentControllerService, DispatcherControllerService } from 'src/app/model';
import { LazyLoadEvent } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { CommentDto } from '../../../model/model/commentDto';
import { InvoiceListItemDto } from '../../../model/model/invoiceListItemDto';
import { InvoiceControllerService } from '../../../model/api/invoiceController.service';
import { EnumControllerService } from '../../../model/api/enumController.service';
import { LabelValue } from '../../../model/model/labelValue';
import { ConfirmationService } from 'primeng/api';
import { TreeItem } from '../../../model/model/treeItem';
import { ListInvoiceRequest } from '../../../model/model/listInvoiceRequest';
import { DocumentControllerService } from '../../../model/api/documentController.service';
import { TABLE_SELECTOR } from '../../common/enums/table-selector';

import { InvoiceManagerComponent } from '../invoice-manager/invoice-manager.component';
import { Table } from 'primeng/table';
import { ToastMessage } from '../shared/toaster/toast-message';
import { ToasterService } from '../shared/toaster/toaster.service';
import { User } from 'src/app/model/model/client/user';
import { InvoiceProcessingComponent } from '../invoice-processing/invoice-processing.component';
import { InvoiceProcessingDetailData } from '../invoice-processing/invoice-processing-provicerData';
import { UIChart } from 'primeng/components/chart/chart';
import { PeriodInputComponent } from '../shared/period-input/period-input.component';

@Component({
  selector: 'invoice-table',
  templateUrl: './invoice-table.component.html',
  styleUrls: ['./invoice-table.component.css'],
  providers: [ConfirmationService],
})
export class InvoiceTableComponent implements OnInit {
  @ViewChild('dt', { static: false }) table: Table;
  @ViewChild('p', { static: true }) paginator: Paginator;
  @ViewChild('invoiceManager', { static: false }) invoiceManager: InvoiceManagerComponent;
  @ViewChild('invoiceProcessingComponent', { static: false }) invoiceProcessingComponent: InvoiceProcessingComponent;
  @ViewChild('issueDateCalendar', { static: false }) issueDateCalendar: PeriodInputComponent;
  @ViewChild('settlementPeriodCalendar', { static: false }) settlementPeriodCalendar: PeriodInputComponent;
  @ViewChild('payingDeadlineCalendar', { static: false }) payingDeadlineCalendar: PeriodInputComponent;
  @ViewChild('chart', { static: false }) chart: UIChart;
  public selectedInvoices: InvoiceListItemDto[];

  public invoiceProcessOptions: LabelValue[] = [];

  public invoices: InvoiceListItemDto[];

  public filterValues: InvoiceFilter = null; //new InvoiceFilter();

  public allColumns: any[] = [];

  public selectedColumns: any[] = [];

  public orderedSelectedColumns: any[] = [];

  public displayInvoiceComment: boolean = false;

  public showChartDialog: boolean = false;

  public chartData: any;

  public invoiceTotalCount: number = 0;

  public currentInc: number = 0;

  public sortField: string;

  public sortOrder: number = 0;

  public hu: any;

  public selectedTreeItem: TreeItem;

  public currencyOptions: any;
  public actProcessID: number;
  public statusAndDetail: InvoiceProcessingDetailData = <InvoiceProcessingDetailData>{};

  public invoiceProcessStatus: any;
  // --------------- Comment panel ------------------------
  public commentText: string = '';

  public commentInvoiceId: number;
  public chartTypeOptions: LabelValue[] = [
    { label: 'Oszlop', value: 'bar' },
    { label: 'Vonal', value: 'line' },
  ];
  public simpleChart: boolean = false;
  public selectedChartType = 'bar';
  public comments: CommentDto[];
  public showInvoiceProcessDetail: boolean = false;
  private noChange: boolean = false;
  constructor(
    private commonService: CommonService,
    private commentService: CommentControllerService,
    private dispatcherService: DispatcherControllerService,
    private invoiceService: InvoiceControllerService,
    private enumService: EnumControllerService,
    private documentController: DocumentControllerService,
    private confirmationService: ConfirmationService,
    private toasterService: ToasterService
  ) {}

  private chartColors = [
    '#9CCC65',
    '#FF6666',
    '#CC6600',
    '#FFCC00',
    '#CCCC99',
    '#CCFF00',
    '#33CC00',
    '#CCFFFF',
    '#3399CC',
    '#663399',
    '#CC6699',
    '#FF3399',
    '#FF66FF',
  ];

  ngOnInit() {
    this.initColumns();
    this.hu = this.commonService.datePickerLanguage;
    this.filterValues = <InvoiceFilter>{};
    this.commonService.connector.subscribe((data) => {
      if (!this.noChange) {
        this.selectedTreeItem = data.selectedTreeItem;
        this.selectedInvoices = [];
        this.filter();
      }
    });

    this.commonService.invoiceFilter.subscribe((data) => {
      this.noChange = true;
      if (data) {
        if (this.filterValues.podName || this.filterValues.invoiceNumber) this.initNewFilter();

        if (data.routerlink == 'INVOICE') {
          this.filterValues.invoiceNumber = data.value;
        }
        if (data.routerlink == 'POD') {
          this.filterValues.podName = data.value;
        }
      }
      this.noChange = false;
    });
  }

  initColumns() {
    this.enumService.listCurrenciesUsingGET(this.commonService.getCurrentUser().token).subscribe((resp) => {
      this.currencyOptions = resp.labelValues;
    });
    let existUXSetting = this.commonService.existUserUXSetting('invoice-table', 'selectedColumns');
    let savedSelectedCols: any[];
    savedSelectedCols = existUXSetting ? JSON.parse(this.commonService.getUserUXSettingValue('invoice-table', 'selectedColumns')) : [];
    this.invoiceService.listColumnFieldsUsingGET(this.commonService.getCurrentUser().token).subscribe((resp) => {
      resp.fields.forEach((field) => {
        let item: any = {
          field: field.fieldName,
          value: field.fieldName,
          header: field.displayName,
          fieldType: field.fieldType,
          labelValues: field.labelValues,
          fieldLocation: field.fieldLocation,
          size: field.size,
          align: field.align,
        };

        this.allColumns.push(item);
        let count = savedSelectedCols.find((citem) => citem.field == item.field);
        if (existUXSetting) {
          if (count) this.selectedColumns.push(item);
        } else {
          if (!field.optionalColumn) this.selectedColumns.push(item);
        }
      });

      this.setFilterDropDown();

      this.allColumns = Object.assign([], this.allColumns);
      this.toggleColumn(null, false);
    });
  }

  setFilterDropDown() {
    if (this.isAdminUser()) {
      this.enumService.listProcessStatusUsingGET(this.commonService.getCurrentUser().token).subscribe((resp) => {
        this.invoiceProcessStatus = resp.labelValues;
        this.loadInvoiceProcesses();
      });
    }
  }

  loadInvoiceProcesses() {
    this.invoiceService.listInvoiceProcessesUsingGET(this.commonService.getCurrentUser().token).subscribe((data) => {
      this.invoiceProcessOptions = [];
      data.forEach((item) => {
        let statusLabel = this.commonService.findLabel(this.invoiceProcessStatus, item.status);
        this.invoiceProcessOptions.push({ label: item.processStart + ' - ' + statusLabel, value: '' + item.id });
      });
    });
  }

  //@ts-ignore
  toggleColumn(event?, needSave = true) {
    this.orderedSelectedColumns = [];
    this.allColumns.forEach((col) => {
      this.selectedColumns.forEach((selectedCol) => {
        if (selectedCol === col) {
          this.orderedSelectedColumns.push(col);
        }
      });
    });

    this.orderedSelectedColumns.push({ field: 'operations', header: 'Műveletek', fieldLocation: 'COLUMN', size: '100px', align: 'center' });

    if (needSave)
      this.commonService.addComponentSetting(
        'invoice-table',
        'selectedColumns',
        'Számlák tábla, mutatott oszlopok',
        JSON.stringify(this.selectedColumns)
      );
  }

  findLabel(options: LabelValue[], value: string): string {
    let result: string = value;
    options.forEach((item) => {
      if (item.value == value) {
        result = item.label;
      }
    });
    return result;
  }

  showRawDataValue(data: string, field: string): any {
    return data != null ? JSON.parse(data)[field] : '';
  }

  isDisableExport(): boolean {
    return this.invoices == null || this.invoices.length == 0;
  }

  buildListInvoiceRequest(): ListInvoiceRequest {
    let request: ListInvoiceRequest = {
      treeId: this.selectedTreeItem == null ? null : parseInt(this.selectedTreeItem.key),
      pageNumber: this.paginator.getPage(),
      pageSize: this.commonService.tablePageSize,
      sortField: this.sortField,
      direction: this.sortOrder,
      invoiceProcessId: this.filterValues.invoiceProcessId == null ? null : this.filterValues.invoiceProcessId,
      orphanInvoice: this.filterValues != null ? this.filterValues.orphanInvoice : false,
      podName: this.filterValues != null ? this.filterValues.podName : null,
      podType: this.filterValues != null ? this.filterValues.podType : null,
      invoiceNumber: this.filterValues != null ? this.filterValues.invoiceNumber : null,
      type: this.filterValues != null && this.filterValues.type != null ? this.filterValues.type : null,
      provider: this.filterValues != null && this.filterValues.provider != null ? this.filterValues.provider : null,
      issueDateFrom: this.filterValues != null && this.filterValues.issueDate != null ? this.filterValues.issueDate[0] : null,
      issueDateTo: this.filterValues != null && this.filterValues.issueDate != null ? this.filterValues.issueDate[1] : null,
      payingDeadlineFrom:
        this.filterValues != null && this.filterValues.payingDeadline != null ? this.filterValues.payingDeadline[0] : null,
      payingDeadlineTo: this.filterValues != null && this.filterValues.payingDeadline != null ? this.filterValues.payingDeadline[1] : null,
      settlementPeriodFrom:
        this.filterValues != null && this.filterValues.settlementPeriod != null ? this.filterValues.settlementPeriod[0] : null,
      settlementPeriodTo:
        this.filterValues != null && this.filterValues.settlementPeriod != null ? this.filterValues.settlementPeriod[1] : null,
      rhdTariff: this.filterValues != null ? this.filterValues.rhdTariff : null,
      podControl: this.filterValues != null ? this.filterValues.podControl : null,
    };

    return request;
  }
  listInvoices(): void {
    if (TABLE_SELECTOR.INVOICES != this.commonService.getselectTable()) {
      return;
    }
    this.commonService.setDisableTree(this.filterValues.orphanInvoice);
    this.invoiceService
      .listInvoicesUsingPOST(this.buildListInvoiceRequest(), this.commonService.getCurrentUser().token)
      .subscribe((data) => {
        this.invoices = data.invoicePage.content;
        this.invoiceTotalCount = data.invoicePage.totalElements;
      });

    this.setFilterDropDown();
  }

  // ----------------------- Table events  -----------------------------------------

  public sort(event: LazyLoadEvent) {
    this.sortField = event.sortField;
    this.sortOrder = event.sortOrder;
    this.selectedInvoices = [];
    this.listInvoices();
  }

  public filter(afterSaving: boolean = false) {
    
    this.selectedInvoices = [];
    if(!afterSaving) {
      this.paginator.changePage(0);
      this.currentInc = 0;
    }
     
    this.listInvoices();
  }

  public paginate(): void {
    this.currentInc = this.paginator.getPage() * this.commonService.tablePageSize;
    this.listInvoices();
  }

  public getCurrentPageReportTemplate() {
    let from = this.paginator.getPage() * this.commonService.tablePageSize + 1;
    let to = (this.paginator.getPage() + 1) * this.commonService.tablePageSize;
    const total = this.invoiceTotalCount | 0;
    to = Math.min(to, total);
    return `{currentPage}. oldal [ ${from} - ${to}  a(z) ${total} -ből]`;
    //return '{currentPage}. oldal [' + from + ' - ' + to + ' a(z) ' + total + '-ből]';
  }

  public initNewFilter() {
    this.filterValues = new InvoiceFilter();
    this.filterValues.issueDate = [null, null];
  }

  public resetFilter() {
    this.initNewFilter();
    this.sortField = null;
    this.sortOrder = 0;
    this.table.sortField = '';
    this.table.sortOrder = 0;
    this.table.reset();
    this.issueDateCalendar.clear();
    this.settlementPeriodCalendar.clear();
    this.payingDeadlineCalendar.clear();

    this.filter();
  }

  // ----------------------- PDF export  -----------------------------------------

  exportInvoiceDocumentConfirmation() {
    let uuids: string[] = this.getSelectedInvoiceUuIds();
    let count: number = uuids.length > 0 ? uuids.length : this.invoiceTotalCount;
    if (count > this.commonService.maxDownloadItem) {
      let toastMessage: ToastMessage;
      toastMessage = {
        severity: 'error',
        summary: 'Hiba!',
        detail: 'A letöltendő tételek száma (' + count + ') meghaladja a maximális ' + this.commonService.maxDownloadItem + ' darabszámot!',
        life: 30000,
      };
      this.toasterService.showToaster(toastMessage);
      return;
    }
    if (uuids.length > 0) {
      this.confirmationService.confirm({
        message: 'Szeretné a <b>kijelölt, ' + count + ' darab</b> számlát letölteni?',
        accept: () => {
          this.downloadSelectedInvoiceDocuments(uuids);
        },
      });
    } else {
      this.confirmationService.confirm({
        message: 'Szeretné az <b>összes, ' + count + ' darab</b> számlát letölteni?',
        accept: () => {
          this.downloadAllInvoiceDocuments();
        },
      });
    }
  }

  getSelectedInvoiceUuIds(): string[] {
    let uuids: string[] = [];
    if (this.selectedInvoices != null) {
      this.selectedInvoices.forEach((invoice) => {
        uuids.push(invoice.attachedFileUuid);
      });
    }
    return uuids;
  }

  downloadSelectedInvoiceDocuments(uuids: string[]) {
    this.documentController.downloadZipUsingPOST(uuids, this.commonService.getCurrentUser().token, 'response').subscribe((data) => {
      import('file-saver').then((FileSaver) => {
        FileSaver.saveAs(data.body, this.commonService.getFilenameFromHeader(data, 'document.zip'));
      });
    });
  }
  downloadAllInvoiceDocuments() {
    this.invoiceService
      .exportInvoiceDocumentsUsingPOST(this.buildListInvoiceRequest(), this.commonService.getCurrentUser().token, 'response')
      .subscribe((data) => {
        import('file-saver').then((FileSaver) => {
          FileSaver.saveAs(data.body, this.commonService.getFilenameFromHeader(data, 'document.zip'));
        });
      });
  }


// ----------------------- Reload invoices  -----------------------------------------
  reloadConfirmation() {
    let ids: number[] = this.getSelectedInvoiceIds();
    let count: number = ids.length > 0 ? ids.length : this.invoiceTotalCount;
    if (ids.length > 0) {
      this.confirmationService.confirm({
        message: 'Szeretné a <b>kijelölt, ' + count + ' darab</b> számlát újra feldolgozni?',
        accept: () => {
          this.reloadSelectedInvoices(ids);
        },
      });
    } else {
      this.confirmationService.confirm({
        message: 'Szeretné az <b>összes,' + count + ' darab</b> számlát újra feldolgozni?',
        accept: () => {
          this.reloadAllInvoices();
        },
      });
    }
  }

  reloadSelectedInvoices(ids: number[]) {
    this.invoiceService.reloadInvoicesUsingPOST(ids, this.commonService.getCurrentUser().token, 'response').subscribe(() => {
      
      let toastMessage: ToastMessage;
      toastMessage = {
        severity: 'info',
        summary: '',
        detail: 'Az újrafeldolgozás elindult',
        life: 30000,
      };
      this.toasterService.showToaster(toastMessage);
      this.loadInvoiceProcesses();
    });
  }

  reloadAllInvoices() {
    this.invoiceService
      .reloadFilteredInvoicesUsingPOST(this.buildListInvoiceRequest(), this.commonService.getCurrentUser().token, 'response').subscribe(() => {
        
        let toastMessage: ToastMessage;
        toastMessage = {
          severity: 'info',
          summary: '',
          detail: 'Az újrafeldolgozás elindult',
          life: 30000,
        };
        this.toasterService.showToaster(toastMessage);    
        this.loadInvoiceProcesses();     
      });
  }

  // ----------------------- Excel export  -----------------------------------------

  exportExcelConfirmation() {
    let ids: number[] = this.getSelectedInvoiceIds();
    let count: number = ids.length > 0 ? ids.length : this.invoiceTotalCount;
    if (ids.length > 0) {
      this.confirmationService.confirm({
        message: 'Szeretné a <b>kijelölt, ' + count + ' darab</b> számlát exportálni?',
        accept: () => {
          this.exportSelectedInvoiceToExcel(ids);
        },
      });
    } else {
      this.confirmationService.confirm({
        message: 'Szeretné az <b>összes,' + count + ' darab</b> számlát exportálni?',
        accept: () => {
          this.exportAllInvoiceToExcel();
        },
      });
    }
  }

  getSelectedInvoiceIds(): number[] {
    let ids: number[] = [];
    if (this.selectedInvoices != null) {
      this.selectedInvoices.forEach((invoice) => {
        ids.push(invoice.id);
      });
    }
    return ids;
  }

  exportSelectedInvoiceToExcel(ids: number[]) {
    this.invoiceService.exportSelectedInvoiceXlsUsingPOST(ids, this.commonService.getCurrentUser().token, 'response').subscribe((data) => {
      import('file-saver').then((FileSaver) => {
        FileSaver.saveAs(data.body, this.commonService.getFilenameFromHeader(data, 'szamla_export_' + new Date().getTime() + '.xlsx'));
      });
    });
  }

  exportAllInvoiceToExcel() {
    this.invoiceService
      .exportInvoiceXlsUsingPOST(this.buildListInvoiceRequest(), this.commonService.getCurrentUser().token, 'response')
      .subscribe((data) => {
        import('file-saver').then((FileSaver) => {
          FileSaver.saveAs(data.body, this.commonService.getFilenameFromHeader(data, 'szamla_export_' + new Date().getTime() + '.xlsx'));
        });
      });
  }

  // ----------------------- Excel (all fields) export  -----------------------------------------

  exportInvoiceAllFieldsConfirmation() {
    let ids: number[] = this.getSelectedInvoiceIds();
    let count: number = ids.length > 0 ? ids.length : this.invoiceTotalCount;
    if (ids.length > 0) {
      this.confirmationService.confirm({
        message: 'Szeretné a <b>kijelölt, ' + count + ' darab</b> számlát exportálni?',
        accept: () => {
          this.exportSelectedInvoiceAllFieldsToExcel(ids);
        },
      });
    } else {
      this.confirmationService.confirm({
        message: 'Szeretné az <b>összes,' + count + ' darab</b> számlát exportálni?',
        accept: () => {
          this.exportInvoiceAllFieldsToExcel();
        },
      });
    }
  }

  private exportSelectedInvoiceAllFieldsToExcel(ids: number[]) {
    this.invoiceService
      .exportSelectedInvoiceXlsAllFieldsUsingPOST(ids, this.commonService.getCurrentUser().token, 'response')
      .subscribe((data) => {
        import('file-saver').then((FileSaver) => {
          FileSaver.saveAs(data.body, this.commonService.getFilenameFromHeader(data, 'szamla_export_' + new Date().getTime() + '.xlsx'));
        });
      });
  }

  private exportInvoiceAllFieldsToExcel() {
    this.invoiceService
      .exportInvoiceXlsAllFieldsUsingPOST(this.buildListInvoiceRequest(), this.commonService.getCurrentUser().token, 'response')
      .subscribe((data) => {
        import('file-saver').then((FileSaver) => {
          FileSaver.saveAs(data.body, this.commonService.getFilenameFromHeader(data, 'szamla_export_' + new Date().getTime() + '.xlsx'));
        });
      });
  }
  // ----------------------- Merge pdf export  -----------------------------------------

  exportPdfMergeConfirmation() {
    let ids: number[] = this.getSelectedInvoiceIds();
    let count: number = ids.length > 0 ? ids.length : this.invoiceTotalCount;
    if (count > this.commonService.maxDownloadItem) {
      let toastMessage: ToastMessage;
      toastMessage = {
        severity: 'error',
        summary: 'Hiba!',
        detail: 'A letöltendő tételek száma (' + count + ') meghaladja a maximális ' + this.commonService.maxDownloadItem + ' darabszámot!',
        life: 30000,
      };
      this.toasterService.showToaster(toastMessage);
      return;
    }
    if (ids.length > 0) {
      this.confirmationService.confirm({
        message: 'Szeretné a <b>kijelölt, ' + count + ' darab</b> számlákat összefűzve letölteni?',
        accept: () => {
          this.downloadSelectedPdfMerge(ids);
        },
      });
    } else {
      this.confirmationService.confirm({
        message: 'Szeretné az <b>összes, ' + count + ' darab</b> számlát összefűzve letölteni?',
        accept: () => {
          this.downloadAllPdfMerge();
        },
      });
    }
  }

  downloadSelectedPdfMerge(ids: number[]) {
    this.invoiceService
      .mergeSelectedInvoiceDocumentsUsingPOST(ids, this.commonService.getCurrentUser().token, 'response')
      .subscribe((data) => {
        import('file-saver').then((FileSaver) => {
          FileSaver.saveAs(
            data.body,
            this.commonService.getFilenameFromHeader(data, 'osszesitett_szamla_' + new Date().getTime() + '.pdf')
          );
        });
      });
  }

  downloadAllPdfMerge() {
    this.invoiceService
      .mergeInvoiceDocumentsUsingPOST(this.buildListInvoiceRequest(), this.commonService.getCurrentUser().token, 'response')
      .subscribe((data) => {
        import('file-saver').then((FileSaver) => {
          FileSaver.saveAs(
            data.body,
            this.commonService.getFilenameFromHeader(data, 'osszesitett_szamla_' + new Date().getTime() + '.pdf')
          );
        });
      });
  }
  // ----------------------- Operations -----------------------------------------

  showDetails(row: number): void {
    this.invoiceManager.invoiceId = this.invoices[row].id;
    this.invoiceManager.visible = true;
  }

  newInvoice() {
    this.invoiceManager.invoiceId = undefined;
    this.invoiceManager.visible = true;
    this.invoiceManager.uploadingDocumentFile = null;
    this.invoiceManager.uploadingDocumentFileName =  null;
  }  

  downloadInvoiceDocument(row: number) {
    this.commonService.downloadDocument(this.invoices[row].attachedFileUuid, 'szamla');
  }
  // ----------------------- Comment -----------------------------------------
  showComments(row: number) {
    this.displayInvoiceComment = true;
    this.commentText = '';
    this.commentInvoiceId = this.invoices[row].id;
    this.commentService.listInvoiceCommentUsingGET(this.commentInvoiceId, this.commonService.getCurrentUser().token).subscribe((resp) => {
      this.comments = resp.comments;
    });
  }

  isDisableCommentSaving(): boolean {
    return this.commentText == null || this.commentText.trim().length == 0;
  }

  saveComment(): void {
    let req: CommentRequest = { id: this.commentInvoiceId, text: this.commentText };
    this.commentService.addCommentToInvoiceUsingPOST(req, this.commonService.getCurrentUser().token).subscribe(() => {
      this.displayInvoiceComment = false;
    });
  }

  // ----------------------- Inovice editor -----------------------------------------







 








  getTableWidthInpx(): string {
    return this.commonService.getTableWidthInpx();
  }

  getTableHeightInpx(): string {
    return this.commonService.getTableHeightInpx();
  }
  getTablePageSize(): number {
    return this.commonService.tablePageSize;
  }
  isAdminUser(): boolean {
    return this.commonService.isAdminUser();
  }
  getNumberFormatRounding2Digits(): string {
    return this.commonService.numberFormatRounding2Digits;
  }
  getNumberFormatRoundingInteger(): string {
    return this.commonService.numberFormatRoundingInteger;
  }
  getCurrentUser(): User {
    return this.commonService.getCurrentUser();
  }

  invoiceProcessDetail(processID: number): void {
    this.dispatcherService.getStatusUsingGET(processID, this.commonService.getCurrentUser().token).subscribe(
      (status) => {
        this.statusAndDetail.status = status;
      },
      //@ts-ignore
      (error) => {},
      () => {
        this.dispatcherService.getDetailsUsingGET(processID, this.commonService.getCurrentUser().token).subscribe(
          (detail) => {
            this.statusAndDetail.detail = detail;
          },
          //@ts-ignore
          (error) => {},
          () => {
            this.actProcessID = processID;
            this.showInvoiceProcessDetail = true;
          }
        );
      }
    );
  }

  getSortedInvoices() {
    return this.invoices
      .filter((invoice: any) => invoice.settlementPeriodStart)
      .map((invoice: any) => {
        invoice.invoiceLabel = invoice.settlementPeriodStart.substring(0, 7);
        invoice.invoiceDate = new Date(invoice.settlementPeriodStart);
        return invoice;
      })
      .sort((a: any, b: any) => {
        return a.invoiceDate.getTime() - b.invoiceDate.getTime();
      });
  }

  drawPeriodChart(): void {
    let nInvoices = this.getSortedInvoices();

    this.simpleChart = false;
    let invoiceDatasets: any[] = [];
    this.chartData = {
      labels: ['Jan', 'Feb', 'Márc', 'Ápr', 'Máj', 'Jún', 'Júl', 'Aug', 'Szept', 'Okt', 'Nov', 'Dec'],
      responsive: true,
      width: '95%',
      height: '500px',
      datasets: invoiceDatasets,
    };

    nInvoices.forEach((invoice) => {
      let paying: number = invoice.paying;
      this.addInvoiceToPeriodDataset(invoice, paying, invoiceDatasets);
    });
    let i = 0;
    this.chartData.datasets.forEach((element, index) => {
      if (index > this.chartColors.length - 1) {
        i = 0;
      } else {
        i = index;
      }
      element.backgroundColor = this.chartColors[i];
      element.borderColor = this.chartColors[i];
    });
    this.showChartDialog = true;
  }

  private addInvoiceToPeriodDataset(invoice: InvoiceListItemDto, value: number, datasets: any[]) {
    let year: string = invoice.settlementPeriodStart.substring(0, 4);
    let month: number = parseInt(invoice.settlementPeriodStart.substring(5, 7));
    let dataset: any = null;
    datasets.forEach((element) => {
      if (element.label == year) {
        dataset = element;
      }
    });
    if (dataset == null) {
      let yearlyData: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      let color = '#9CCC65';
      dataset = {
        label: year,
        backgroundColor: color, //'#9CCC65',//TODO: set different color
        borderColor: color, //'#7CB342',
        data: yearlyData,
        fill: false,
      };
      datasets.push(dataset);
    }
    dataset.data[month - 1] = dataset.data[month - 1] + value;
  }

  drawChart(): void {
    this.simpleChart = true;
    let payings: any[] = [];
    let invoiceLabels: any[] = [];
    let nInvoices = this.getSortedInvoices();
    payings = nInvoices.map((invoice) => invoice.paying);
    invoiceLabels = nInvoices.map((invoice) => invoice.invoiceLabel);

    this.chartData = {
      labels: invoiceLabels,
      responsive: true,
      width: '95%',
      height: '500px',
      datasets: [
        //         {
        //             label: 'Fogyasztás',
        //             backgroundColor: '#42A5F5',
        //             borderColor: '#1E88E5',
        //             data: consuptions
        //         },
        {
          label: 'Fizetendő',
          backgroundColor: '#9CCC65',
          borderColor: '#7CB342',
          data: payings,
          fill: false,
        },
      ],
    };
    this.showChartDialog = true;
  }

  isDisableChart(): boolean {
    if (!this.selectedTreeItem) return true;
    return this.selectedTreeItem.podId ? false : true;
  }

  //@ts-ignore
  chartTypeChange(param) {
    this.showChartDialog = false;
    this.simpleChart ? this.drawChart() : this.drawPeriodChart();
  }
  /* 
 getColor(){ 
    return "hsl(" + 360 * Math.random() + ',' +
               (15 + 70 * Math.random()) + '%,' + 
               (65 + 10 * Math.random()) + '%)'
  }
  */
}
