import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ProviderIntegrationControllerService } from 'src/app/model';
import { ProviderIntegrationMessageListResponse } from 'src/app/model/model/providerIntegrationMessageListResponse';
import { CommonService } from 'src/app/modules/common/common.service';

@Component({
  selector: 'provider-integration-robot-status',
  templateUrl: './provider-integration-robot-status.component.html',
  styleUrls: ['./provider-integration-robot-status.component.css'],
})

export class ProviderIntegrationRobotStatusComponent implements OnInit, OnDestroy {
  constructor(
    private commonService: CommonService,
    private confirmationService: ConfirmationService,
    private providerIntegrationControllerService: ProviderIntegrationControllerService
  ) {}

  private subscriptions: Subscription[] = [];
  providerIntegrationMessages: ProviderIntegrationMessageListResponse;

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  ngOnInit() {
    this.getList();
  }
  getList() {
    this.subscriptions.push(
      this.providerIntegrationControllerService
        .listMessagesUsingGET(this.commonService.getCurrentUser().token)
        .subscribe((data: ProviderIntegrationMessageListResponse) => {
          this.providerIntegrationMessages = data;
        })
    );
  }

  cancelRunningProcess(){
    if (this.providerIntegrationMessages.runningMessage.length == 0) return
    this.confirmationService.confirm({
      message: `Szeretné megszakítani a folyamatban levő letöltést?`,
      accept: () => {
        this.subscriptions.push(
          this.providerIntegrationControllerService
            .cancelMessageUsingGET(this.providerIntegrationMessages.runningMessage[0].uuid, this.commonService.getCurrentUser().token)
            .pipe(take(1))
            .subscribe(() => {
              this.getList();
            })
        );
      },
    });    
  }

  calcStatus() {
    let result = 'green';
    if (
      this.providerIntegrationMessages &&
      this.providerIntegrationMessages.finishedMessages &&
      this.providerIntegrationMessages.runningMessage &&
      this.providerIntegrationMessages.waitingMessages
    ) {            
      if (
        this.providerIntegrationMessages.runningMessage.length >0
      )
        result = 'yellow';
      if (        
        this.providerIntegrationMessages.waitingMessages.length > 0
      )
        result = 'red';
    }
    return result;
  }
}
