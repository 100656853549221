import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { DispatcherControllerService, EnumControllerService } from 'src/app/model';
import { CommonService } from '../../common/common.service';
import { InvoiceProcessingDetailData } from '../invoice-processing/invoice-processing-provicerData';


@Component({
  selector: 'invoice-processing-detail',
  templateUrl: './invoice-processing-detail.component.html',
  styleUrls: ['./invoice-processing-detail.component.css']
})
export class InvoiceProcessingDetailComponent implements OnInit {
  @Input() processID: number;
  @Input() statusAndDetailData: InvoiceProcessingDetailData = <InvoiceProcessingDetailData>{};;

  constructor(private commonService: CommonService,
    private dispatcherService: DispatcherControllerService,
    private enumService: EnumControllerService) { }

  private providerOptions: any[] = [];
  private processStatusOptions: any[] = [];
  public showComment: boolean = true;
  ngOnInit() { }
  ngOnDestroy(): void { }

  //@ts-ignore
  ngOnChanges(changes: SimpleChanges): void {
    this.initOptionsAndEnum();
  }

  private initOptionsAndEnum() {
    this.enumService.listProvidersUsingGET(this.commonService.getCurrentUser().token).subscribe(resp => {
      this.providerOptions = resp.labelValues;
    });
    this.enumService.listProcessStatusUsingGET(this.commonService.getCurrentUser().token).subscribe(resp => {
      this.processStatusOptions = resp.labelValues;
    })
  }

  refreshDetail() {
    this.dispatcherService.getStatusUsingGET(this.processID, this.commonService.getCurrentUser().token).subscribe(
      status => {
        this.statusAndDetailData.status = status;
        //this.statusAndDetailData.status.volumen_cnt = 9999;
        //this.statusAndDetailData.status.processed_cnt = 6300;
        //this.statusAndDetailData.status.comment = '';
      }, 
      //@ts-ignore
      error => { },
      () => {
        this.dispatcherService.getDetailsUsingGET(this.processID, this.commonService.getCurrentUser().token).subscribe(
          detail => {
            this.statusAndDetailData.detail = detail;
          }, 
          //@ts-ignore
          error => { },
          () => {
            //Kész a cucc megjelenhet a popup
          }
        )
      }
    )
  }

  getTableHeightInpx(): string {
    return this.commonService.getTableHeightInpx()
  }

  public findLabelByStatus(value: string): string {
    return this.commonService.findLabel(this.processStatusOptions, value);
  }

  public findLabelbyProvider(value: string): string {
    return this.commonService.findLabel(this.providerOptions, value);
  }
  
  public toggleComment(){
    this.showComment = !this.showComment;
  }
}
