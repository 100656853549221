import { Component, OnInit, ViewChild } from '@angular/core';
import { ContractFilter } from './contarct-filter';
import { CommonService } from '../../common/common.service';
import { Paginator } from 'primeng/paginator';
import { ComboItem } from '../contract-manager/combo-item.inteface';
import { ListContractRequest } from '../../../model/model/listContractRequest';
import { ContractListItemDto } from '../../../model/model/contractListItemDto';
import { LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { ContractControllerService } from "../../../model/api/contractController.service";
import { EnumControllerService } from '../../../model/api/enumController.service';
import { LabelValue } from '../../../model/model/labelValue';
import { TABLE_SELECTOR } from '../../common/enums/table-selector';
import { TreeItem } from '../../../model/model/models';
import { ContractManagerComponent } from '../contract-manager/contract-manager.component';
//import { RangeCalendar } from '../range-calendar.component';
import { PeriodInputComponent } from '../shared/period-input/period-input.component';
import { Table } from 'primeng/table';

@Component({
  selector: 'contract-table',
  templateUrl: './contract-table.component.html',
  styleUrls: ['./contract-table.component.css']
})
export class ContractTableComponent implements OnInit {

  @ViewChild('p', { static: true }) paginator: Paginator;
  @ViewChild('dt', { static: false }) table: Table;
  @ViewChild('contractManager',{ static: true }) contractManager: ContractManagerComponent;
  @ViewChild('terminationPeriodCalendar', { static: false }) terminationPeriodCalendar:PeriodInputComponent;
  @ViewChild('contractPeriodCalendar', { static: false }) contractPeriodCalendar:PeriodInputComponent;

  static dropDownSelectValue: ComboItem = { label: 'Válassz', value: 'VALASSZ' };

  public selectedContracts: ContractListItemDto[];

  public contracts: ContractListItemDto[];

  public hu: Object;

  public filterValues: ContractFilter;

  public cols: any[];

  public selectedTreeItem : TreeItem;

  public providerOptions: any[] = [];

  public typeOptions: any[] = [];

  public podTypeOptions: any[] = [];

  public periodTypeOptions: any[] = [];

  public currencyOptions: any[] = [];
  
  public sortField:string;

  public sortOrder:number = 0;

  //public contractMaxRowNumer: number;

  public contractTotalCount: number;

  public isAdmin: boolean;

  public currentInc: number = 0;
  
  public allColumns: any[] = [];

  public selectedColumns: any[] = [];

  public orderedSelectedColumns: any[] = [];

  constructor(private commonService: CommonService, 
    private contractController: ContractControllerService, 
    private confirmationService: ConfirmationService,
    private enumService : EnumControllerService) { }

  ngOnInit() {
    this.hu = this.commonService.datePickerLanguage;

    this.initColumns();
    
    this.initDropdowns();
    this.filterValues = <ContractFilter>{};
    this.commonService.connector.subscribe(data => {
      this.selectedTreeItem = data.selectedTreeItem;
      this.selectedContracts = [];
      this.filter();
    });      
  }

  initColumns() {
    this.allColumns = [
      { field: "customerName", header: "Ügyfél", size:"180px", align:"left" },
      { field: "top", header: "Top", size:"80px", align:"center" },
      { field: "lastCall", header: "Last call", size:"80px", align:"center" },
      { field: "podType", header: "POD típus", size:"80px", align:"center"},
      { field: "type", header: "Szerződés típus", size:"100px", align:"left" },
      { field: "periodType", header: "Fajta", size:"100px", align:"left" },
      { field: "name", header: "Elnevezés", size:"250px", align:"left" },
      { field: "contractNumber", header: "Szerződésszám", size:"250px", align:"left" },
      { field: "provider", header: "Szolgáltató", size:"250px", align:"left" },
      { field: "contractStart", header: "Szerződés időszaka", size:"150px", align:"center" },
      { field: "unitPrice", header: "Szerződött egységár", size:"100px", align:"right" },
      { field: "terminationStart", header: "Felmondási időszak", size:"150px", align:"center"}
    ];

    let existUXSetting = this.commonService.existUserUXSetting('contract-table', 'selectedColumns');
    let savedSelectedCols: any[]; 
    
    savedSelectedCols = (existUXSetting) ? JSON.parse(this.commonService.getUserUXSettingValue('contract-table', 'selectedColumns')) : []
    
    if (!existUXSetting) {
      this.selectedColumns.push(this.allColumns[0],this.allColumns[4],this.allColumns[6],this.allColumns[7],this.allColumns[8],
      this.allColumns[9],this.allColumns[11]);
    } else {
    
    this.allColumns.forEach(col => {
      let count = savedSelectedCols.find(citem => citem.field == col.field)
      if (count) this.selectedColumns.push(col)
    
    })
    
    }

    this.allColumns=Object.assign([],this.allColumns);
    this.toggleColumn();
  }
  
  initDropdowns() {
    this.enumService.listProvidersUsingGET(this.commonService.getCurrentUser().token).subscribe(resp => {
      this.providerOptions = resp.labelValues;
    });
    this.enumService.listContractTypesUsingGET(this.commonService.getCurrentUser().token).subscribe(resp => {
      this.typeOptions = resp.labelValues;
    });
    this.enumService.listPodTypesUsingGET(this.commonService.getCurrentUser().token).subscribe(resp => {
      this.podTypeOptions = resp.labelValues;
    });
    this.enumService.listPeriodTypesUsingGET(this.commonService.getCurrentUser().token).subscribe(resp => {
      this.periodTypeOptions = resp.labelValues;
    });  
    this.enumService.listCurrenciesUsingGET(this.commonService.getCurrentUser().token).subscribe(resp=>{
      this.currencyOptions = resp.labelValues;
    });

  }

  public resetFilter() {
    this.sortField = null;
    this.sortOrder = 0;
    this.table.sortField = '';
    this.table.sortOrder = 0;
    this.table.reset();
    this.filterValues =new ContractFilter();
    this.filterValues.contractPeriod = [null, null];
    this.filterValues.terminationPeriod = [null, null];
    this.contractPeriodCalendar.clear();
    this.terminationPeriodCalendar.clear();
    this.filter();
  }

  buildListContractRequest() : ListContractRequest {
    let request: ListContractRequest = {
      treeId: this.selectedTreeItem == null ? null : parseInt(this.selectedTreeItem.key),
      pageNumber: this.paginator.getPage(),
      pageSize: this.commonService.tablePageSize,
      sortField: this.sortField,
      direction: this.sortOrder,
      contractNumber: this.filterValues != null ? this.filterValues.contractNumber : null,
      provider: this.filterValues != null ? this.filterValues.provider : null,
      contractPeriodStart: this.filterValues != null && this.filterValues.contractPeriod != null ? this.filterValues.contractPeriod[0] : null,
      contractPeriodEnd: this.filterValues != null && this.filterValues.contractPeriod != null ? this.filterValues.contractPeriod[1] : null,
      terminationPeriodStart: this.filterValues != null && this.filterValues.terminationPeriod != null ? this.filterValues.terminationPeriod[0] : null,
      terminationPeriodEnd: this.filterValues != null && this.filterValues.terminationPeriod != null ? this.filterValues.terminationPeriod[1] : null,
      name: this.filterValues != null ? this.filterValues.name : null,
      type: this.filterValues != null ? this.filterValues.type : null,
      periodType: this.filterValues != null ? this.filterValues.periodType : null,
      podType: this.filterValues != null ? this.filterValues.podType : null
      
    };
    return request;
  }

  public listContracts(): void {
    if (TABLE_SELECTOR.CONTRACTS != this.commonService.getselectTable()) {
      return;
    }
    this.commonService.setDisableTree(false);
    this.contractController.listContractsUsingPOST(this.buildListContractRequest(), this.commonService.getCurrentUser().token).subscribe(data => {
        this.contracts = data.contractPage.content;
        this.contractTotalCount = data.contractPage.totalElements;
      });
  }
  public sort(event: LazyLoadEvent) {
    this.sortField = event.sortField;
    this.sortOrder = event.sortOrder;
    this.selectedContracts = [];
    this.listContracts();
  }

  public filter(){
    this.selectedContracts = [];
    this.paginator.changePage(0);
    this.currentInc = 0;
    this.listContracts();
  }
  
  public paginate(): void {
    this.currentInc = this.paginator.getPage() * this.commonService.tablePageSize;
    this.listContracts();
  }


  showDetails(row:number):void {
    this.contractManager.contractId = this.contracts[row].id;
    this.contractManager.visible = true;
  }

  showContractManager(): void {

    this.contractManager.contractId = undefined;
    this.contractManager.visible = true;
    this.contractManager.uploadingDocumentFile = null;
    this.contractManager.uploadingDocumentFileName =  null;
  }

  downloadContract(row : number): void {
    this.commonService.downloadDocument(this.contracts[row].uuid, 'szerzodes.pdf');
  }

  deleteContract(row : number):void {
    this.confirmationService.confirm({ message: 'Szeretné a törölni a '+this.contracts[row].name+' nevű szerződést?', accept: () => { 
      this.contractController.deleteUsingGET(this.contracts[row].id, this.commonService.getCurrentUser().token).subscribe(() =>{
        this.filter();
      });
    }
   });
  }

  //@ts-ignore
  toggleColumn(event?, needSave=true) {
    this.orderedSelectedColumns = [];    
    this.allColumns.forEach(col=>{
      this.selectedColumns.forEach(selectedCol=>{
        if (selectedCol===col){
          this.orderedSelectedColumns.push(col);
        }
      });      
    });
    this.orderedSelectedColumns.push({ field: "operations", header: "Műveletek", size:"100px", align:"center" });
    
    if (needSave)
      this.commonService.addComponentSetting('contract-table', 'selectedColumns', 'Szerződések tábla, mutatott oszlopok', JSON.stringify(this.selectedColumns) )  
  }

  getTableWidthInpx(): string {
    return this.commonService.getTableWidthInpx();
  }

  getTableHeightInpx(): string {
    return this.commonService.getTableHeightInpx();
  }
  isAdminUser(): boolean {
    return this.commonService.isAdminUser();
  }
  isCustomer(item: any) {
    return this.commonService.isCustomer(item);
  }
  findLabel(options:LabelValue[], value: string): string {
    return this.commonService.findLabel(options, value);
  }
  getTablePageSize() : number {
    return this.commonService.tablePageSize;
  }
  getNumberFormatRounding2Digits(){
    return this.commonService.numberFormatRounding2Digits;
  }
  public getCurrentPageReportTemplate() {
    let from = this.paginator.getPage() * this.commonService.tablePageSize + 1;
    let to = (this.paginator.getPage() + 1) * this.commonService.tablePageSize;
    const total = this.contractTotalCount | 0;
    to = Math.min(to, total);
    return `{currentPage}. oldal [ ${from} - ${to}  a(z) ${total} -ből]`
  }

}
