import { TreeNode } from "primeng/api";
import { Injectable } from "@angular/core";
import { TreeItem } from "src/app/model";

@Injectable({providedIn: "root"})
export class TreeUtils {

  constructor() {}

  findRoot(item:TreeNode) : TreeNode {
    return  (item.parent != null) ? this.findRoot(item.parent) : item;
  }

  public getTreeChain(item: TreeNode) : TreeNode[]{
    let chain: TreeNode[] = [];
    chain.push(item);
    while (item["parent"] != null) {
      item = item["parent"];
      chain.push(item);
    }
    chain.reverse();
    return chain;
  }

public expandTree(tree: TreeNode[]) {
    tree.forEach( node => {
        this.expandTreeRecursive(node, true);
      });
}

  public expandTreeRecursive(node:TreeNode, isExpand:boolean){
    node.expanded = isExpand;
    if(node.children){
        node.children.forEach( childNode => {
            this.expandTreeRecursive(childNode, isExpand);
        } );
      }
  }

  findTreeItemByKey(key:string, tree:TreeNode[], parent:TreeNode):TreeNode {
      let foundItem = tree.find(obj => obj.key == key);
      tree.forEach(treeItem => {
        treeItem.parent = parent;
      });
      
      if (foundItem!=null) {
        return foundItem;
      } else {
        let result = null;
        tree.forEach(treeItem => {
          if (treeItem.children!=null && treeItem.children.length>0){
            if (result==null){
              result = this.findTreeItemByKey(key, treeItem.children, treeItem);
            }
          }
        });
        return result;
      }
      return null;
  }

  findTreeItemByPodId(podId:number, tree:TreeNode[], parent:TreeNode):TreeNode {
    let foundItem = tree.find(obj => obj['podId'] == podId);
    tree.forEach(treeItem => {
      treeItem.parent = parent;
    });
    
    if (foundItem!=null) {
      return foundItem;
    } else {
      let result = null;
      tree.forEach(treeItem => {
        if (treeItem.children!=null && treeItem.children.length>0){
          if (result==null){
            result = this.findTreeItemByPodId(podId, treeItem.children, treeItem);
          }
        }
      });
      return result;
    }
    return null;
}

cloneParent(item:TreeItem) {
  let cloneParent = null;
    if (item.parent != null) {
      cloneParent = this.cloneParent(item.parent);
    }
    let currentItem : TreeItem = {
        key : item.key,
        label : item.label,
        type : item.type,
        podId: item.podId,

        parent : cloneParent
    };
    if (cloneParent!=null){
        cloneParent.children = [];
        cloneParent.children.push(currentItem);
    }
   return currentItem;
  }

  merge(tree: TreeNode[], item: TreeNode) {
    let foundItem = tree.find(obj => obj.key == item.key);
    if (foundItem!=null) {
      if (item.children[0]!=null) {
        this.merge(foundItem.children == null ? [] : foundItem.children, item.children[0]);
      }
    } else {
      tree.push(item);
      tree.sort((a, b) => (a.label > b.label) ? 1 : -1);
    }
  }


}