
// import { NotificationTypeEnum } from "../../common/enums/notificationType";

export class PODFilter {
  type: string;
  status: string;
  podName: string;
  address: string;
 }
 
 

