import { Component, OnInit, Input } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { CommonService } from '../../../common/common.service';
import { PodControllerService } from '../../../../model/api/podController.service';
import { InvoicesharePodConfigDto } from '../../../../model/model/invoicesharePodConfigDto';
import { InvoiceShareControllerService } from '../../../../model/api/invoiceShareController.service';
import { InvoiceSharePodDto } from '../../../../model/model/invoiceSharePodDto';



@Component({
  selector: 'invoiceshare-editor',
  templateUrl: './invoiceshare-editor.component.html',
  styleUrls: ['./invoiceshare-editor.component.css'],
  providers: [MessageService],
  inputs: ['visible']
})
export class InvoiceshareEditorComponent implements OnInit {

  private subscriptions: Subscription[] = [];

  public visible: boolean;

  public configDto: InvoicesharePodConfigDto;

  public costHolder : string;

  public podName : string;

  public sumPercent : number;

  public errorMessage: string;
  
  constructor(
    private commonService : CommonService,
    private invoiceshareService: InvoiceShareControllerService,
    private podService: PodControllerService,
    ) {
  }

  @Input()
  set config(configDto: InvoicesharePodConfigDto) {
    this.configDto = configDto;
    this.calculateSum();
    this.getPodInfo();
  }

  getPodInfo() : void {
    if (this.configDto.podCount==1) {
      this.subscriptions.push(
        this.podService.getPodUsingGET(this.configDto.podId, this.commonService.getCurrentUser().token).subscribe(resp => {
          this.podName = resp.pod.name;
        })
      );
    }
  }

  ngOnInit() {
    this.configDto =<InvoicesharePodConfigDto>{};
    this.configDto.shares = [];
  }

  ngOnDestroy(){
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  addCostHolder() {
    let share : InvoiceSharePodDto = {};
    share.name = this.costHolder;
    share.percent = 0;
    share.description = '';
    this.configDto.shares.push(share);
    this.costHolder = '';
    this.calculateSum();
  }

  removeShare(share : InvoiceSharePodDto) {
    this.configDto.shares = [...this.configDto.shares.filter((item) => item.name != share.name && item.percent != share.percent)];
    this.calculateSum();
  }

  save() :void{
    this.removeSumShare();
    this.subscriptions.push(

      this.invoiceshareService.saveConfigUsingPOST(this.configDto, this.commonService.getCurrentUser().token).subscribe(
        /// @ts-ignore
        (data) => {
          this.visible = false;
        })
    );
  }
  calculateSum() {
    this.removeSumShare();
    this.addSumShare();

  }

  addSumShare() {
    this.sumPercent = 0;
    for (let i = 0; i < this.configDto.shares.length; i++) {
        this.sumPercent = this.sumPercent + this.configDto.shares[i].percent;
    }

    let share : InvoiceSharePodDto = {};
    share.id = -1;
    share.name = 'Összesen';
    share.percent = this.sumPercent;
    share.description = '';
    this.configDto.shares.push(share);
  }

  removeSumShare() {
    this.configDto.shares = [...this.configDto.shares.filter((s) => s.id!=-1)]
  }

  isDisable() {
    return this.sumPercent != 0 && this.sumPercent != 100;
  }

}
