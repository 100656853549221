import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { CommonService } from "../../common/common.service";
import { NgForm } from "@angular/forms";
import { EnumControllerService } from "../../../model/api/enumController.service";
import { UserDetailsDto } from "../../../model/model/models";
import { CustomerTreeControllerService } from "../../../model/api/customerTreeController.service";
import { TreeNode, MessageService } from "primeng/api";
import { UserRoleEnum } from "../../common/enums/userRole";
import { TreeUtils } from "../../common/tree.utils";

@Component({
  selector: "user-editor",
  templateUrl: "./user-editor.component.html",
  styleUrls: ["./user-editor.component.css"],
  providers: [MessageService]
})
export class UserEditorComponent implements OnInit {
  @ViewChild("form", { static: true }) form: NgForm;

  public formValues: UserDetailsDto;

  public roleOptions: any[] = [];

  tree: TreeNode[];
  
  userTree: TreeNode[] = [];

  selectedTreeItem: TreeNode;
  selectedUserTreeItem: TreeNode;

  constructor(
    private commonService: CommonService,
    private customerTreeService: CustomerTreeControllerService,
    private treeUtils: TreeUtils,
    private enumService : EnumControllerService,

  ) {}

  @Input()
  set user(userDto: UserDetailsDto) {
    this.formValues = userDto;
    this.changeRole();
  }

  get user(): UserDetailsDto { return this.formValues; }


  ngOnInit() {
    this.initDropdowns();
  }

  initDropdowns() {
    this.enumService.listRolesUsingGET(this.commonService.getCurrentUser().token).subscribe(data => {
      this.roleOptions = data.labelValues;
    });

  }

  public loadTreeWithoutPods() {
    this.customerTreeService.getTreeWithoutPodsUsingGET(this.commonService.getCurrentUser().token).subscribe(response => {
      this.tree = response.items;
      this.formValues.whitelist.forEach(key => {
        let treeItem = this.treeUtils.findTreeItemByKey(""+key, this.tree, null);
        this.addTreeItem(treeItem);
      });
      
    });
  }

  public loadTreeWithCustomers() {
    this.customerTreeService.getTreeOnlyCustomersUsingGET(this.commonService.getCurrentUser().token).subscribe(response => {
      this.tree = response.items;
      this.formValues.customers.forEach(key => {
        let treeItem = this.treeUtils.findTreeItemByKey(""+key, this.tree, null);
        this.addTreeItem(treeItem);
      });
    });
  }

  changeRole() {
    if (this.formValues.role == null){
      return;
    }

    if (this.formValues.role == UserRoleEnum[UserRoleEnum.ADMIN]) {
      this.tree = [];
      this.userTree = [];
    }
    if (this.formValues.role == UserRoleEnum[UserRoleEnum.POWER_CLIENT]) {
      this.loadTreeWithCustomers();
      this.userTree = [];
    } 
    if (this.formValues.role == UserRoleEnum[UserRoleEnum.CLIENT]) {
      this.loadTreeWithoutPods();
      this.userTree = [];
    }
    if (this.formValues.role == UserRoleEnum[UserRoleEnum.CUSTOMER_ADMIN]) {
      this.loadTreeWithCustomers();
      this.userTree = [];
    }


  }

  addTreeItem(treeItem:TreeNode){
    if (treeItem == null) {
      return;
    }
    //clone + copy
    let clonedItem = this.treeUtils.cloneParent(treeItem);
    clonedItem.children = treeItem.children;
    let root : TreeNode = this.treeUtils.findRoot(clonedItem);
    this.treeUtils.merge(this.userTree, root);

    //delete original
    this.deleteTreeItem(treeItem, this.tree);

    this.formValues.customers = this.collectCustomerIds();
    this.formValues.whitelist = [];
    this.collectWhitelistIds(this.userTree, this.formValues.whitelist);
  }

  removeTreeItem(treeItem:TreeNode) {
    if (treeItem == null) {
      return;
    }
    //clone + copy
    let clonedItem = this.treeUtils.cloneParent(treeItem);
    clonedItem.children = treeItem.children;
    let root : TreeNode = this.treeUtils.findRoot(clonedItem);
    this.treeUtils.merge(this.tree, root);

    //delete original
    this.deleteTreeItem(treeItem, this.userTree);

    this.formValues.customers = this.collectCustomerIds();
    this.formValues.whitelist = [];
    this.collectWhitelistIds(this.userTree, this.formValues.whitelist);
  }
  
  collectCustomerIds():number[]{
    let customerIds:number[] = [];
    if (this.userTree == null) {
      return null;
    } else {
      this.userTree.forEach(treeItem => {
        customerIds.push(parseInt(treeItem.key));
      });
    }
    return customerIds;
  }

  collectWhitelistIds(tree:TreeNode[], whitelistIds:number[]){
    if (tree != null) {
        tree.forEach(treeItem => {
          if (!this.commonService.isCustomer(treeItem)) {
            whitelistIds.push(parseInt(treeItem.key));
          }
          if (treeItem.children!=null){
            this.collectWhitelistIds(treeItem.children, whitelistIds);
          }
        });
      }
    }
  
  deleteTreeItem(item:TreeNode, tree:TreeNode[]) {
    if (item.parent!=null) {
      tree = item.parent.children;
    }
    const index: number = tree.indexOf(item);
    if (index !== -1) {
      tree.splice(index, 1);
    }
  }


  isDisable():boolean{


    return false
  }



}
